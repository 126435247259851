import { CURRENT_LANGUGAE } from "../constants/ActionTypes";
const initialState = {
  currentLanguage: "en",
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_LANGUGAE:
      return { ...state, currentLanguage: action.currentLanguage };

    default:
      return state;
  }
};
export default languageReducer;
