import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getOpenStoreIdApp,
} from "../../../store";
import { postCall, getCall } from "../../../api/post";
import { getCommonCounter, getUpdateQuantity } from "../../../actions";
import {
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import AddToCartAnim from "../../../animation/AddToCartAnim";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { debuglogger } from "../../../utils/logger";
import RequestProductListItem from "./RequestProductListItem";
import { confirmAlert } from "react-confirm-alert";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import DeleteAnim from "../../../animation/AnimFiles/DeleteAnim";

class RequestProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPincodeDeliveryEnable: false,
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
      showAddToCartMessageModal: false,
      addToCartMessage: "",
      masterDetailsObj: {},
    };
  }
  componentWillMount() {
    this.initMasterDetailsId().then(() => {
      this.fetchMoreItems();
      this.initPincodeDelivery();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  fetchMoreItems = () => {
    const { productList } = this.props;
    const skip = productList.length;
    this.props.handlePagination(skip);
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      clearLocalStorageReload({ response: pincodeDelivery });
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  render() {
    const {
      productList,
      symbol,
      isPaginationEnableForProductList,
      masterDetailsObj,
      onClickOfDeleteIcon,
    } = this.props;
    const {
      isPincodeDeliveryEnable,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state;
    return (
      <>
        <div>
          <div className="product-wrapper-grid">
            <div className="container-fluid">
              {productList.length > 0 ? (
                <InfiniteScroll
                  dataLength={productList.length} //This is important field to render the next data
                  next={this.fetchMoreItems}
                  hasMore={isPaginationEnableForProductList}
                  loader={<div className="loading-cls" />}
                  endMessage={
                    ""
                    // <p className="seen-cls seen-it-cls">
                    //     <b>Yay! You have seen it all</b>
                    // </p>
                  }
                >
                  <div className="row">
                    {productList.map((product, index) => (
                      <div
                        className={`${
                          this.props.colSize === 3
                            ? "col-xl-3 col-md-6 col-grid-box"
                            : "col-lg-" + this.props.colSize
                        }`}
                        key={index}
                      >
                        <RequestProductListItem
                          product={product}
                          symbol={symbol}
                          onClickOfDeleteIcon={(product) => {
                            onClickOfDeleteIcon(product, index);
                          }}
                          isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                          key={index}
                          masterDetailsObj={masterDetailsObj}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                      className="img-fluid mb-4"
                      alt=""
                    />
                    <h3>
                      {getValueFromLanguageObject(
                        "str_sorry_couldnt_find_the_product_you_were_looking_for"
                      )
                        ? getValueFromLanguageObject(
                            "str_sorry_couldnt_find_the_product_you_were_looking_for"
                          )
                        : "Sorry! Couldn't find the product you were looking For!!!"}{" "}
                    </h3>
                    <p>
                      {getValueFromLanguageObject(
                        "str_please_check_if_you_have_missspelt_something_try_search_with_other_word"
                      )
                        ? getValueFromLanguageObject(
                            "str_please_check_if_you_have_missspelt_something_try_search_with_other_word"
                          )
                        : "Please check if you have misspelt something or try searching with other words."}
                    </p>
                    <Link to={`/products`} className="btn btn-solid">
                      {getValueFromLanguageObject("str_continue_shopping")
                        ? getValueFromLanguageObject("str_continue_shopping")
                        : "continue shopping"}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}

        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
});

export default connect(mapStateToProps, {
  counter: getCommonCounter,
  updateQuantity: getUpdateQuantity,
})(withRouter(RequestProductList));
