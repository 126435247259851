import {
  IS_REGISTER_ALLOW,
  IS_INQUIRY,
  BASE_CURRENCY,
} from "../constants/ActionTypes";
const initialState = {
  isRegisterAllow: false,
  isInquiry: false,
  baseCurrency: "INR",
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_REGISTER_ALLOW:
      return { ...state, isRegisterAllow: action.isRegisterAllow };

    case IS_INQUIRY:
      return { ...state, isInquiry: action.isInquiry };

    case BASE_CURRENCY:
      return { ...state, baseCurrency: action.baseCurrency };

    default:
      return state;
  }
};
export default settingsReducer;
