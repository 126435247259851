import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getOpenStoreIdApp,
} from "../../../store";
import { postCall, getCall } from "../../../api/post";
import { getCommonCounter, getUpdateQuantity } from "../../../actions";
import {
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import AddToCartAnim from "../../../animation/AddToCartAnim";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { debuglogger } from "../../../utils/logger";
import CollectionProductListItem from "./CollectionProductListItem";

class CollectionProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPincodeDeliveryEnable: false,
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
      showAddToCartMessageModal: false,
      addToCartMessage: "",
      masterDetailsObj: {},
    };
  }
  componentWillMount() {
    this.initMasterDetailsId().then(() => {
      this.fetchMoreItems();
      this.initPincodeDelivery();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  fetchMoreItems = () => {
    const { productList } = this.props;
    // const { products } = productList || {};
    const { length: skip } = productList || [];
    // const skip = length;
    this.props.handlePagination(skip);
  };

  // addToCart = async (product, qty, isInquiry) => {
  //   try {
  //     debuglogger("product", product);
  //     if (
  //       product &&
  //       product.productvariation &&
  //       product.productvariation !== null &&
  //       product.productvariation !== "[]"
  //     ) {
  //       navigateTo(this.props, `/singleproduct/${product.id}`);
  //       return;
  //     }

  //     this.props.handleLoader(true);
  //     let userDetails = loadUserDetailsFromLocalStorage();
  //     if (!isOpenStoreIdExist() && userDetails === undefined) {
  //       return;
  //     } else {
  //       userDetails = userDetails === undefined ? {} : userDetails;
  //     }
  //     const { id, cityId } = userDetails;
  //     const openStoreId = getOpenStoreIdApp();
  //     let userId = openStoreId ? openStoreId : id;

  //     const addtocartUrl = isInquiry
  //       ? "orders/productInquiry"
  //       : "orders/addtocart";

  //     let addtocartObj = {
  //       orderstatus: 1,
  //       inshoppingcart: 1,
  //       userId,
  //       totalamount: isInquiry ? 0 : product.price * qty,
  //       orderdetails: [
  //         {
  //           quantity: qty,
  //           productId: product.id,
  //         },
  //       ],
  //       cityId: cityId,
  //       date: new Date(),
  //     };

  //     const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
  //     this.props.handleLoader(false);
  //     if (addToCartResponse.error) {
  //       clearLocalStorageReload({ response: addToCartResponse });
  //       this.loadErrorMessageAnimation(addToCartResponse.error.message);
  //       if (addToCartResponse.error.statusCode === 401) {
  //         removeUserDetailAndOtherDetails();
  //         navigateTo(this.props, `/login`);
  //         return;
  //       }
  //     } else {
  //       if (isInquiry) {
  //         this.loadAddToCartMessageAnimation(
  //           getValueFromLanguageObject("str_add_to_inquiry_successfully")
  //             ? getValueFromLanguageObject("str_add_to_inquiry_successfully")
  //             : "Product inquiry added successfully"
  //         );
  //       } else {
  //         this.loadAddToCartMessageAnimation(
  //           getValueFromLanguageObject("str_add_to_cart_successfully")
  //             ? getValueFromLanguageObject("str_add_to_cart_successfully")
  //             : "Product added to cart successfully."
  //         );
  //       }
  //     }
  //     this.props.counter();
  //     this.props.updateQuantity();
  //   } catch (error) {
  //     this.props.handleLoader(false);
  //   }
  // };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      clearLocalStorageReload({ response: pincodeDelivery });
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      productList,
      symbol,
      isPaginationEnableForProductList,
      masterDetailsObj,
    } = this.props;
    // const { products } = productList || {};
    const { length: productLength } = productList || [];
    const {
      isPincodeDeliveryEnable,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state;
    return (
      <>
        <div>
          <div className="product-wrapper-grid">
            <div className="container-fluid">
              {productList ? (
                <InfiniteScroll
                  dataLength={productLength} //This is important field to render the next data
                  next={this.fetchMoreItems}
                  hasMore={isPaginationEnableForProductList}
                  loader={<div className="loading-cls" />}
                  endMessage={
                    ""
                    // <p className="seen-cls seen-it-cls">
                    //     <b>Yay! You have seen it all</b>
                    // </p>
                  }
                >
                  <div className="row">
                    {productList &&
                      productList.map((product, index) => (
                        <div
                          className={`${
                            this.props.colSize === 3
                              ? "col-xl-3 col-md-6 col-grid-box"
                              : "col-lg-" + this.props.colSize
                          }`}
                          key={index}
                        >
                          <CollectionProductListItem
                            product={product}
                            symbol={symbol}
                            onAddToCartClicked={this.props.addToCart}
                            isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                            // key={index}
                            masterDetailsObj={masterDetailsObj}
                          />
                        </div>
                      ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                      className="img-fluid mb-4"
                      alt=""
                    />
                    <h3>
                      Sorry! Couldn't find the product you were looking For!!!{" "}
                    </h3>
                    <p>
                      Please check if you have misspelt something or try
                      searching with other words.
                    </p>
                    <Link to={`/products`} className="btn btn-solid">
                      continue shopping
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
});

export default connect(mapStateToProps, {
  counter: getCommonCounter,
  updateQuantity: getUpdateQuantity,
})(withRouter(CollectionProductListing));
