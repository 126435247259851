import { connect } from "react-redux";
import React, { Component } from "react";
import { getCall } from "../../../api/post";
import {
  removeCurrentLanguage,
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
} from "../../../store";
import Breadcrumb from "../../common/breadcrumb";
import {
  getPrivacyPolicyUrl,
  getMasterDetailsIdUrl,
  getAboutUsUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import Iframe from "react-iframe";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";

class PrivacyPolicy extends Component {
  state = { text: "", showErrorMessageModal: false, errorMessage: "" };
  componentDidMount() {
    // this.initPrivacyPolicy();
    // const userDetails = loadUserDetailsFromLocalStorage();
    // if (!isOpenStoreIdExist() && userDetails === undefined) {
    //   navigateTo(this.props, `/login`);
    //   return;
    // }
    this.initMasterDetailsId();
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  removeUserDetailAndRedirectToLogin = () => {
    removeUserDetailAndOtherDetails();
    removeCurrentLanguage();
    navigateTo(this.props, `/login`);
  };

  // initPrivacyPolicy = async () => {
  //   const privacyPolicyUrl = getPrivacyPolicyUrl();
  //   const privacyPolicy = await getCall(privacyPolicyUrl, []);
  //   if (privacyPolicy.error) {
  //     this.loadErrorMessageAnimation(privacyPolicy.error.message);
  //     if (privacyPolicy.error.statusCode === 401) {
  //       this.removeUserDetailAndRedirectToLogin();
  //       return;
  //     }
  //   } else {
  //     this.setState({ text: privacyPolicy.text });
  //   }
  // };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
    } = this.state;
    return (
      <>
        <div>
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_privacy_policy")
                ? getValueFromLanguageObject("str_privacy_policy")
                : "Privacy Policy"
            }
          />
          <div className="container">
            <Iframe
              url={getPrivacyPolicyUrl(masterDetailsObj)}
              position="relative"
              width="100%"
              frameBorder="0"
              height="640"
              styles={{ height: "100%" }}
            />
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(PrivacyPolicy));
