import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { getCall } from "../../../api/post";
import { connect } from "react-redux";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
} from "../../../services";
import { isInquiryOrCart } from "../../../actions";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  loadUserDetailsFromLocalStorage,
  getOpenStoreIdApp,
} from "../../../store";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import Lightbox from "react-image-lightbox";

const imageContainer = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
  border: "1px solid #D3D3D3",
};

class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock:
        props.product.availablequantity > 0
          ? getValueFromLanguageObject("str_instock")
            ? getValueFromLanguageObject("str_instock")
            : "InStock"
          : getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      image: "",
      category: {},
      pincode: "",
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
      isFullScreenImage: false,
      fullScreenImagePath: "",
    };
  }

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initSingleCategry();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > this.props.product.minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock!",
      });
    }
  };

  changeQty = (e) => {
    const { isInquiry, product } = this.props;
    if (!isInquiry && product.availablequantity === 0) {
      return;
    }
    if (e.target.value === "" || e.target.value === "-") {
      this.setState({ quantity: "" });
      return;
    }
    if (e.target.value < 1) {
      return;
    }
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  initSingleCategry = async () => {
    if (this.props.product.category.parentId !== null) {
      const singleCategoryUrl =
        "categories/" + this.props.product.category.parentId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    } else {
      userDetails = userDetails === undefined ? {} : userDetails;
    }
    const { id } = userDetails;
    const openStoreId = getOpenStoreIdApp();
    let userId = openStoreId ? openStoreId : id;
    const counterUrl = "commoncounters?filter[where][userId]=" + userId;
    const commonCounter = await getCall(counterUrl, []);
    if (commonCounter.error) {
      clearLocalStorageReload({ response: commonCounter });
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  pincodeHandleSubmit = (event) => {
    this.setState({ pincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      isInquiry,
      handleClickOfQuickView,
    } = this.props;

    const {
      open,
      category,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      isFullScreenImage,
      fullScreenImagePath,
    } = this.state;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    const strOutOfStock = getValueFromLanguageObject("str_out_of_stock")
      ? getValueFromLanguageObject("str_out_of_stock")
      : "Out of Stock";

    const strThereAre = getValueFromLanguageObject("str_there_are")
      ? getValueFromLanguageObject("str_there_are")
      : "There are";

    const strUnitsOfItemAlreadyInTheCart = getValueFromLanguageObject(
      "str_units_of_item_already_in_the_cart"
    )
      ? getValueFromLanguageObject("str_units_of_item_already_in_the_cart")
      : "units of item already in the cart";

    return (
      <>
        <div className="product-box">
          <div className="img-wrapper mb-2">
            <div className="front">
              <Link to={`/singleproduct/${product.id}`}>
                <div style={{ height: "250px" }}>
                  <img
                    style={imageContainer}
                    src={
                      product.productmedia.length > 0
                        ? getImageUrl(
                            product.productmedia[0].productname,
                            masterDetailsObj
                          )
                        : getPlaceHolderImage()
                    }
                    // className="img-fluid"
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = getPlaceHolderImage();
                    }}
                  />
                </div>
              </Link>
            </div>
            <div className="cart-info cart-wrap">
              <button
                data-toggle="modal"
                data-target="#quick-view"
                title={
                  getValueFromLanguageObject("str_quick_view")
                    ? getValueFromLanguageObject("str_quick_view")
                    : "Quick View"
                }
                // onClick={this.onOpenModal}
                onClick={() => handleClickOfQuickView(product)}
                className="cursor-pointer"
              >
                <i className="fa fa-eye" aria-hidden="true" />
              </button>
              <button
                title={
                  getValueFromLanguageObject("str_view_image")
                    ? getValueFromLanguageObject("str_view_image")
                    : "View Image"
                }
                onClick={() => {
                  const path =
                    product.productmedia.length > 0
                      ? getImageUrl(
                          product.productmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage();
                  this.setState({
                    isFullScreenImage: true,
                    fullScreenImagePath: path,
                  });
                }}
              >
                <i className="fa fa-search-plus" aria-hidden="true" />
              </button>
              <button
                title={
                  isInquiry
                    ? getValueFromLanguageObject("str_add_to_inquiry")
                      ? getValueFromLanguageObject("str_add_to_inquiry")
                      : "Add To Inquiry"
                    : getValueFromLanguageObject("str_add_to_cart")
                    ? getValueFromLanguageObject("str_add_to_cart")
                    : "Add to cart"
                }
                onClick={() => {
                  onAddToCartClicked(product, this.state.quantity, isInquiry);
                }}
                disabled={
                  !isInquiry && product && product.availablequantity === 0
                    ? true
                    : false
                }
              >
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="product-detail">
            <div>
              <Link to={`/singleproduct/${product.id}`}>
                <h6
                  style={{
                    color: getColorCodeBasedOnKey("text_product_name_products"),
                  }}
                >
                  <EllipsisText text={product.name} length={35} />
                </h6>
              </Link>
              <h6>
                <EllipsisText text={product.category.name} length={20} />
              </h6>
              {!isInquiry && (
                <h4>{`${symbol} ${
                  product.price === null
                    ? "0"
                    : parseFloat(product.price).toFixed(2)
                }`}</h4>
              )}

              {product && product.totalCartCounter > 0 && (
                <div className="quantity-cart-item">
                  {`${strThereAre} ${
                    product.totalCartCounter
                  } ${strUnitsOfItemAlreadyInTheCart}`}
                </div>
              )}

              <div>
                {!isInquiry && product && product.availablequantity === 0 && (
                  <div className="product-outofstock">{strOutOfStock}</div>
                )}
              </div>
            </div>
            {/* {open && (
              <Modal
                open={open}
                onClose={this.onCloseModal}
                center
                styles={{ closeButton: { background: "#cfd4da" } }}
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content quick-view-modal">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-6  col-xs-12">
                          <div
                            className="quick-view-img"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "30px",
                            }}
                          >
                            <div
                              className="quick-image-box"
                              onClick={() => {
                                const path =
                                  product.productmedia.length > 0
                                    ? getImageUrl(
                                        product.productmedia[0].productname,
                                        masterDetailsObj
                                      )
                                    : getPlaceHolderImage();
                                this.setState({
                                  isFullScreenImage: true,
                                  fullScreenImagePath: path,
                                });
                              }}
                            >
                              <img
                                src={
                                  product.productmedia.length > 0
                                    ? getImageUrl(
                                        product.productmedia[0].productname,
                                        masterDetailsObj
                                      )
                                    : getPlaceHolderImage()
                                }
                                alt=""
                                className="img-fluid"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = getPlaceHolderImage();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 rtl-text">
                          <div className="product-right">
                            <h2>
                              <EllipsisText text={product.name} length={35} />
                            </h2>
                            {!isInquiry && (
                              <h3>
                                {`${symbol}${parseFloat(product.price).toFixed(
                                  2
                                )}`}
                              </h3>
                            )}
                            {category && category.name && (
                              <div className="border-product">
                                <h6 className="product-title">
                                  {getValueFromLanguageObject("str_category")
                                    ? getValueFromLanguageObject("str_category")
                                    : "category"}
                                </h6>
                                <p>{category.name}</p>
                              </div>
                            )}
                            <div className="border-product">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_sub_category")
                                  ? getValueFromLanguageObject(
                                      "str_sub_category"
                                    )
                                  : "sub-category"}
                              </h6>
                              <p>{product.category.name}</p>
                            </div>

                            {!isInquiry && (
                              <div className="product-description border-product">
                                <h6 className="product-title">
                                  <h6 className="product-title">
                                    {getValueFromLanguageObject("str_price")
                                      ? getValueFromLanguageObject("str_price")
                                      : "Price"}
                                  </h6>
                                  <p>
                                    {`${symbol}${parseFloat(
                                      product.price
                                    ).toFixed(2)}`}
                                  </p>
                                </h6>
                              </div>
                            )}
                            <div className="product-description border-product">
                              {!isInquiry && (
                                <div className="instock-cls">
                                  {this.state.stock}
                                </div>
                              )}
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_quantity")
                                  ? getValueFromLanguageObject("str_quantity")
                                  : "quantity"}
                              </h6>
                              <div className="qty-box">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={this.minusQty}
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-left" />
                                    </button>
                                  </span>
                                  <input
                                    type="number"
                                    name="quantity"
                                    value={this.state.quantity}
                                    onChange={this.changeQty}
                                    className="form-control input-number"
                                    min="1"
                                    max="100000"
                                    minLength="1"
                                    maxLength="100000"
                                  />
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-right-plus"
                                      onClick={this.plusQty}
                                      data-type="plus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-right" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="quantity-description mb-3">
                              {product && product.totalCartCounter > 0 && (
                                <>
                                  {`${strThereAre} ${
                                    product.totalCartCounter
                                  } ${strUnitsOfItemAlreadyInTheCart}`}
                                </>
                              )}
                            </div>
                            <div className="product-buttons">
                              <button
                                className="btn btn-solid"
                                onClick={async () => {
                                  this.onCloseModal();

                                  onAddToCartClicked(
                                    product,
                                    this.state.quantity,
                                    isInquiry
                                  );
                                }}
                                disabled={
                                  !isInquiry &&
                                  product &&
                                  product.availablequantity === 0
                                    ? true
                                    : false
                                }
                              >
                                {isInquiry
                                  ? getValueFromLanguageObject(
                                      "str_add_to_inquiry"
                                    )
                                    ? getValueFromLanguageObject(
                                        "str_add_to_inquiry"
                                      )
                                    : "Add to Inquiry"
                                  : getValueFromLanguageObject(
                                      "str_add_to_cart"
                                    )
                                  ? getValueFromLanguageObject(
                                      "str_add_to_cart"
                                    )
                                  : "Add to Cart"}
                              </button>
                              <Link
                                to={`/singleproduct/${product.id}`}
                                className="btn btn-solid"
                              >
                                {getValueFromLanguageObject("str_view_detail")
                                  ? getValueFromLanguageObject(
                                      "str_view_detail"
                                    )
                                  : "view detail"}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )} */}
          </div>
          {showErrorMessageModal && (
            <ErrorMessageAnimationModal
              show={showErrorMessageModal}
              message={errorMessage}
              onClose={() => {
                this.setState({ showErrorMessageModal: false });
              }}
            />
          )}
        </div>
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(withRouter(ProductListItem));
