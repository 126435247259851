import { createStore, applyMiddleware, compose } from "redux";

// middlewares
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";

// Import custom components
import rootReducer from "../reducers";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

export const saveUserDetailsToLocalStorage = (data) => {
  try {
    const stringifyData = JSON.stringify(data);
    localStorage.setItem("userDetails", stringifyData);
  } catch (e) {
    console.log(e);
  }
  return 1;
};

export const loadUserDetailsFromLocalStorage = () => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails === null) return undefined;
    return JSON.parse(userDetails);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const setCurrentLanguage = (language) => {
  try {
    localStorage.setItem("language", language);
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentLanguage = () => {
  try {
    return localStorage.getItem("language");
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const removeCurrentLanguage = () => {
  try {
    localStorage.removeItem("language");
  } catch (e) {
    console.log(e);
  }
};

// Store language data to local storage
export const setLanguageObjectToLocalStorage = (language) => {
  try {
    const stringifyData = JSON.stringify(language);
    localStorage.setItem("languagejson", stringifyData);
  } catch (e) {
    console.log(e);
  }
};

export const getLanguageObjectToLocalStorage = () => {
  try {
    const languagejson = localStorage.getItem("languagejson");
    if (languagejson === null) return undefined;
    return JSON.parse(languagejson);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getValueFromLanguageObject = (keyword) => {
  try {
    const languagejson = localStorage.getItem("languagejson");
    if (languagejson === null) return undefined;
    if (getCurrentLanguage() === "en") {
      const enJson = JSON.parse(languagejson).en;
      return enJson[keyword];
    }

    if (getCurrentLanguage() === "ar") {
      const enJson = JSON.parse(languagejson).ar;
      return enJson[keyword];
    }

    if (getCurrentLanguage() === "zh") {
      const enJson = JSON.parse(languagejson).ch;
      return enJson[keyword];
    }
  } catch (e) {
    // console.log(e);
    return undefined;
  }
};

// Set Currency Symbol
export const setCurrencySymbol = (symbol) => {
  try {
    localStorage.setItem("currencySymbol", symbol);
  } catch (error) {
    localStorage.setItem("currencySymbol", "₹");
  }
};

// Get Currency Symbol
export const getCurrencySymbol = () => {
  try {
    return localStorage.getItem("currencySymbol");
  } catch (error) {}
};

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

// // Set Color Code to
// export const setColorCodeToLocalStorage = (color_code) => {
//   try {
//     const stringifyData = JSON.stringify(color_code);
//     localStorage.setItem("colorCode", stringifyData);
//   } catch (error) {}
// };

// // Get Color Code
export const getColorCodeBasedOnKey = (keyword) => {
  try {
    const colorCodejson = localStorage.getItem("colorCode");
    if (colorCodejson === null) return undefined;
    const color = JSON.parse(colorCodejson);
    return color[keyword];
  } catch (error) {}
};

export const getCurrentTheme = () => {
  try {
    if (localStorage.getItem("darkmode")) {
      return localStorage.getItem("darkmode");
    } else {
      localStorage.setItem("darkmode", "Light");
      return "Light";
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const setCurrentTheme = (mode) => {
  try {
    localStorage.setItem("darkmode", mode);
  } catch (e) {
    console.log(e);
  }
};

export const getDefaultLoader = () => {
  try {
    if (localStorage.getItem("default-loader")) {
      return localStorage.getItem("default-loader");
    } else {
      localStorage.setItem("default-loader", true);
      return true;
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const setDefaultLoader = (isDefaultLoader) => {
  try {
    localStorage.setItem("default-loader", isDefaultLoader);
  } catch (e) {
    console.log(e);
  }
};

export const getFirstVisitOfApp = () => {
  try {
    if (localStorage.getItem("firstVisit")) {
      return localStorage.getItem("firstVisit");
    } else {
      localStorage.setItem("firstVisit", true);
      return true;
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const setFirstVisitOfApp = () => {
  try {
    localStorage.setItem("firstVisit", false);
  } catch (e) {
    console.log(e);
  }
};

export const getOpenStoreIdApp = () => {
  return localStorage.getItem("openStoreId");
};

export const setOpenStoreIdApp = (uuid) => {
  console.log('setOpenStoreIdApp .... Calls .... ', uuid);
  return localStorage.setItem("openStoreId", uuid);
};

export const removeOpenStoreIdApp = () => {
  return localStorage.removeItem("openStoreId");
};

export const isStoreOpen = () => {
  return localStorage.getItem("isStoreOpen");
};

export const setStoreOpen = (isStoreOpen) => {
  return localStorage.setItem("isStoreOpen", isStoreOpen);
};

export const getMDIFromLocal = () => {
  return localStorage.getItem("masterDetailsId");
};

export const setMDIInLocal = (uuid) => {
  return localStorage.setItem("masterDetailsId", uuid);
};

const persistedState = loadFromLocalStorage();

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(
  rootReducer,
  persistedState,
  compose(
    applyMiddleware(thunkMiddleware),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : function(f) {
          return f;
        }
  )
);

const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  // saveToLocalStorage(state);
});

export default store;
