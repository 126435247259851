import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
} from "../../../../services";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  getCurrencySymbol,
} from "../../../../store";
import { getCall } from "../../../../api/post";
import { isInquiryOrCart } from "../../../../actions";
import { clearLocalStorageReload } from "../../../../utils/utils";

class CartHeader extends Component {
  state = {
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  render() {
    const { item, symbol, isInquiry } = this.props;
    const { masterDetailsObj } = this.state;

    return (
      <li>
        <div className="media">
          <Link to={`/singleproduct/${item.products.id}`}>
            <img
              alt=""
              className="mr-3"
              src={
                item.products.productmedia.length > 0
                  ? getImageUrl(
                      item.products.productmedia[0].productname,
                      masterDetailsObj
                    )
                  : getPlaceHolderImage()
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = getPlaceHolderImage();
              }}
            />
          </Link>
          <div className="media-body">
            <Link to={`/singleproduct/${item.products.id}`}>
              <h4>{item.products.name}</h4>
            </Link>
            <h4>
              <span>
                {` ${isInquiry ? "Qty : " : ""} ${item.quantity}`}
                { isInquiry ? <> </> : <i className="fa fa-close mx-1" style={{display:"inline-block"}} aria-hidden="true"></i> }
                {isInquiry ? (
                  <> </>
                ) : (
                  <>{symbol + parseFloat(item.amount).toFixed(2)}</>
                )}
              </span>
            </h4>
            {!isInquiry && (
              <h4>
                <span>
                  {`${
                    getValueFromLanguageObject("str_total_with_colun")
                      ? getValueFromLanguageObject("str_total_with_colun")
                      : "Total : "
                  }
                  ${symbol}
                  ${parseFloat(item.quantity * item.amount).toFixed(2)}`}
                </span>
              </h4>
            )}
          </div>
        </div>
      </li>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isInquiry: state.settings.isInquiry,
    symbol: getCurrencySymbol(),
  };
}

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(CartHeader);
