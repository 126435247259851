import React, { Component } from "react";
// import { Modal } from "react-bootstrap";
import Modal from "react-responsive-modal";
import { getValueFromLanguageObject } from "../../../store";
import PaypalExpressBtn from "react-paypal-express-checkout";
import GooglePayButton from "@google-pay/button-react";

class OrderPaymentOptionModal extends Component {
  render() {
    const {
      isEditPaymentOptionModalVisible,
      onClosepaymentOptionModal,
      paymentDetails,
      paymentMethod,
      onChangeOfPaymentMethod,
      paymnetDetailStatus,
      isHidePaymentButton,
      googlePayConfig,
      payPalConfig,
    } = this.props;
    return (
      <>
        <Modal
          open={isEditPaymentOptionModalVisible}
          onClose={onClosepaymentOptionModal}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          center
        >
          <div className="payment-box">
            <div className="upper-box">
              {paymentDetails &&
                paymentDetails.length > 0 &&
                paymentDetails[0].status === 1 && (
                  <div className="payment-options">
                    <div className="order-box mb-0">
                      <div className="title-box border-0 pb-0 mb-3">
                        {getValueFromLanguageObject("str_make_your_payment_via")
                          ? getValueFromLanguageObject(
                              "str_make_your_payment_via"
                            )
                          : "Make your payment via"}
                      </div>
                    </div>
                    <ul>
                      {paymentDetails &&
                        paymentDetails.length > 0 &&
                        paymentDetails[0].status === 1 &&
                        paymentDetails[0].text &&
                        paymentDetails[0].text.length > 0 &&
                        paymentDetails[0].text.map((paymentOption) => {
                          return paymentOption.status === 1 ? (
                            <li>
                              <div className="radio-option">
                                <input
                                  type="radio"
                                  name="payment-group"
                                  id={paymentOption.name}
                                  onClick={() => {
                                    onChangeOfPaymentMethod(paymentOption.name);
                                  }}
                                  checked={
                                    paymentMethod === paymentOption.name
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  htmlFor={paymentOption.name}
                                  style={{
                                    color: "#444444",
                                  }}
                                >
                                  {paymentOption.name}
                                  <span className="image" />
                                </label>
                              </div>
                            </li>
                          ) : (
                            ""
                          );
                        })}
                    </ul>
                  </div>
                )}
            </div>

            <div className="text-right">
              {isHidePaymentButton ? (
                <div className="payNow-record">
                  <button
                    type="button"
                    className="btn-solid btn mobile-btn-block"
                    onClick={() => paymnetDetailStatus()}
                  >
                    {getValueFromLanguageObject("str_procced_to_pay")
                      ? getValueFromLanguageObject("str_procced_to_pay")
                      : "Procced to pay"}
                  </button>
                </div>
              ) : (
                //       {/* <SadadPaymentSample
                //   sadadPaymentConfig={sadadPaymentConfig}
                //   sadadPaymentChecksum={sadadPaymentChecksum}
                //   isOpenPayWithSadad={isOpenPayWithSadad}
                //   button={strPayNow}
                //   payWithSadad={() => this.payWithSadad()}
                // /> */}

                <td>
                  {" "}
                  {paymentMethod === "Paypal" &&
                    payPalConfig &&
                    payPalConfig.env && (
                      <PaypalExpressBtn
                        env={payPalConfig.env}
                        client={payPalConfig.client}
                        currency={payPalConfig.currency}
                        total={payPalConfig.total}
                        onError={this.onErrorOfPaypalPayment}
                        onSuccess={this.onSuccessOfPaypalPayment}
                        onCancel={this.onCancelOfPaypalPayment}
                        shipping={1}
                        style={{
                          color: "gold",
                          shape: "rect",
                          size: "large",
                          label: "pay",
                        }}
                      />
                    )}
                  {paymentMethod === "GooglePay" && (
                    <GooglePayButton
                      environment="PRODUCTION" // TEST, PRODUCTION
                      buttonType="long"
                      style={{ marginTop: "10px" }}
                      paymentRequest={googlePayConfig}
                      onLoadPaymentData={this.makeGooglePayPayment}
                      onCancel={(cancel) => {
                        console.log("cancel");
                        console.log(cancel);
                        this.props.loadErrorMessageAnimation("Payment failed");
                        this.setState({ paymentResponse: cancel }, () => {
                          this.props.updatePaymentStatusGooglePay(3);
                          this.setState({
                            isEditPaymentOptionModalVisible: false,
                          });
                        });
                      }}
                      onError={(error) => {
                        console.log("error");
                        console.log(error);
                        this.props.loadErrorMessageAnimation("Payment failed");
                        this.setState({ paymentResponse: error }, () => {
                          this.props.updatePaymentStatusGooglePay(3);
                          this.setState({
                            isEditPaymentOptionModalVisible: false,
                          });
                        });
                      }}
                      onReadyToPayChange={(change) => {
                        console.log("change");
                        console.log(change);
                      }}
                      // onPaymentAuthorized={(auth) => {
                      //   console.log("auth");
                      //   console.log(auth);
                      // }}
                      // onPaymentDataChanged={(dataChange) => {
                      //   console.log("dataChange");
                      //   console.log(dataChange);
                      // }}
                    />
                  )}
                </td>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default OrderPaymentOptionModal;
