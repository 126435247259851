import React, { Component } from "react";
import Lottie from "react-lottie";
import deleteJSON from "../json/delete.json";
import { getValueFromLanguageObject } from "../../store";
import noProductFoundProductJSON from "../json/noProductFoundProductJSON.json";

class NoProductFoundProductAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: noProductFoundProductJSON,
      // resizeMode: " cover",
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  }

  render() {
    return (
      <>
        <center>
          <div className="animationSize-280-140">
            <Lottie options={this.defaultOptions} />
            <h3 className="mt-3">
              <strong style={{ fontSize: "16px" }}>
                {getValueFromLanguageObject("str_no_products_found")
                  ? getValueFromLanguageObject("str_no_products_found")
                  : "No Products Found"}
              </strong>
            </h3>
          </div>
        </center>
      </>
    );
  }
}

export default NoProductFoundProductAnim;
