import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import CartPage from "../components/common/headers/common/cart-header";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  getOpenStoreIdApp,
} from "../store";
import { getCall } from "../api/post";
import { isInquiryOrCart, getCommonCounter } from "../actions";
import {
  getSettingUrl,
  getShippingOptionsUrl,
  getMerchantInformationUrl,
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
} from "../services";
import ErrorMessageAnimationModal from "../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../utils/utils";

class CartContainer extends Component {
  constructor() {
    super();
    this.state = {
      cartList: [],
      isMerchantGSTEnable: false,
      merchantInfo: {},
      shippingCharges: [],
      currentCatelogueType: {},
      isPincodeDeliveryEnable: false,
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
      showCartDetailsDiv: false,
    };
  }

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.props.counter();
      this.initCartList();
      this.initMerchantInfo();
      this.initCatelogueSettings();
      this.initShippingCharges();
      this.initPincodeDelivery();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCartList = async () => {
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    } else {
      userDetails = userDetails === undefined ? {} : userDetails;
    }
    const { id } = userDetails;
    const openStoreId = getOpenStoreIdApp();
    let userId = openStoreId ? openStoreId : id;
    const cartListUrl = `orders?filter[where][userId]=${userId}&filter[where][inshoppingcart]=1&filter[order]=created`;

    const cartList = await getCall(cartListUrl, []);
    if (cartList.error) {
      clearLocalStorageReload({ response: cartList });
      this.loadErrorMessageAnimation(cartList.error.message);
      if (cartList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, "/login");
        return;
      }
    } else {
      this.setState({ cartList });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({ isMerchantGSTEnable: false, merchantInfo: {} });
    } else {
      this.setState({
        merchantInfo,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initShippingCharges = async () => {
    const { masterDetailsObj } = this.state;
    const shippingChargesUrl = getShippingOptionsUrl(masterDetailsObj);
    const shippingCharges = await getCall(shippingChargesUrl, []);
    if (shippingCharges.error) {
      clearLocalStorageReload({ response: shippingCharges });
    }
    this.setState({ shippingCharges: shippingCharges });
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList[0].orderdetail.length; i++) {
        total =
          total +
          cartList[0].orderdetail[i].amount *
            cartList[0].orderdetail[i].quantity;
      }
    return total;
  }

  getShippingPriceFromCart() {
    const { cartList } = this.state;
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList.length; i++) {
        total = cartList[i].shippingprice;
      }
    return total;
  }

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getShippingCharges = (total) => {
    const { shippingCharges, isPincodeDeliveryEnable } = this.state;
    var charge = 0;

    if (isPincodeDeliveryEnable) {
      return this.getShippingPriceFromCart();
    }

    if (shippingCharges.length > 0) {
      for (let index = 0; index < shippingCharges.length; index++) {
        if (
          shippingCharges[index].id === 3 &&
          shippingCharges[index].status === 1
        ) {
          for (
            let jindex = 0;
            jindex < shippingCharges[index].options.length;
            jindex++
          ) {
            if (
              !shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total &&
              shippingCharges[index].options[jindex].maxValue >= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
            if (
              shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
          }
        }
      }
    }

    return charge;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }
    return grandTotal;
  };

  deleteProductFromCart = () => {};

  UNSAFE_componentWillUpdate = (prevProps, nextState) => {
    // console.log("nextState");
    // console.log(nextState);
    // console.log("prevProps");
    // console.log(prevProps);
    if (
      this.props.cartCounter !== prevProps.cartCounter ||
      this.props.updateQuantity !== prevProps.updateQuantity
    ) {
      this.initCartList();
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      clearLocalStorageReload({ response: pincodeDelivery });
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const { symbol, cartCounter, isInquiry } = this.props;
    const {
      cartList,
      isMerchantGSTEnable,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
    } = this.state;
    return (
      <>
        <div className={` ${!isInquiry ? "" : "hide-settings-cart"}`}>
          <div className="cart-qty-cls">{cartCounter}</div>
          <Link to={`/cart`}>
            {/* <img
              src={`${process.env.PUBLIC_URL}/assets/images/icon/shopping-cart.png`}
              alt=""
              className="img-fluid"
              // onClick={() => {
              //   this.setState({ showCartDetailsDiv: true });
              // }}
            /> */}
            {getValueFromLanguageObject("str_cart")
              ? getValueFromLanguageObject("str_cart")
              : "Cart"}
            <i className="fa fa-shopping-cart ml-1" />
          </Link>

          {this.state.showCartDetailsDiv && (
            <ul className="show-div shopping-cart d-none">
              {cartList.length > 0 &&
                cartList[0].orderdetail.map((item, index) => (
                  <CartPage
                    key={index}
                    item={item}
                    symbol={symbol}
                    removeFromCart={() => this.deleteProductFromCart(item)}
                  />
                ))}

              {cartList.length > 0 ? (
                <>
                  <li>
                    {!isInquiry && (
                      <div className="total">
                        <h5>
                          {getValueFromLanguageObject(
                            "str_sub_total_with_colon"
                          )
                            ? getValueFromLanguageObject(
                                "str_sub_total_with_colon"
                              )
                            : "Sub Total : "}
                          <span>
                            {`${symbol}${parseFloat(
                              this.getTotalofCart(cartList)
                            ).toFixed(2)}`}
                          </span>
                        </h5>

                        <h5>
                          {`+ ${
                            getValueFromLanguageObject(
                              "shipping_charges_with_colun"
                            )
                              ? getValueFromLanguageObject(
                                  "shipping_charges_with_colun"
                                )
                              : "Shipping Charges : "
                          }`}

                          <span>
                            {`${symbol}${parseFloat(
                              this.getShippingCharges(
                                this.getTotalofCart(cartList)
                              )
                            ).toFixed(2)}`}
                          </span>
                        </h5>
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname === "India" && (
                            <h5>
                              {`+ ${
                                getValueFromLanguageObject("str_cgst")
                                  ? getValueFromLanguageObject("str_cgst")
                                  : "CGST"
                              } (${this.state.merchantInfo.CGST}%) :`}
                              <span>
                                {`${symbol}${parseFloat(
                                  this.getCGSTFromTotal(
                                    this.getTotalofCart(cartList)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h5>
                          )}

                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname === "India" && (
                            <h5>
                              {`+ ${
                                getValueFromLanguageObject("str_sgst")
                                  ? getValueFromLanguageObject("str_sgst")
                                  : "SGST"
                              } (${this.state.merchantInfo.SGST}%) : `}

                              <span>
                                {`${symbol}${parseFloat(
                                  this.getSGSTFromTotal(
                                    this.getTotalofCart(cartList)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h5>
                          )}

                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname !== "India" && (
                            <h5>
                              {`+ ${
                                getValueFromLanguageObject("str_igst")
                                  ? getValueFromLanguageObject("str_igst")
                                  : "IGST"
                              } (${this.state.merchantInfo.IGST}%) : `}

                              <span>
                                {`${symbol}${parseFloat(
                                  this.getIGSTFromTotal(
                                    this.getTotalofCart(cartList)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h5>
                          )}

                        <h5>
                          {`- ${
                            getValueFromLanguageObject("discount_with_colun")
                              ? getValueFromLanguageObject(
                                  "discount_with_colun"
                                )
                              : "Discount :"
                          }`}

                          <span>{`${symbol}${parseFloat(0).toFixed(2)}`}</span>
                        </h5>

                        <h5 className="grand-total">
                          {getValueFromLanguageObject(
                            "str_grand_total_with_colon"
                          )
                            ? getValueFromLanguageObject(
                                "str_grand_total_with_colon"
                              )
                            : "Grand Total :"}
                          <span>
                            {symbol}
                            {parseFloat(
                              this.getGrandTotal(
                                this.getTotalofCart(cartList),
                                this.getCGSTFromTotal(
                                  this.getTotalofCart(cartList)
                                ),
                                this.getSGSTFromTotal(
                                  this.getTotalofCart(cartList)
                                ),
                                this.getIGSTFromTotal(
                                  this.getTotalofCart(cartList)
                                ),
                                this.getShippingCharges(
                                  this.getTotalofCart(cartList)
                                )
                              )
                            ).toFixed(2)}
                          </span>
                        </h5>
                      </div>
                    )}
                  </li>
                  <li>
                    <div className="buttons">
                      <Link to={`/cart`} className="view-cart">
                        {isInquiry
                          ? getValueFromLanguageObject("str_view_inquiry")
                            ? getValueFromLanguageObject("str_view_inquiry")
                            : "view inquiry"
                          : getValueFromLanguageObject("str_view_cart")
                          ? getValueFromLanguageObject("str_view_cart")
                          : "view cart"}
                      </Link>

                      <Link to={`/checkout`} className="checkout">
                        {getValueFromLanguageObject("str_checkout")
                          ? getValueFromLanguageObject("str_checkout")
                          : "checkout"}
                      </Link>
                    </div>
                  </li>
                </>
              ) : (
                <li>
                  <h5>
                    {!isInquiry
                      ? getValueFromLanguageObject(
                          "str_no_cart_item_found_desc"
                        )
                        ? getValueFromLanguageObject(
                            "str_no_cart_item_found_desc"
                          )
                        : "You have not added any product in your cart."
                      : getValueFromLanguageObject(
                          "str_no_inquiry_item_found_desc"
                        )
                      ? getValueFromLanguageObject(
                          "str_no_inquiry_item_found_desc"
                        )
                      : "You have not added any product in your inquiry."}
                  </h5>
                </li>
              )}
            </ul>
          )}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isInquiry: state.settings.isInquiry,
    symbol: getCurrencySymbol(),
    cartCounter: state.cartCounter.cartCounter,
    updateQuantity: state.cartCounter.updateQuantity,
  };
}

export default connect(mapStateToProps, {
  isInquiryOrCart: isInquiryOrCart,
  counter: getCommonCounter,
})(withRouter(CartContainer));
