import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { MENUITEMS } from "./menu";
import "./categorybar.css";
import { isMobile } from "react-device-detect";
// Import Swiper styles
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css";
import {
  getCategoriesLastSixUrl,
  getMasterDetailsIdUrl,
  getPlaceHolderImage,
  getSubCategoryImageUrl,
} from "../../../services";
import { debuglogger } from "../../../utils/logger";
import { getCall } from "../../../api/post";
import { clearLocalStorageReload } from "../../../utils/utils";
import { getValueFromLanguageObject } from "../../../store";
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Virtual, Navigation, Pagination, Scrollbar, A11y]);

class NavBarNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: "0px", ActiveMenu: "" },
      isHovered: false,
      isHoveredAllCategories: false,
      categories: [],
      masterDetailsObj: {},
      toggleBarAllCategoriesShow: false,
      isSubCategoriesOpenToggleBar: false,
      isShowCollectionMenu: false,
      activeSteps: 0,
    };
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-300px" } });
    }
    this.initMasterDetailsId().then(() => {
      this.initCategory();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    debuglogger("masterDetailsIdUrl", masterDetailsIdUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCategory = async () => {
    const categoryUrl = getCategoriesLastSixUrl(20);
    const categories = await getCall(categoryUrl, []);
    if (categories.error) {
      clearLocalStorageReload({ response: categories });
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({
        categories: categories.categories,
        isShowCollectionMenu: categories.isShowCollectionMenu,
      });
    }
  };

  openNav() {
    this.setState({ navClose: { right: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { right: "-410px" } });
  }

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function(value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };

  //   // Click Toggle menu
  toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    // setMainMenu({ mainmenu: MENUITEMS });
  };

  openMblNav = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu"))
      event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function(value) {
        value.classList.remove("opensubmenu");
      });
      document
        .querySelector(".mega-menu-container")
        .classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  handleHover = (categoryId) => {
    this.setState({ isHovered: true, currentMenu: categoryId });
  };

  handleHoverAllCategories = (category) => {
    this.setState({
      isHoveredAllCategories: true,
      currentAllCategoryMenu: category,
    });
  };

  handleLeaveHover = () => {
    this.setState({ isHovered: false });
  };

  handleLeaveHoverAllCategories = () => {
    this.setState({ isHoveredAllCategories: false });
  };

  renderSubCategoriesNamesFromId = (categoryId) => {
    const { categories, currentMenu } = this.state || {};
    const [categoriesFiltered] = categories.filter(
      (category, index) => category.id === currentMenu
    );
    const { subcategories = [] } = categoriesFiltered || [];
    const [subCategory] = subcategories.filter(
      (subcategory) => subcategory.id === categoryId
    );
    const { name = "" } = subCategory || {};
    return name;
  };

  renderSubCategoriesMegaMenu = (categories) => {
    const { currentMenu, masterDetailsObj } = this.state || "";

    const [categoriesFiltered] = categories.filter(
      (category, index) => category.id === currentMenu
    );

    const { subcategories = [] } = categoriesFiltered || [];
    let subCategoryMediaFiltered = [];
    const subCategoryMedia = subcategories.map(
      (subcategory) =>
        (subCategoryMediaFiltered = [
          ...subCategoryMediaFiltered,
          ...subcategory.categorymedia,
        ])
    );
    // console.log("subCategoryMediaFiltered");
    // console.log(subCategoryMediaFiltered);

    return (
      <div
        className={`mega-main-div ${this.state.isHovered ? "menu-show" : ""}`}
        onMouseEnter={() => {
          this.handleHover(categoriesFiltered.id);
        }}
        onMouseLeave={this.handleLeaveHover}
      >
        <div className="categories-bg">
          <div className={`mega-menu-container`}>
            <Row>
              <div className={`col mega-box`}>
                <div className="link-section">
                  <div className="menu-title">
                    {/* <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                          {megaMenuItem.name}
                        </h5> */}
                  </div>
                  <div className="menu-content">
                    <ul>
                      {subcategories &&
                        subcategories.map((subCategory, index) => (
                          <li key={index} className={`sub-menu cursor-pointer`}>
                            <Link to={`/s/${subCategory.id}`}>
                              {subCategory.name}
                            </Link>
                            {/* <i className="fa fa-angle-right pl-2" /> */}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="topbrand-image">
                <ul>
                  {subCategoryMediaFiltered
                    .slice(0, 9)
                    .map((subCategoryMedia, i) => {
                      return (
                        <li>
                          <Link to={`/s/${subCategoryMedia.categoryId}`} className="img-box">
                            <img
                              className="responsive"
                              src={getSubCategoryImageUrl(
                                subCategoryMedia.categoryname,
                                masterDetailsObj
                              )}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getPlaceHolderImage();
                              }}
                            />
                            <span>
                              {this.renderSubCategoriesNamesFromId(
                                subCategoryMedia.categoryId
                              )}
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="collection-image">
                {subCategoryMediaFiltered
                  .slice(9, 10)
                  .map((subCategoryMedia, i) => {
                    return (
                      <div className="banner">
                        <a href="" className="img-box">
                          <img
                            className="responsive"
                            src={getSubCategoryImageUrl(
                              subCategoryMedia.categoryname,
                              masterDetailsObj
                            )}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = getPlaceHolderImage();
                            }}
                          />
                          <span>
                            {this.renderSubCategoriesNamesFromId(
                              subCategoryMedia.categoryId
                            )}
                          </span>
                        </a>
                      </div>
                    );
                  })}

                {subCategoryMediaFiltered
                  .slice(10, 11)
                  .map((subCategoryMedia, i) => {
                    return (
                      <div className="banner">
                        <a href="" className="img-box">
                          <img
                            className="responsive"
                            src={getSubCategoryImageUrl(
                              subCategoryMedia.categoryname,
                              masterDetailsObj
                            )}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = getPlaceHolderImage();
                            }}
                          />
                          <span>
                            {this.renderSubCategoriesNamesFromId(
                              subCategoryMedia.categoryId
                            )}
                          </span>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  renderAllCategoriesSubCategories = () => {
    const { categories, currentAllCategoryMenu, isSubCategoriesOpenToggleBar } =
      this.state || [];
    const { id: categoryId, name: categoryName } = currentAllCategoryMenu || {};

    const [categoriesFiltered] = categories.filter(
      (category, index) => category.id === categoryId
    );

    const { subcategories = [] } = categoriesFiltered || [];

    return (
      <div
        className={`${
          isSubCategoriesOpenToggleBar ? "d-block" : ""
        } subcategories-name`}
        onMouseLeave={() => {
          if (!isMobile) {
            this.handleLeaveHoverAllCategories();
          }
          if (isSubCategoriesOpenToggleBar) {
            this.handleLeaveHoverAllCategories();
          }
        }}
        onMouseEnter={() => {
          if (!isMobile) {
            this.handleHoverAllCategories(currentAllCategoryMenu);
          }
          if (isSubCategoriesOpenToggleBar) {
            this.handleHoverAllCategories(currentAllCategoryMenu);
          }
        }}
      >
        <li
          className="back-btn"
          onClick={() =>
            this.setState({
              toggleBarAllCategoriesShow: true,
              isSubCategoriesOpenToggleBar: false,
            })
          }
        >
          <div className="mobile-back text-right">
            <span>
              {" "}
              {getValueFromLanguageObject("str_back_to_categories")
                ? getValueFromLanguageObject("str_back_to_categories")
                : "Back to categories"}
            </span>
            <i className="fa fa-angle-right pl-2" aria-hidden="true" />
          </div>
        </li>
        <div className="categories-title">{categoryName}</div>
        <ul className="subCat">
          {subcategories.map((subCategory) => (
            <li
              onClick={(e) => {
                this.props.history.push(`/s/${subCategory.id}`);
              }}
            >
              <div className="cursor-pointer">
                {subCategory.name}
                <span class="badge bg-success text-white ml-1">
                  {subCategory.totalproducts}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const {
      currentMenu,
      categories,
      currentAllCategoryMenu,
      isHoveredAllCategories,
      toggleBarAllCategoriesShow,
      isSubCategoriesOpenToggleBar,
      isShowCollectionMenu,
    } = this.state;
    return (
      <>
        <div className="main-navbar categories-main-menu">
          <div id="mainnav">
            <div
              className="toggle-nav"
              onClick={() =>
                this.setState({ toggleBarAllCategoriesShow: true })
              }
            >
              {getValueFromLanguageObject("str_all_categories")
                ? getValueFromLanguageObject("str_all_categories")
                : "All Categories"}
              <i class="fa fa-caret-down" aria-hidden="true" />
            </div>
            <ul
              className="nav-menu"
              style={toggleBarAllCategoriesShow ? { right: "0" } : {}}
            >
              <li
                className={`${
                  isSubCategoriesOpenToggleBar ? "d-none" : ""
                } back-btn`}
                onClick={() =>
                  this.setState({ toggleBarAllCategoriesShow: false })
                }
              >
                <div className="mobile-back text-right">
                  <span>
                    {getValueFromLanguageObject("str_back_nav")
                      ? getValueFromLanguageObject("str_back_nav")
                      : "Back Nav"}
                  </span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              {/* All Categories Items */}
              <li
                className={"mega-menu all-categories"}
                onMouseEnter={() => {
                  const [category] = categories || [];
                  this.handleHoverAllCategories(category);
                }}
                onClick={() => {
                  if (!isMobile) {
                    this.props.history.push("/products");
                  }
                }}
              >
                <a
                  className={`${
                    isSubCategoriesOpenToggleBar ? "d-none" : ""
                  } nav-link`}
                >
                  {getValueFromLanguageObject("str_all_categories")
                    ? getValueFromLanguageObject("str_all_categories")
                    : "All Categories"}
                  <i class="fa fa-caret-down" aria-hidden="true" />
                </a>
                <div className="mega-menu-container all-cate-mega">
                  <div className="d-flex cat-inner">
                    <div
                      className={`${
                        isSubCategoriesOpenToggleBar ? "d-none d-md-block" : ""
                      } categories-box`}
                    >
                      <ul className="categories-name">
                        {categories &&
                          categories.map((category, index) => (
                            <li
                              onMouseLeave={() => {
                                if (!isMobile) {
                                  this.handleLeaveHoverAllCategories();
                                }
                                if (isSubCategoriesOpenToggleBar) {
                                  this.handleLeaveHoverAllCategories();
                                }
                              }}
                              onMouseEnter={() => {
                                if (!isMobile) {
                                  this.handleHoverAllCategories(category);
                                }
                                if (isSubCategoriesOpenToggleBar) {
                                  this.handleHoverAllCategories(category);
                                }
                              }}
                            >
                              <div
                                className="nav-link"
                                onClick={(e) => {
                                  this.props.history.push(`/c/${category.id}`);
                                }}
                              >
                                {category.name}
                                <span class="badge bg-success text-white ml-1">
                                  {category.totalsubcategories}
                                </span>
                              </div>
                              <i
                                className="fa fa-plus"
                                onClick={() => {
                                  this.handleHoverAllCategories(category);
                                  this.setState({
                                    isSubCategoriesOpenToggleBar: true,
                                  });
                                }}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                    {currentAllCategoryMenu &&
                      isHoveredAllCategories &&
                      this.renderAllCategoriesSubCategories()}
                  </div>
                </div>
              </li>

              <Swiper
                // spaceBetween={50}
                slidesPerView={6}
                navigation
                // virtual={true}
                onSwiper={(swiper) => {
                  // console.log("swiper");
                  // console.log(swiper);
                }}
                onActiveIndexChange={({ realIndex }) => {
                  // console.log(realIndex + 1);
                  // setActiveStep(realIndex);
                  this.setState({ activeSteps: realIndex });
                }}
              >
                {categories &&
                  categories.map((category, index) => (
                    <SwiperSlide key={`slide-${index}`} virtualIndex={index}>
                      <li
                        key={index}
                        className={`mega-menu`}
                        onMouseLeave={this.handleLeaveHover}
                        onMouseEnter={() => {
                          this.handleHover(category.id);
                        }}
                        onClick={(e) => {
                          this.props.history.push(`/c/${category.id}`);
                        }}
                      >
                        <a
                          className="nav-link"
                          // onClick={(e) => this.openMblNav(e)}
                        >
                          {category.name}
                          <span className="sub-arrow" />
                        </a>
                      </li>
                    </SwiperSlide>
                  ))}
                {isShowCollectionMenu && (
                  <SwiperSlide>
                    <li className={"mega-menu collecion-item"}>
                      <Link to={"/collection"} className="nav-link">
                        {getValueFromLanguageObject("str_collections")
                          ? getValueFromLanguageObject("str_collections")
                          : "Collections"}
                      </Link>
                    </li>
                  </SwiperSlide>
                )}
              </Swiper>

              {/* Categories Megamenu */}
              {categories &&
                currentMenu &&
                this.renderSubCategoriesMegaMenu(categories)}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(NavBarNew);
