import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { isInquiryOrCart } from "../../../actions";

class FilterBar extends Component {
  //List Layout View
  listLayout() {
    document.querySelector(".collection-grid-view").style = "opacity:0";
    document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function(el) {
      el.className = "";
      el.classList.add("col-lg-12");
    });
    setTimeout(function() {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
  }

  //Grid Layout View
  gridLayout() {
    document.querySelector(".collection-grid-view").style = "opacity:1";
    document
      .querySelector(".product-wrapper-grid")
      .classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function(el) {
      el.className = "";
      el.classList.add("col-lg-3");
    });
  }

  // Layout Column View
  LayoutView = (colSize) => {
    if (
      !document
        .querySelector(".product-wrapper-grid")
        .classList.contains("list-view")
    ) {
      var elems = document.querySelector(".infinite-scroll-component .row")
        .childNodes;
      [].forEach.call(elems, function(el) {
        el.className = "";
        el.classList.add("col-lg-" + colSize);
      });
    }

    this.props.onLayoutViewClicked(colSize);
  };

  render() {
    const { isInquiry, productList } = this.props;
    return (
      <div className="product-filter-content filter-border-bottom d-flex justify-content-between">
        <div className="search-count border-right-0">
          <h5>
            {`${
              getValueFromLanguageObject("str_showing_products")
                ? getValueFromLanguageObject("str_showing_products")
                : "Showing Products"
            } ${productList.length} ${
              getValueFromLanguageObject("str_results")
                ? getValueFromLanguageObject("str_results")
                : "Results"
            }`}
          </h5>
        </div>
        <div className="product-page-filter" style={{ maxWidth: "150px" }}>
          <select
            onChange={(e) =>
              this.props.handleSortingProductFilter(e.target.value)
            }
          >
            <option value={1}>
              {getValueFromLanguageObject("str_sorting_items")
                ? getValueFromLanguageObject("str_sorting_items")
                : "Sorting items"}
            </option>
            {!isInquiry && (
              <>
                <option value={3}>
                  {getValueFromLanguageObject("str_date_old_to_new")
                    ? getValueFromLanguageObject("str_date_old_to_new")
                    : "Date: Old to New"}
                </option>
                <option value={2}>
                  {getValueFromLanguageObject("str_date_new_to_old")
                    ? getValueFromLanguageObject("str_date_new_to_old")
                    : "Date: New to Old"}
                </option>
              </>
            )}
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
});

export default connect(mapStateToProps, {
  isInquiryOrCart: isInquiryOrCart,
})(FilterBar);
