import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  getCurrentLanguage,
} from "../../../store";
import {
  customPostCall,
  getCall,
  getDownloadPDF,
  patchCall,
  postCall,
} from "../../../api/post";
import {
  getMerchantInformationUrl,
  getSettingUrl,
  getOrderStatusUrl,
  getMasterDetailsIdUrl,
  getSadadPaymentChecksumUrl,
  getPaymentDetailsUrl,
  getDownloadInvoiceUrl,
} from "../../../services";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Moment from "react-moment";
import OrderFilterBar from "../../collection/common/order-filter-bar";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNotificationCounter, getCommonCounter } from "../../../actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import CancelOrderAnim from "../../../animation/CancelOrderAnim";
import NoProductFoundOrderAnim from "../../../animation/AnimFiles/NoProductFoundOrderAnim";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import { isInquiryOrCart } from "../../../actions";
import {
  clearLocalStorageReload,
  getCurrentDate,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { sadadPaymentConfigure } from "../../checkout/sadad_config";
import OrderPaymentOptionModal from "../sadadpaymentsample/OrderPaymentOptionModal";
import { payment } from "../../../api/payment";
import SadadPaymentSample from "../sadadpaymentsample/SadadPaymentSample";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { GooglePayConfigure } from "../../checkout/goople_pay_config";
import GooglePayButton from "@google-pay/button-react";
import { paytmConfigure } from "../../checkout/paytm_config";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import download from "downloadjs";

const SSR_SIMULATE = "SSR Simulate : ";
class Orders extends Component {
  state = {
    layoutColumns: 3,
    productList: [],
    newArrival: [],
    skip: 0,
    orderList: [],
    orderStatusList: [],
    orderStatusFilter: "0",
    paymentStatusFilter: "0",
    sortingFilter: "0",
    hasMoreItems: true,
    limit: 20,
    isLoading: true,
    AppTitle: "",
    currentCatelogueType: {},
    merchantInfo: {},
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    isInquiryState: false,
    sadadPaymentConfig: {},
    sadadPaymentChecksum: "",
    isOpenPayWithSadad: false,
    paymentMethod: "Cash On Delivery",
    orderIndex: 0,
    payUMoneyConfig: {},
    payPalConfig: {},
    googlePayConfig: {},
    paytmConfig: {},
    isHidePaymentButton: true,
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    if (isOpenStoreIdExist()) {
      navigateTo(this.props, `/`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initIsInquiry().then(() => {
        this.initOrderList().then(() => {
          this.setState({ isLoading: false });
        });
      });
      this.fetchMoreItems();
      this.initMerchantInfo();
      this.initCatelogueSettings();
      this.initOrderStatus();
      this.initPaymentDetails();
      this.props.notificationCounter();
    });
  }

  getOrderId(orderItem) {
    const { id = "" } = orderItem || {};
    return id;
  }

  getTotalProductItems = () => {
    let totalProductItems = "";
    const { orderList } = this.state;
    if (orderList.length > 0) {
      const [orderdetail] = orderList;
      const { totalitems = "" } = orderdetail || {};
      totalProductItems = totalitems;
    }
    return totalProductItems;
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      clearLocalStorageReload({ response: paymentDetails });
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  payWithSadad = async () => {
    const { paymentDetails, orderItem } = this.state;
    const { id: orderId = "" } = orderItem || {};
    const {
      id: userId,
      email: userEmail,
      cellnumber: userCellnumber,
    } = loadUserDetailsFromLocalStorage();
    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Sadad";
      }
    );
    const merchantId = paytmPaymentMethodDetails[0].config.MERCHANT_ID;
    const secretKey = paytmPaymentMethodDetails[0].config.MERCHANT_KEY;

    const sadadPaymentWebsite = process.env.SADAD_WEBSITE_URL;
    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
    const totalAmount = parseFloat(
      this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        orderItem.shippingprice
      )
    );
    const currentUrl = window.location.href;
    const callbackPageUrl = currentUrl.substring(
      0,
      currentUrl.lastIndexOf("/")
    );
    const sadadCallbackUrl = process.env.SADAD_CALLBACK_URL;
    const transactionDate = getCurrentDate();
    const sadadPageLanguage = getCurrentLanguage();
    const totalProductItems = this.getTotalProductItems(orderItem);
    const productionDetails = [
      {
        order_id: orderId,
        itemname: "Product",
        amount: totalAmount.toFixed(2),
        quantity: totalProductItems.toString(),
        // type: "line_item",
      },
    ];
    const sadadCheckoutVersion = process.env.SADAD_CHECKOUT_VERSION;
    const checksumObj = sadadPaymentConfigure({
      merchantId,
      orderId,
      sadadPaymentWebsite,
      totalAmount: totalAmount.toFixed(2),
      userId,
      userEmail,
      userCellnumber,
      sadadPageLanguage,
      sadadCallbackUrl,
      transactionDate,
      productionDetails,
      sadadCheckoutVersion,
    });
    const sadadPaymentChecksumUrl = getSadadPaymentChecksumUrl();
    const checksumResponse = await customPostCall(
      sadadPaymentChecksumUrl,
      checksumObj,
      secretKey
    );
    this.setState({ sadadPaymentConfig: checksumObj }, () => {
      this.setState({ sadadPaymentChecksum: checksumResponse.checksum }, () => {
        this.setState({ isOpenPayWithSadad: true }, () => {
          const orderPaymentForm = document.getElementById("orderPaymentForm");
          // console.log("orderPaymentForm");
          // console.log(orderPaymentForm);
          orderPaymentForm.submit();
        });
      });
    });
  };

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  // Get Inquiry Or Cart Setting
  initIsInquiry = async () => {
    const { masterDetailsObj } = this.state;
    let isInquiry = false;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const setting = await getCall(settingUrl, []);
    if (setting.error) {
      clearLocalStorageReload({ response: setting });
    } else {
      for (let i = 0; i < setting.length; i++) {
        if (setting[i].registerallow === "IS_INQUIRY") {
          if (setting[i].status === 1) {
            isInquiry = true;
          }
        }
      }
    }
    this.setState({ isInquiryState: isInquiry });
  };

  initOrderList = async () => {
    const {
      orderStatusFilter,
      paymentStatusFilter,
      skip,
      sortingFilter,
      orderList,
      limit,
      isInquiryState,
    } = this.state;
    const { isInquiry } = this.props;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const { id } = userDetails;
    let orderListUrl = `orders?filter[where][userId]=${id}&filter[where][inshoppingcart]=${
      isInquiryState ? 2 : 0
    }&filter[include]=orderdetails`;

    if (orderStatusFilter !== "0") {
      orderListUrl =
        orderListUrl + "&filter[where][orderstatus]=" + orderStatusFilter;
    }
    if (paymentStatusFilter !== "0") {
      orderListUrl =
        orderListUrl + "&filter[where][paymentstatus]=" + paymentStatusFilter;
    }

    if (sortingFilter !== "0") {
      if (sortingFilter === "1") {
        // Price: High to Low
        orderListUrl = orderListUrl + "&filter[order][1]=totalamount DESC";
      }
      if (sortingFilter === "2") {
        // Price: Low to High
        orderListUrl = orderListUrl + "&filter[order][1]=totalamount";
      }
      if (sortingFilter === "3") {
        // Date: New to Old
        orderListUrl = orderListUrl + "&filter[order][0]=date DESC";
      }
      if (sortingFilter === "4") {
        // Price: Old to New
        orderListUrl = orderListUrl + "&filter[order][0]=date";
      }
    } else {
      orderListUrl = orderListUrl + "&filter[order]=created DESC";
    }
    orderListUrl = orderListUrl + `&filter[skip]=${skip}&filter[limit]=20`;

    const orders = await getCall(orderListUrl, []);
    if (orders.error) {
      clearLocalStorageReload({ response: orders });
      this.loadErrorMessageAnimation(orders.error.message);
      if (orders.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (skip > 0) {
        const pl = orderList.concat(orders);
        if (orders.length < limit || orders.length === 0) {
          this.setState({ hasMoreItems: false });
        } else {
          this.setState({ hasMoreItems: true });
        }
        this.setState({ orderList: pl });
      } else {
        if (orders.length < limit || orders.length === 0) {
          this.setState({ hasMoreItems: false });
        } else {
          this.setState({ hasMoreItems: true });
        }
        this.setState({ orderList: orders });
      }
    }
  };

  initOrderStatus = async () => {
    const { masterDetailsObj } = this.state;
    const orderStatusUrl = getOrderStatusUrl(masterDetailsObj);
    const orderStatusList = await getCall(orderStatusUrl, []);
    if (orderStatusList.error) {
      clearLocalStorageReload({ response: orderStatusList });
      this.loadErrorMessageAnimation(orderStatusList.error.message);
      if (orderStatusList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ orderStatusList });
    }
  };

  downloadInvoice = async (orderItem) => {
    const { orderId, ordermedia } = orderItem || {};
    const { masterDetailsObj } = this.state || {};
    const downloadInvoiceUrl = getDownloadInvoiceUrl(
      masterDetailsObj,
      ordermedia
    );
    download(downloadInvoiceUrl);
    // const downloadInvoiceRepsonse = await getDownloadPDF(downloadInvoiceUrl, {
    //   id,
    // });
    // const { error } = downloadInvoiceRepsonse || {};
    // if (error) {
    //   clearLocalStorageReload({ response: downloadInvoiceRepsonse });
    // } else {
    // }
  };

  handleOrderStatusChange = (orderstatus) => {
    this.setState(
      {
        orderStatusFilter: orderstatus,
        skip: 0,
        hasMoreItems: true,
        // isLoading: true,
      },
      () => {
        this.initOrderList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handlePaymentStatusChange = (paymentstatus) => {
    // console.log("handlePaymentStatusChange");
    // console.log(paymentstatus);
    this.setState(
      {
        paymentStatusFilter: paymentstatus,
        skip: 0,
        hasMoreItems: true,
        isLoading: true,
      },
      () => {
        this.initOrderList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleSortingOrderFilter = (sort) => {
    this.setState(
      { sortingFilter: sort, skip: 0, hasMoreItems: true, isLoading: true },
      () => {
        this.initOrderList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  // onClickOfViewOrder = (order, index) => {
  //     return (<Link to={`/ordersummary/${order.id}`}></Link>);
  // }

  onClickOfCancelOrder = (order, index) => {
    const { isInquiry } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.cancelOrder(order, index).then(() => {
              this.setState({ isLoading: false });
            });
            onClose();
          });
        };
        return (
          <>
            <ConfirmAlertPopup
              title={
                !isInquiry
                  ? getValueFromLanguageObject("str_cancel_order")
                    ? getValueFromLanguageObject("str_cancel_order")
                    : "Cancel Order"
                  : getValueFromLanguageObject("str_cancel_inquiry")
                  ? getValueFromLanguageObject("str_cancel_inquiry")
                  : "Cancel Inquiry"
              }
              message={
                isInquiry
                  ? getValueFromLanguageObject(
                      "str_are_you_sure_you_want_to_cancel_inquiry"
                    )
                    ? getValueFromLanguageObject(
                        "str_are_you_sure_you_want_to_cancel_inquiry"
                      )
                    : "Are you sure you want to cancel inquiry"
                  : getValueFromLanguageObject(
                      "str_are_you_sure_you_want_to_cancel_order"
                    )
                  ? getValueFromLanguageObject(
                      "str_are_you_sure_you_want_to_cancel_order"
                    )
                  : "Are you sure you want to cancel order"
              }
              animation={<CancelOrderAnim />}
              submit={
                getValueFromLanguageObject("str_yes_cancel_it")
                  ? getValueFromLanguageObject("str_yes_cancel_it")
                  : "Yes, Cancel it!"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  cancelOrder = async (order, index) => {
    const { isInquiry } = this.props;
    const cancelOrderUrl = `orders/cancelOrder`;
    const cancelOrderObj = {
      orderstatus: 5,
      orderId: order.id,
    };

    const cancelOrder = await patchCall(cancelOrderUrl, cancelOrderObj);
    if (cancelOrder.error) {
      clearLocalStorageReload({ response: cancelOrder });
      this.loadErrorMessageAnimation(cancelOrder.error.message);
      if (cancelOrder.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_is_successfully_cancel")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_is_successfully_cancel"
              )
            : "Your inquiry is successfully cancelled"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_is_successfully_cancel")
            ? getValueFromLanguageObject(
                "msg_your_order_is_successfully_cancel"
              )
            : "Your order is successfully cancelled"
        );
      }

      this.initOrderList();
      // orderList[index].orderstatus = 5;
      // this.setState({ orderList });
    }
  };

  renderOrderStatus(orderstatus) {
    const { orderStatusList } = this.state;
    const statusItem = orderStatusList.find(
      (statusItem) => statusItem.id === orderstatus
    );
    let orderStatusClass = "";
    if (statusItem && statusItem.id) {
      orderStatusClass =
        orderstatus === statusItem.id && statusItem.status === "PENDING"
          ? "order-status-pending"
          : statusItem.id && statusItem.status === "CONFIRMED"
          ? "order-status-confirmed"
          : statusItem.id && statusItem.status === "INPROGRESS"
          ? "order-status-inprogress"
          : statusItem.id && statusItem.status === "DELIVERED"
          ? "order-status-delivered"
          : statusItem.id && statusItem.status === "CANCELLED"
          ? "order-status-cancelled"
          : "order-status-rejected";
    }

    return (
      <span className={`${orderStatusClass} order-status-color`}>
        {statusItem && statusItem.status ? statusItem.status : ""}
      </span>
    );
  }

  renderPaymentStatus(paymentstatus) {
    if (paymentstatus === 1) {
      return (
        <span className={`order-status-pending order-status-color`}>
          PENDING
        </span>
      );
    } else if (paymentstatus === 2) {
      return (
        <span className={`order-status-confirmed order-status-color`}>
          SUCCESS
        </span>
      );
    } else {
      return (
        <span className={`order-status-rejected order-status-color`}>
          FAILED
        </span>
      );
    }
  }

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  fetchMoreItems = () => {
    const { orderList } = this.state;
    this.handlePagination(orderList.length);
  };

  handlePagination = (skip) => {
    this.setState({ skip }, () => {
      this.initOrderList();
    });
  };

  getTotalofCart(orderList) {
    let total = 0;
    const { orderdetail } = orderList || {};
    if (orderdetail)
      for (let i = 0; i < orderdetail.length; i++) {
        total = total + orderdetail[i].amount * orderdetail[i].quantity;
      }

    return total;
  }

  getSubTotalFromTotalandServiceCharges = (orderItem) => {
    let subTotal = 0;
    const total = this.getTotalofCart(orderItem);
    const serviceChargeAmount = this.renderServiceCharges(orderItem);
    subTotal = total + parseFloat(serviceChargeAmount);
    return subTotal;
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }
    return grandTotal;
  };

  renderServiceCharges = (orderItem) => {
    let serviceCharges = 0;
    const { additionalchargedetails } = orderItem || {};
    if (additionalchargedetails) {
      const additionalchargedetailsObj =
        JSON.parse(additionalchargedetails) || {};
      const { charges = 0 } = additionalchargedetailsObj || {};
      serviceCharges = parseFloat(charges).toFixed(2);
    }
    return serviceCharges;
  };

  renderOrderCancelIcon = (item, index) => {
    const { orderStatusList } = this.state;
    return (
      <>
        {orderStatusList.map((statusItem) => {
          return statusItem.id === item.orderstatus &&
            (statusItem.status === "pending" ||
              statusItem.status === "PENDING") ? (
            <div
              className="icon"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Cancel Order"
            >
              <a href onClick={() => this.onClickOfCancelOrder(item, index)}>
                <i className="fa fa-times" />
              </a>
            </div>
          ) : (
            <></>
          );
        })}
      </>
    );
  };

  renderPaymentButton = (item, index) => {
    const {
      sadadPaymentConfig,
      sadadPaymentChecksum,
      isOpenPayWithSadad,
      orderIndex,
      isHidePaymentButton,
      paymentMethod,
      orderStatusList,
    } = this.state;
    const strPayNow = getValueFromLanguageObject("str_pay_now")
      ? getValueFromLanguageObject("str_pay_now")
      : "Pay now";

    return (
      <>
        {item.paymentstatus !== 2 && isHidePaymentButton && (
          <td>
            <div className="payNow-record">
              <button
                className="btn btn-solid"
                onClick={() => {
                  this.setState({
                    isEditPaymentOptionModalVisible: true,
                    orderIndex: index,
                  });
                }}
              >
                {strPayNow}
              </button>
            </div>
            {/* <SadadPaymentSample
              sadadPaymentConfig={sadadPaymentConfig}
              sadadPaymentChecksum={sadadPaymentChecksum}
              isOpenPayWithSadad={isOpenPayWithSadad}
              button={strPayNow}
              payWithSadad={() => this.payWithSadad()}
            /> */}
          </td>
        )
        // : (
        //   <td>
        //     {" "}
        //     {/* {paymentMethod === "Paypal" &&
        //       this.state.payPalConfig &&
        //       this.state.payPalConfig.env && (
        //         <PaypalExpressBtn
        //           env={this.state.payPalConfig.env}
        //           client={this.state.payPalConfig.client}
        //           currency={this.state.payPalConfig.currency}
        //           total={this.state.payPalConfig.total}
        //           onError={this.onErrorOfPaypalPayment}
        //           onSuccess={this.onSuccessOfPaypalPayment}
        //           onCancel={this.onCancelOfPaypalPayment}
        //           shipping={1}
        //           style={{
        //             color: "gold",
        //             shape: "rect",
        //             size: "large",
        //             label: "pay",
        //           }}
        //         />
        //       )} */}
        //     {/* {paymentMethod === "GooglePay" && (
        //       <GooglePayButton
        //         environment="PRODUCTION" // TEST, PRODUCTION
        //         buttonType="long"
        //         style={{ marginTop: "10px" }}
        //         paymentRequest={this.state.googlePayConfig}
        //         onLoadPaymentData={this.makeGooglePayPayment}
        //         onCancel={(cancel) => {
        //           console.log("cancel");
        //           console.log(cancel);
        //           this.loadErrorMessageAnimation("Payment failed");
        //           this.setState({ paymentResponse: cancel }, () => {
        //             this.updatePaymentStatusGooglePay(3);
        //             this.setState({
        //               isEditPaymentOptionModalVisible: false,
        //             });
        //           });
        //         }}
        //         onError={(error) => {
        //           console.log("error");
        //           console.log(error);
        //           this.loadErrorMessageAnimation("Payment failed");
        //           this.setState({ paymentResponse: error }, () => {
        //             this.updatePaymentStatusGooglePay(3);
        //             this.setState({
        //               isEditPaymentOptionModalVisible: false,
        //             });
        //           });
        //         }}
        //         onReadyToPayChange={(change) => {
        //           console.log("change");
        //           console.log(change);
        //         }}
        //         // onPaymentAuthorized={(auth) => {
        //         //   console.log("auth");
        //         //   console.log(auth);
        //         // }}
        //         // onPaymentDataChanged={(dataChange) => {
        //         //   console.log("dataChange");
        //         //   console.log(dataChange);
        //         // }}
        //       />
        //     )} */}
        //   </td>
        // )
        }
      </>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };
  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  renderOrderTableTab = () => {
    const { isInquiry } = this.props;

    const strInquiryNo = getValueFromLanguageObject("str_inquiry_no")
      ? getValueFromLanguageObject("str_inquiry_no")
      : "Inquiry No";

    const strOrderNo = getValueFromLanguageObject("order_no_")
      ? getValueFromLanguageObject("order_no_")
      : "Order No";

    const strDate = getValueFromLanguageObject("str_date")
      ? getValueFromLanguageObject("str_date")
      : "Date";

    const strItems = getValueFromLanguageObject("str_items")
      ? getValueFromLanguageObject("str_items")
      : "items";

    const strInquiryStatus = getValueFromLanguageObject("str_inquiry_status")
      ? getValueFromLanguageObject("str_inquiry_status")
      : "Inquiry Status";

    const strOrderStatus = getValueFromLanguageObject("str_order_status")
      ? getValueFromLanguageObject("str_order_status")
      : "Order Status";

    const strAction = getValueFromLanguageObject("str_action")
      ? getValueFromLanguageObject("str_action")
      : "action";

    const strPaymentStatus = getValueFromLanguageObject("str_payment_status")
      ? getValueFromLanguageObject("str_payment_status")
      : "Payment Status";

    const strTotal = getValueFromLanguageObject("str_total")
      ? getValueFromLanguageObject("str_total")
      : "total";

    return (
      <thead>
        <tr className="table-head">
          <th scope="col" className="pb-1 pt-1">
            {isInquiry ? strInquiryNo : strOrderNo}
          </th>
          <th scope="col" className="pb-1 pt-1">
            {strDate}
          </th>
          <th scope="col" className="pb-1 pt-1">
            {strItems}
          </th>
          <th scope="col" className="pb-1 pt-1">
            {isInquiry ? strInquiryStatus : strOrderStatus}
          </th>
          {!isInquiry && (
            <th scope="col" className="pb-1 pt-1">
              {strPaymentStatus}
            </th>
          )}
          <th scope="col" className="pb-1 pt-1">
            {strTotal}
          </th>
          <th scope="col" className="pb-1 pt-1">
            {strAction}
          </th>
        </tr>
      </thead>
    );
  };

  loadCheckoutScript = () => {
    const { paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Paytm";
      }
    );
    const mId = paytmPaymentMethodDetails[0].config.PaytmMerchantID;

    // Development URL
    // const url =
    //   "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/";

    // Production URL
    const url = "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/";

    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = url.concat(mId);
    scriptElement.type = "application/javascript";
    scriptElement.onload = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.setState({
            checkoutJsInstance,
          });
        });
      } else {
        console.error(SSR_SIMULATE + "onload not available!");
      }
    };
    scriptElement.onerror = (error) => {
      console.error("SSR Simulate: script load fail!");
    };
    document.body.appendChild(scriptElement);
    this.setState({ isScriptLoading: true });
  };

  getCheckoutJsObj() {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    } else {
      console.error(SSR_SIMULATE + "Checkout instance not found!");
    }

    return null;
  }

  makePaytmPayment = () => {
    this.loadCheckoutScript();
  };

  onChangeOfPaymentMethod = (paymentMethod) => {
    if (paymentMethod === "Cash On Delivery") {
      this.setState({ paymentMethod });
    }
    if (paymentMethod === "Paytm") {
      this.setState({ paymentMethod });
    }
    if (paymentMethod === "RazorPay") {
      this.setState({ paymentMethod });
    }

    if (paymentMethod === "PayU") {
      this.setState({ paymentMethod });
    }

    if (paymentMethod === "Paypal") {
      this.setState({ paymentMethod });
    }
    if (paymentMethod === "GooglePay") {
      this.setState({ paymentMethod }, () => this.initGooglePayPayment());
    }
    if (paymentMethod === "Sadad") {
      this.setState({ paymentMethod });
    }
  };

  renderLogoDynamic = async () => {
    const { masterDetailsObj } = this.state;
    let path = `${process.env.SERVER_URL}`;
    if (masterDetailsObj && masterDetailsObj.id) {
      const logoUrl = getMerchantInformationUrl(masterDetailsObj);
      const logoResponse = await getCall(logoUrl, []);
      if (logoResponse.error) {
        clearLocalStorageReload({ response: logoResponse });
        return;
      } else {
        path = `${path}/api/containers/profilepic-${
          masterDetailsObj.codename
        }/download/${logoResponse.profilepic}`;
        return path;
      }
    }
  };

  initRazorPayPayment = async () => {
    const {
      isMerchantGSTEnable,
      orderList,
      AppTitle,
      currentCatelogueType,
      paymentDetails,
      masterDetailsObj,
      orderItem,
      isEditPaymentOptionModalVisible,
    } = this.state;
    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
    const paymentamount = this.getGrandTotal(
      subTotal,
      this.getCGSTFromTotal(subTotal),
      this.getSGSTFromTotal(subTotal),
      this.getIGSTFromTotal(subTotal),
      orderItem.shippingprice
    );
    const userDetails = loadUserDetailsFromLocalStorage();
    const logo = this.renderLogoDynamic(masterDetailsObj);
    var paymentAmount = 0;
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      paymentAmount = paymentamount;
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      paymentAmount = paymentamount;
    } else {
      paymentAmount = paymentamount;
    }

    if (paymentDetails && paymentDetails.length > 0 && paymentDetails[0]) {
      const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
        (paymentMethods) => {
          return paymentMethods.name === "RazorPay";
        }
      );
      const mId = paytmPaymentMethodDetails[0].config.key_id;
      const orderId = this.getOrderId(orderItem);
      const paymentResponse = await payment({
        key: mId,
        clientName: AppTitle,
        clientLogo: logo,
        amount: paymentAmount.toFixed(2),
        mobile: userDetails.cellnumber,
        email: userDetails.email,
        name: userDetails.username,
        currency: "INR",
        orderId,
      });
      if (paymentResponse.error) {
        this.loadErrorMessageAnimation(paymentResponse.error.message);
        this.setState({ paymentResponse }, () => {
          this.updatePaymentStatusRazorPay(3);
        });
      } else {
        //TODO add in language json
        this.loadSuccessMessageAnimation("Payment is successfully completed");
        this.setState({ paymentResponse }, () => {
          this.updatePaymentStatusRazorPay(2);
        });
        this.setState({ isEditPaymentOptionModalVisible: false });
      }
    }
  };

  updatePaymentStatusRazorPay = async (paymentStatus) => {
    const { paymentResponse, paymentMethod, orderItem } = this.state;
    const orderId = this.getOrderId(orderItem);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;
    let transactionNumber = "";
    if (paymentMethod === "RazorPay") {
      transactionNumber = paymentResponse.razorpay_payment_id;
    }
    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      order_id: orderId,
    };

    this.setState({ isLoading: true });
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    this.setState({ isLoading: false });

    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    }
    this.initOrderList();
  };

  initUpdatePaymentTransactionId = async () => {
    const {
      orderList,
      paymentResponse,
      paymentMethod,
      isEditPaymentOptionModalVisible,
      orderItem,
    } = this.state;
    const updatePaymentTransactionIdUrl = `orders/${this.getOrderId(
      orderItem
    )}`;
    let transactionNumber = "";
    if (paymentMethod === "RazorPay") {
      transactionNumber = paymentResponse.razorpay_payment_id;
    }
    if (paymentMethod === "PayU") {
      transactionNumber = paymentResponse.encryptedPaymentId;
    }

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      order_id: paymentMethod === "PayU" ? paymentResponse.txnid : "",
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ isLoading: true });
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("Payment_Successful")
          ? getValueFromLanguageObject("Payment_Successful")
          : "Payment Successful!"
      );
      this.setState({ isEditPaymentOptionModalVisible: false });
      this.setState({ isLoading: false });
    }
  };

  initUpdatePaymentStatusCOD = async () => {
    const {
      orderItem,
      paymentResponse,
      paymentMethod,
      isEditPaymentOptionModalVisible,
    } = this.state;
    const orderId = this.getOrderId(orderItem);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;
    let transactionNumber = "";

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: 1, // Pending
      order_id: orderId,
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      // this.setState({ isEditPaymentOptionModalVisible: false });
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.loadSuccessMessageAnimation("Payment is successfully completed");
    }
    this.setState({ isEditPaymentOptionModalVisible: false });
    this.initOrderList();
    // else {
    //   setTimeout(() => {
    //     this.props.history.push(`/orderdetails/${orderId}`);
    //   }, 1000);
    // }
  };

  updatePaymentStatusPayUMoney = async (paymentStatus) => {
    const { orderItem, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(orderItem);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;

    const { response } = paymentResponse || {};
    const { encryptedPaymentId, txnid } = response || {};

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: encryptedPaymentId,
        other_transaction_details: response,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      order_id: txnid,
    };

    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );

    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      // TODO Add to language object
      this.loadSuccessMessageAnimation("Payment is successfully completed");
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${orderId}`);
      }, 1000);
    }
  };

  // getProductIds = (orderItem) => {
  //   console.log(orderItem);
  //   console.log("productID");
  //   console.log(orderItem.orderdetail[0].productId);

  // };

  initPayUMoneyPayment = async () => {
    try {
      const { orderItem, paymentDetails } = this.state;

      // Get User Details From Local Storage
      const userDetails = loadUserDetailsFromLocalStorage();
      const { billingaddress } = userDetails;
      const billingaddressObj = billingaddress
        ? JSON.parse(billingaddress)
        : {};

      const { name, email, cellnumber } = billingaddressObj;
      const orderId = this.getOrderId(orderItem);
      // Get Selected Payment Details from payment details config
      const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
        (paymentMethods) => {
          return paymentMethods.name === "PayU";
        }
      );
      // Merchant Key
      const key = paytmPaymentMethodDetails[0].config.PayUMerchantKey;

      // Total Amount
      const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
      const totalAmount = this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        orderItem.shippingprice
      );

      // Product Details
      const productInfo = orderItem.orderdetail[0].productId;

      // Row Object for checkou script
      var rowObject = {
        key: key,
        txnid: orderId,
        amount: totalAmount,
        firstname: name,
        email,
        phone: cellnumber,
        productinfo: productInfo,
        surl: `/${process.env.PUBLIC_URL}/checkout`,
        furl: `/${process.env.PUBLIC_URL}/checkout`,
        hash: "",
      };

      const { txnid, amount, productinfo, firstname } = rowObject;

      // Generate Checksum Object
      let data = {
        txnId: txnid,
        email,
        amount,
        productinfo: productinfo,
        firstname: firstname,
      };

      this.setState({ isLoading: true });
      const payUMoneyHash = await postCall("orders/genratepayumoneyhash", data);

      this.setState({ isLoading: false });
      if (payUMoneyHash && payUMoneyHash.error) {
        clearLocalStorageReload({ response: payUMoneyHash });
        this.loadErrorMessageAnimation(payUMoneyHash.error.message);
        if (payUMoneyHash.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
        // Update Payment Status if any error and redirect user to order summary page
        setTimeout(() => {
          this.setState({ isLoading: true });
          this.updatePaymentStatusPayUMoney(3).then(() => {
            this.setState({ isLoading: false });
            this.setState({ isEditPaymentOptionModalVisible: false });
          });
        }, 750);
      } else {
        rowObject.hash = payUMoneyHash.hash;
        this.setState({ payUMoneyConfig: rowObject }, () => {
          this.loadPayUMoneyBoltCheckout();
        });
      }
    } catch (error) {
      // Update Payment Status if any error and redirect user to order summary page
      this.setState({ isLoading: true });
      this.updatePaymentStatusPayUMoney(3).then(() => {
        this.setState({ isLoading: false });
        this.setState({ isEditPaymentOptionModalVisible: false });
      });
    }
  };

  loadPayUMoneyBoltCheckout = () => {
    const { payUMoneyConfig } = this.state;

    window.bolt.launch(payUMoneyConfig, {
      responseHandler: (response) => {
        // console.log("response");
        // console.log(response);
        // your payment response Code goes here
        if (response.response.status === "success") {
          this.setState(
            {
              paymentResponse: response,
              isLoading: true,
            },
            () => {
              this.updatePaymentStatusPayUMoney(2).then(() => {
                this.setState({ isLoading: false });
                this.setState({ isEditPaymentOptionModalVisible: false });
              });
            }
          );
        } else if (response.response.txnStatus === "CANCEL") {
          this.loadErrorMessageAnimation(response.response.txnMessage);
          setTimeout(() => {
            this.setState({ isLoading: true });
            this.updatePaymentStatusPayUMoney(3).then(() => {
              this.setState({ isLoading: false });
              this.setState({ isEditPaymentOptionModalVisible: false });
            });
          }, 750);
        }
      },
      catchException: (error) => {
        this.setState({ isLoading: true });
        this.updatePaymentStatusPayUMoney(3).then(() => {
          this.setState({ isLoading: false });
          this.setState({ isEditPaymentOptionModalVisible: false });
        });
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
      },
    });
  };

  updatePaymentStatusPayUMoney = async (paymentStatus) => {
    const { orderItem, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(orderItem);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;

    const { response } = paymentResponse || {};
    const { encryptedPaymentId, txnid } = response || {};

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: encryptedPaymentId,
        other_transaction_details: response,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      order_id: txnid,
    };

    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );

    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      // TODO Add to language object
      this.loadSuccessMessageAnimation("Payment is successfully completed");
      // setTimeout(() => {
      //   this.props.history.push(`/orderdetails/${orderId}`);
      // }, 1000);
    }
  };

  // Paypal - 1
  initPaypalPayment = () => {
    const { orderItem, paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Paypal";
      }
    );
    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
    let obj = {
      env: "production", // set this to prduction
      currency: "USD", // Paypal is not allowing user to pay in india
      total: this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        orderItem.shippingprice
      ),
      client: {
        sandbox: paytmPaymentMethodDetails[0].config.PaypalClientToken,
        production: paytmPaymentMethodDetails[0].config.PaypalClientToken,
      },
    };
    this.setState({ payPalConfig: obj }, () => {});
  };

  // Paypal - 2
  onSuccessOfPaypalPayment = (payment) => {
    // Congratulation, it came here means everything's fine!
    console.log("The payment was succeeded!", payment);
    if (payment.paid) {
      // TODO Add to language object
      this.loadSuccessMessageAnimation("Payment successfully completed");
      this.setState({ paymentResponse: payment }, () => {
        this.updatePaymentStatusPayPal(2);
      });
    } else {
      // TODO Add to language object
      this.loadErrorMessageAnimation("Payment failed");
      this.setState({ paymentResponse: payment }, () => {
        this.updatePaymentStatusPayPal(3);
      });
    }
    this.initOrderList();
    this.setState({ isEditPaymentOptionModalVisible: false });
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  // Paypal - 3
  onCancelOfPaypalPayment = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    // TODO Add to language object
    this.loadErrorMessageAnimation("Payment failed");
    this.setState({ paymentResponse: data }, () => {
      this.updatePaymentStatusPayPal(3);
      this.setState({ isEditPaymentOptionModalVisible: false });
      this.initOrderList();
    });
  };

  // Paypal - 4
  onErrorOfPaypalPayment = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    // TODO Add to language object
    this.loadErrorMessageAnimation("Payment failed");
    this.setState({ paymentResponse: err }, () => {
      this.updatePaymentStatusPayPal(3);
      this.setState({ isEditPaymentOptionModalVisible: false });
      this.initOrderList();
    });
  };

  // Paypal - 5
  updatePaymentStatusPayPal = async (paymentStatus) => {
    const { orderItem, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(orderItem);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;
    const { paymentID, payerID } = paymentResponse;

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: paymentID,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      orderId: payerID,
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${orderId}`);
      }, 1000);
    }
  };

  // Google Pay - 1
  initGooglePayPayment = () => {
    const { orderItem, paymentDetails } = this.state;
    const { shippingprice } = orderItem || {};

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "GooglePay";
      }
    );
    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
    const totalAmount = this.getGrandTotal(
      subTotal,
      this.getCGSTFromTotal(subTotal),
      this.getSGSTFromTotal(subTotal),
      this.getIGSTFromTotal(subTotal),
      shippingprice
    );

    let obj = {
      merchantId: paytmPaymentMethodDetails[0].config.MERCHANT_ID,
      merchantName: paytmPaymentMethodDetails[0].config.MERCHANT_NAME,
      amount: totalAmount,
      // amount: this.getGrandTotal(
      //   this.getTotalofCart(orderItem),
      //   this.getCGSTFromTotal(this.getTotalofCart(orderItem)),
      //   this.getSGSTFromTotal(this.getTotalofCart(orderItem)),
      //   this.getIGSTFromTotal(this.getTotalofCart(orderItem)),
      //   orderItem.shippingprice
      // ),
      gateway: "example",
    };

    const gPayConfig = GooglePayConfigure(obj);
    this.setState({ googlePayConfig: gPayConfig });
  };

  // Google Pay - 2
  makeGooglePayPayment = (payment) => {
    // TODO Add to language object
    this.loadSuccessMessageAnimation("Payment is successfully completed");
    this.setState({ paymentResponse: payment }, () => {
      this.updatePaymentStatusGooglePay(2);
      this.setState({ isEditPaymentOptionModalVisible: false });
      this.initOrderList();
    });
  };

  // Google Pay - 3
  updatePaymentStatusGooglePay = async (paymentStatus) => {
    const { orderItem, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(orderItem);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;

    const { paymentMethodData } = paymentResponse || {};
    const { tokenizationData } = paymentMethodData || {};
    const { token } = tokenizationData || {};

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: "",
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      orderId: orderId,
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    }
    // setTimeout(() => {
    //   this.props.history.push(`/orderdetails/${orderId}`);
    // }, 1000);
  };

  appendHandler(config) {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler,
    };

    return newConfig;
  }

  // PayTM
  generateChecksumPaytmPayment = async () => {
    const { orderItem, paymentDetails } = this.state;
    const orderId = this.getOrderId(orderItem);
    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
    const totalAmount = this.getGrandTotal(
      subTotal,
      this.getCGSTFromTotal(subTotal),
      this.getSGSTFromTotal(subTotal),
      this.getIGSTFromTotal(subTotal),
      orderItem.shippingprice
    );

    const initPaytmPaymentUrl = "orders/genratepaytmchecksum";
    let initPaytmPaymentObj = {
      orderId: orderId,
      amount: totalAmount,
      callbackUrl: window.location.href,
    };
    this.setState({ isPlaceOrderButtonDisable: true }, async () => {
      this.setState({ isLoading: true });
      const initPaytmPaymentResponse = await postCall(
        initPaytmPaymentUrl,
        initPaytmPaymentObj
      );
      this.setState({ isLoading: false, isPlaceOrderButtonDisable: false });
      if (initPaytmPaymentResponse.error) {
        this.loadErrorMessageAnimation(initPaytmPaymentResponse.error.message);
        clearLocalStorageReload({ response: initPaytmPaymentResponse });
      }
      if (
        initPaytmPaymentResponse &&
        initPaytmPaymentResponse.body &&
        initPaytmPaymentResponse.body.txnToken
      ) {
        this.setState({ isPlaceOrderButtonDisable: false });
        if (paymentDetails && paymentDetails.length > 0 && paymentDetails[0]) {
          const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
            (paymentMethods) => {
              return paymentMethods.name === "Paytm";
            }
          );
          const mId = paytmPaymentMethodDetails[0].config.PaytmMerchantID;
          const logo = this.renderLogoDynamic();
          const subTotal = this.getSubTotalFromTotalandServiceCharges(
            orderItem
          );
          let obj = {
            mid: "" + mId,
            orderId: orderId,
            amount: this.getGrandTotal(
              subTotal,
              this.getCGSTFromTotal(subTotal),
              this.getSGSTFromTotal(subTotal),
              this.getIGSTFromTotal(subTotal),
              orderItem.shippingprice
            ),
            token: initPaytmPaymentResponse.body.txnToken,
            merchantName:
              paytmPaymentMethodDetails[0].config.paymentMerchantName,
            logo: logo,
            callbackUrl: window.location.href,
          };
          const paytmC = this.appendHandler(paytmConfigure(obj));
          this.setState({ paytmConfig: paytmC }, () => {
            this.makePaytmPayment();
          });
        }
      } else if (
        initPaytmPaymentResponse &&
        initPaytmPaymentResponse.body &&
        initPaytmPaymentResponse.body.resultInfo
      ) {
        this.loadErrorMessageAnimation(
          initPaytmPaymentResponse.body.resultInfo.resultMsg
        );
      }
    });
  };

  paymnetDetailStatus = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { paymentMethod, paymentDetails, orderIndex, orderList } = this.state;
    const orderItem = orderList[orderIndex];

    if (
      paymentDetails &&
      paymentDetails.length > 0 &&
      paymentDetails[0].status === 0
    ) {
    } else {
      if (paymentMethod === "Cash On Delivery") {
        this.setState({ orderItem }, () => {
          this.initUpdatePaymentStatusCOD();
        });
      } else if (paymentMethod === "RazorPay") {
        this.setState({ orderItem }, () => {
          this.initRazorPayPayment();
        });
      } else if (paymentMethod === "Paytm") {
        this.setState({ orderItem }, () => {
          setTimeout(() => {
            this.generateChecksumPaytmPayment();
          }, 500);
        });
      } else if (paymentMethod === "PayU") {
        this.setState({ orderItem }, () => {
          setTimeout(() => {
            this.initPayUMoneyPayment();
          }, 750);
        });
      } else if (paymentMethod === "Paypal") {
        this.setState({ orderItem, isHidePaymentButton: false }, () => {
          setTimeout(() => {
            this.initPaypalPayment();
          }, 750);
        });
      } else if (paymentMethod === "GooglePay") {
        this.setState({ orderItem, isHidePaymentButton: false }, () => {
          this.initGooglePayPayment();
        });
      } else if (paymentMethod === "Paytm") {
        this.setState({ orderItem }, () => {
          this.generateChecksumPaytmPayment();
        });
      } else if (paymentMethod === "Sadad") {
        this.setState({ orderItem }, () => {
          this.payWithSadad();
        });
      }
    }
  };

  renderGrandTotal = (orderItem) => {
    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderItem);
    const total = parseFloat(
      this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        orderItem.shippingprice
      )
    ).toFixed(2);

    return total;
  };

  render() {
    const { symbol } = this.props;
    const { isInquiry } = this.props;

    const {
      orderList,
      orderStatusList,
      hasMoreItems,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      sadadPaymentConfig,
      sadadPaymentChecksum,
      isOpenPayWithSadad,
      isEditPaymentOptionModalVisible,
      paymentDetails,
      paymentMethod,
      isHidePaymentButton,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>
            {AppTitle} |{" "}
            {isInquiry
              ? getValueFromLanguageObject("str_inquiry_page")
                ? getValueFromLanguageObject("str_inquiry_page")
                : "Inquiry Page"
              : getValueFromLanguageObject("str_order_page")
              ? getValueFromLanguageObject("str_order_page")
              : "Order Page"}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {isInquiry ? (
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_inquiry")
                ? getValueFromLanguageObject("str_inquiry")
                : "Inquiry"
            }
          />
        ) : (
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_orders")
                ? getValueFromLanguageObject("str_orders")
                : "Orders"
            }
          />
        )}

        <section className="cart-section section-b-space">
          <div className="container">
            <div className="collection-product-wrapper order-page-filter">
              <div className="product-top-filter">
                <div className="container-fluid p-0">
                  {/* <div className="row">
                                        <div className="col-xl-12">
                                            <div className="filter-main-btn">
                                                <span onClick={this.openFilter}
                                                    className="filter-btn btn btn-theme"><i
                                                        className="fa fa-filter"
                                                        aria-hidden="true"></i> Filter</span>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="row">
                    <div className="col-12 ">
                      <OrderFilterBar
                        orderList={orderList}
                        orderStatusList={orderStatusList}
                        handleOrderStatusChange={this.handleOrderStatusChange}
                        handlePaymentStatusChange={
                          this.handlePaymentStatusChange
                        }
                        handleSortingOrderFilter={this.handleSortingOrderFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InfiniteScroll
              dataLength={orderList.length} //This is important field to render the next data
              next={this.fetchMoreItems}
              hasMore={hasMoreItems}
              loader={<div className="loading-cls" />}
              // endMessage={
              //     <p className="seen-cls seen-it-cls">
              //         <b>Yay! You have seen it all</b>
              //     </p>
              // }
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 p-0">
                    <div className="table-responsive">
                      <table className="table cart-table table-responsive-xs">
                        {this.renderOrderTableTab()}

                        {orderList.map((item, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <Link to={`/ordersummary/${item.id}`}>
                                    {item.orderno}
                                  </Link>
                                </td>
                                <td>
                                  <Moment format="DD-MMM-YYYY">
                                    {item.date}
                                  </Moment>
                                </td>
                                <td>
                                  <div className="qty-box">
                                    {item.orderdetail !== undefined
                                      ? item.orderdetail.length
                                      : 0}
                                  </div>
                                </td>
                                <td>
                                  {this.renderOrderStatus(item.orderstatus)}
                                </td>
                                <td>
                                  {this.renderPaymentStatus(item.paymentstatus)}
                                </td>
                                {!isInquiry && (
                                  <td>
                                    <div className="td-color">
                                      {symbol}
                                      {this.renderGrandTotal(item)}
                                    </div>
                                  </td>
                                )}

                                <td>
                                  <div
                                    className="icon"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="View Order"
                                  >
                                    <Link to={`/ordersummary/${item.id}`}>
                                      <i className="fa fa-eye" />
                                    </Link>
                                  </div>
                                  {item && item.ordermedia && (
                                    <div
                                      className="icon"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Download Invoice"
                                      onClick={() => {
                                        this.downloadInvoice(item);
                                      }}
                                    >
                                      <i
                                        className="fa fa-file-text-o"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  {item.orderstatus &&
                                    this.renderOrderCancelIcon(item, index)}
                                </td>
                                {item.orderstatus &&
                                  (item.orderstatus === 1 ||
                                    item.orderstatus === 3) &&
                                  this.renderPaymentButton(item, index)}
                              </tr>
                              {isEditPaymentOptionModalVisible && (
                                <OrderPaymentOptionModal
                                  isEditPaymentOptionModalVisible={
                                    isEditPaymentOptionModalVisible
                                  }
                                  onClosepaymentOptionModal={() => {
                                    this.setState({
                                      isEditPaymentOptionModalVisible: false,
                                    });
                                  }}
                                  paymentDetails={paymentDetails}
                                  paymentMethod={paymentMethod}
                                  onChangeOfPaymentMethod={
                                    this.onChangeOfPaymentMethod
                                  }
                                  paymnetDetailStatus={() =>
                                    this.paymnetDetailStatus()
                                  }
                                  isHidePaymentButton={isHidePaymentButton}
                                  googlePayConfig={this.state.googlePayConfig}
                                  makeGooglePayPayment={
                                    this.makeGooglePayPayment
                                  }
                                  payPalConfig={this.state.payPalConfig}
                                  onErrorOfPaypalPayment={
                                    this.onErrorOfPaypalPayment
                                  }
                                  onSuccessOfPaypalPayment={
                                    this.onSuccessOfPaypalPayment
                                  }
                                  onCancelOfPaypalPayment={
                                    this.onCancelOfPaypalPayment
                                  }
                                  loadErrorMessageAnimation={
                                    this.loadErrorMessageAnimation
                                  }
                                  updatePaymentStatusGooglePay={
                                    this.updatePaymentStatusGooglePay
                                  }
                                  index={index}
                                />
                              )}
                              {/* <SadadPaymentSample
                                sadadPaymentConfig={sadadPaymentConfig}
                                sadadPaymentChecksum={sadadPaymentChecksum}
                                isOpenPayWithSadad={isOpenPayWithSadad}
                                payWithSadad={() =>
                                  this.payWithSadad(item, index)
                                }
                              /> */}
                            </tbody>
                          );
                        })}
                        {/* <div className="mb-2 text-right">
                          {paymentMethod === "Paypal" &&
                            this.state.payPalConfig &&
                            this.state.payPalConfig.env && (
                              <PaypalExpressBtn
                                env={this.state.payPalConfig.env}
                                client={this.state.payPalConfig.client}
                                currency={this.state.payPalConfig.currency}
                                total={this.state.payPalConfig.total}
                                onError={this.onErrorOfPaypalPayment}
                                onSuccess={this.onSuccessOfPaypalPayment}
                                onCancel={this.onCancelOfPaypalPayment}
                                shipping={1}
                                style={{
                                  color: "gold",
                                  shape: "rect",
                                  size: "large",
                                  label: "pay",
                                }}
                              />
                            )}
                          {paymentMethod === "GooglePay" && (
                            <GooglePayButton
                              environment="PRODUCTION" // TEST, PRODUCTION
                              buttonType="long"
                              style={{ marginTop: "10px" }}
                              paymentRequest={this.state.googlePayConfig}
                              onLoadPaymentData={this.makeGooglePayPayment}
                              onCancel={(cancel) => {
                                console.log("cancel");
                                console.log(cancel);
                                this.loadErrorMessageAnimation(
                                  "Payment failed"
                                );
                                this.setState(
                                  { paymentResponse: cancel },
                                  () => {
                                    this.updatePaymentStatusGooglePay(3);
                                    this.setState({
                                      isEditPaymentOptionModalVisible: false,
                                    });
                                  }
                                );
                              }}
                              onError={(error) => {
                                console.log("error");
                                console.log(error);
                                this.loadErrorMessageAnimation(
                                  "Payment failed"
                                );
                                this.setState(
                                  { paymentResponse: error },
                                  () => {
                                    this.updatePaymentStatusGooglePay(3);
                                    this.setState({
                                      isEditPaymentOptionModalVisible: false,
                                    });
                                  }
                                );
                              }}
                              onReadyToPayChange={(change) => {
                                console.log("change");
                                console.log(change);
                              }}
                              // onPaymentAuthorized={(auth) => {
                              //   console.log("auth");
                              //   console.log(auth);
                              // }}
                              // onPaymentDataChanged={(dataChange) => {
                              //   console.log("dataChange");
                              //   console.log(dataChange);
                              // }}
                            />
                          )}
                        </div> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
          </div>
          {this.state.paytmConfig &&
            this.state.paytmConfig.merchant &&
            this.state.paytmConfig.merchant.mid && (
              <div>
                <CheckoutProvider
                  config={this.state.paytmConfig}
                  checkoutJsInstance={this.state.checkoutJsInstance}
                  openInPopup={this.state.openInPopup}
                >
                  <Checkout />
                </CheckoutProvider>
              </div>
            )}
        </section>
        {orderList.length === 0 && <NoProductFoundOrderAnim />}

        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

// export default Orders;
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    isInquiryOrCart: isInquiryOrCart,
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
  }
)(withRouter(Orders));
