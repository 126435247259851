import React, { Component } from "react";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";

// Import custom components
import store, { loadUserDetailsFromLocalStorage } from "../../../store";
import TopBar from "../topbar/topbar";
import { changeCurrency, isInquiryOrCart } from "../../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getMasterDetailsIdUrl, getTenantInfoUrl } from "../../../services";
import { getCall } from "../../../api/post";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
} from "../../../utils/utils";
import NavBarNew from "../navbar/NavBarNew";

class MainHeader extends Component {
  state = {
    isDrawerVisible: false,
    userId: "",
    masterDetailsObj: {},
    isCartNotificationCounterVisibleInOpenStore: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }
  /*=====================
			   Pre loader
			   ==========================*/
  componentDidMount() {
    // console.log("MainHeader");

    // setTimeout(function () {
    // 	document.querySelector(".loader-wrapper").style = "display: none";
    // }, 2000);

    this.setState({ open: true });
    // getNotificationCounter().then((counter) => {
    // 	this.setState({ notificationCounter: counter });
    // });
    // const { pathname } = this.props.location;
    // if (pathname === "/login" || pathname === "/signup") {
    //   this.setState({ isCartNotificationCounterVisibleInOpenStore: false });
    // } else {
    //   this.setState({ isCartNotificationCounterVisibleInOpenStore: true });
    // }
    const userDetails = loadUserDetailsFromLocalStorage();
    this.setState({ userDetails });

    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initTenantInfo();
      this.props.isInquiryOrCart(masterDetailsObj);
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      // this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      const [tenantConfig] = tenantInfo || [];
      const { text } = tenantConfig || {};
      let config = JSON.parse(text);
      const { keepLogoOnRightSideOnDashboard } = config || {};
      this.setState({ keepLogoOnRightSideOnDashboard });
    }
  };

  componentWillMount() {
    // window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 100) {
      if (window.innerWidth < 767) {
        //  768
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  // openSearch() {
  //     document.getElementById("search-overlay").style.display = "block";
  // }

  // closeSearch() {
  //     document.getElementById("search-overlay").style.display = "none";
  // }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  renderSettingIcon = () => {
    const { onClickOnSettingsIcon } = this.props;
    return (
      <li className="onhover-div mobile-setting">
        <div
          onClick={() => {
            onClickOnSettingsIcon();
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/setting.png`}
            className="img-fluid"
            alt=""
          />
          <i className="fa fa-cog" />
        </div>
      </li>
    );
  };

  renderNotificationIcon = () => {
    const {
      notificationCounter,
      handleNotificicationDrawer,
      isInquiry,
    } = this.props;

    return (
      <li
        className={`onhover-div mobile-notification ${
          !isInquiry ? "" : "hide-settings-notification"
        }`}
        onClick={() => {
          handleNotificicationDrawer();
        }}
      >
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/bell.png`}
            className="img-fluid"
            alt=""
          />
          <i class="fa fa-bell" aria-hidden="true" />
        </div>
        <div className="cart-qty-cls">{notificationCounter}</div>
      </li>
    );
  };

  render() {
    const { handleNotificicationDrawer } = this.props;
    const { userDetails, keepLogoOnRightSideOnDashboard } = this.state;
    return (
      <div>
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}
          <TopBar handleNotificicationDrawer={handleNotificicationDrawer} />

          <div
            className={`main-menu login-mobile  ${
              keepLogoOnRightSideOnDashboard ? "header-right" : "header-left"
            }`}
          >
            {/* <div className="brand-logo">
                    <LogoImage />
                  </div> */}

            <div className="menu-right">
              {/*Top Navigation Bar Component*/}
              {isOpenStoreIdExist() && <NavBarNew />}
              {!isOpenStoreIdExist() && userDetails && (
                //  <NavBar />
                <NavBarNew />
              )}
            </div>
            {/* <div className="menu-left pull-right">
                    {userId && (
											<div className="navbar">
												<a href="javascript:void(0)" onClick={this.openNav}>
													<div className="bar-style">
														{" "}
														<i
															className="fa fa-bars sidebar-bar"
															aria-hidden="true"
														/>
													</div>
												</a>
											</div>
										)}
                  </div> */}
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Search a Product"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationCounter: state.cartCounter.notificationCounter,
  isInquiry: state.settings.isInquiry,
});
export default connect(mapStateToProps, {
  changeCurrency,
  isInquiryOrCart: isInquiryOrCart,
})(withRouter(MainHeader));
