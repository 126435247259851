import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { getCall } from "../../../api/post";
import { connect } from "react-redux";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
  getRequestproductImageUrl,
} from "../../../services";
import { isInquiryOrCart } from "../../../actions";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  loadUserDetailsFromLocalStorage,
  getOpenStoreIdApp,
} from "../../../store";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import Slider from "react-slick";
import Moment from "react-moment";
import Lightbox from "react-image-lightbox";

const imageContainer = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
  border: "1px solid #D3D3D3",
  cursor: "pointer",
};

class RequestProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      nav1: null,
      nav2: null,
      stock: getValueFromLanguageObject("str_instock")
        ? getValueFromLanguageObject("str_instock")
        : "InStock",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      image: "",
      category: {},
      pincode: "",
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
      isFullScreenImage: false,
      fullScreenImagePath: "",
      currentSliderIndex: 0,
    };
  }

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initSingleCategry();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    // if (this.state.quantity > this.props.product.minimumorderquantity) {
    //   this.setState({
    //     stock: getValueFromLanguageObject("str_instock")
    //       ? getValueFromLanguageObject("str_instock")
    //       : "InStock",
    //   });
    //   this.setState({ quantity: this.state.quantity - 1 });
    // }
  };

  plusQty = () => {
    // if (this.props.product.availablequantity > this.state.quantity) {
    //   this.setState({ quantity: this.state.quantity + 1 });
    // } else {
    //   this.setState({
    //     stock: getValueFromLanguageObject("str_out_of_stock")
    //       ? getValueFromLanguageObject("str_out_of_stock")
    //       : "Out of Stock!",
    //   });
    // }
  };

  changeQty = (e) => {
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  initSingleCategry = async () => {
    // if (this.props.product.category.parentId !== null) {
    //   const singleCategoryUrl =
    //     "categories/" + this.props.product.category.parentId;
    //   const singleCategory = await getCall(singleCategoryUrl, []);
    //   if (singleCategory.error) {
    //     clearLocalStorageReload({ response: singleCategory });
    //     this.loadErrorMessageAnimation(singleCategory.error.message);
    //     if (singleCategory.error.statusCode === 401) {
    //       removeUserDetailAndOtherDetails();
    //       navigateTo(this.props, `/login`);
    //       return;
    //     }
    //   } else {
    //     this.setState({ category: singleCategory });
    //   }
    // }
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    } else {
      userDetails = userDetails === undefined ? {} : userDetails;
    }
    const { id } = userDetails;
    const openStoreId = getOpenStoreIdApp();
    let userId = openStoreId ? openStoreId : id;
    const counterUrl = "commoncounters?filter[where][userId]=" + userId;
    const commonCounter = await getCall(counterUrl, []);
    if (commonCounter.error) {
      clearLocalStorageReload({ response: commonCounter });
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  pincodeHandleSubmit = (event) => {
    this.setState({ pincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  renderRequestProductId = (product) => {
    const { orderno } = product || {};
    const strRequestID = getValueFromLanguageObject("str_request_id")
      ? getValueFromLanguageObject("str_request_id")
      : "Request ID ";
    if (orderno) {
      return `${strRequestID} : ${orderno}`;
    } else {
      return "";
    }
  };

  renderRequestProductDescription = (product) => {
    const { description } = product || {};
    const strDescription = getValueFromLanguageObject("str_description")
      ? getValueFromLanguageObject("str_description")
      : "Description";
    if (description) {
      return `${strDescription} : ${description}`;
    } else {
      return "";
    }
  };

  renderRequestProductCategory = (product) => {
    const { category } = product || {};
    const { name } = category || {};
    const strCategory = getValueFromLanguageObject("str_category")
      ? getValueFromLanguageObject("str_category")
      : "Category";
    if (name) {
      return `${strCategory} : ${name}`;
    } else {
      return "";
    }
  };

  render() {
    const {
      product,
      symbol,
      onClickOfDeleteIcon,
      isInquiry,
      index,
    } = this.props;
    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      fade: true,
      afterChange: (current) => this.setState({ currentSliderIndex: current }),
    };
    const {
      open,
      category,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      isFullScreenImage,
      fullScreenImagePath,
      currentSliderIndex,
    } = this.state;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }
    return (
      <>
        <div className="product-box">
          <div className="img-wrapper mb-2">
            <div className="front">
              <div style={{ height: "250px" }}>
                <img
                  style={imageContainer}
                  src={
                    product.orderproductmedia.length > 0
                      ? getRequestproductImageUrl(
                          product.orderproductmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage()
                  }
                  // className="img-fluid"
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getPlaceHolderImage();
                  }}
                />
              </div>
            </div>
            <div className="cart-info cart-wrap text-center">
              <a
                href
                data-toggle="modal"
                data-target="#quick-view"
                title={
                  getValueFromLanguageObject("str_quick_view")
                    ? getValueFromLanguageObject("str_quick_view")
                    : "Quick View"
                }
                onClick={this.onOpenModal}
              >
                <i className="fa fa-eye" aria-hidden="true" />
              </a>
              <button
                style={{ cursor: "pointer" }}
                title={
                  getValueFromLanguageObject("str_view_image")
                    ? getValueFromLanguageObject("str_view_image")
                    : "View Image"
                }
                onClick={() => {
                  const path =
                    product &&
                    product.orderproductmedia &&
                    product.orderproductmedia.length &&
                    product.orderproductmedia.length > 0
                      ? getRequestproductImageUrl(
                          product.orderproductmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage();
                  console.log(path);
                  this.setState({
                    isFullScreenImage: true,
                    fullScreenImagePath: path,
                  });
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = getPlaceHolderImage();
                  const path =
                    product &&
                    product.orderproductmedia &&
                    product.orderproductmedia.length &&
                    product.orderproductmedia.length > 0
                      ? getRequestproductImageUrl(
                          product.orderproductmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage();
                  this.setState({
                    isFullScreenImage: true,
                    fullScreenImagePath: path,
                  });
                }}
              >
                <i className="fa fa-search" aria-hidden="true" />
              </button>
              <button
                title={
                  getValueFromLanguageObject("str_delete_product")
                    ? getValueFromLanguageObject("str_delete_product")
                    : "Delete Product"
                }
                onClick={() => {
                  onClickOfDeleteIcon(product, index);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="product-detail">
            <div>
              <h6 className="mb-2" style={{ lineHeight: "26px" }}>
                <EllipsisText
                  text={this.renderRequestProductId(product)}
                  length={110}
                />
              </h6>

              <h6>
                {getValueFromLanguageObject("str_date")
                  ? getValueFromLanguageObject("str_date")
                  : "Date"}
                :
                <Moment format="DD-MMM-YYYY">
                  {product && product.created ? product.created : "-"}
                </Moment>
              </h6>
              <h6
                style={{
                  color: getColorCodeBasedOnKey("text_product_name_products"),
                }}
              >
                <EllipsisText
                  text={this.renderRequestProductCategory(product)}
                  length={35}
                />
              </h6>
              <h6>
                <EllipsisText
                  text={this.renderRequestProductDescription(product)}
                  length={110}
                />
              </h6>

              <h6>
                {/* <EllipsisText
                  text={
                    <Moment format="DD-MMM-YYYY">
                      {product &&
                        product.created &&
                        "DESCRIPTION : " + product.created}
                    </Moment>
                  }
                  length={110}
                /> */}
              </h6>
            </div>
            <Modal
              open={open}
              onClose={this.onCloseModal}
              center
              styles={{ closeButton: { background: "#cfd4da" } }}
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content quick-view-modal">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6  col-xs-12">
                        <div
                          className="quick-view-img"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <div className="quick-image-box request-product-view-modal">
                            {/* <img
                              src={
                                product.orderproductmedia.length > 0
                                  ? getRequestproductImageUrl(
                                      product.orderproductmedia[0].productname,
                                      masterDetailsObj
                                    )
                                  : getPlaceHolderImage()
                              }
                              alt=""
                              className="img-fluid"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getPlaceHolderImage();
                              }}
                            /> */}

                            <Slider
                              {...products}
                              asNavFor={this.state.nav2}
                              ref={(slider) => (this.slider1 = slider)}
                              className="product-slick request-product-view"
                            >
                              {product.orderproductmedia &&
                              product.orderproductmedia.length > 0 ? (
                                product.orderproductmedia.map((vari, index) => (
                                  <div
                                    // key={index}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      onClick={() => {
                                        const path =
                                          product &&
                                          product.orderproductmedia &&
                                          product.orderproductmedia.length &&
                                          product.orderproductmedia.length > 0
                                            ? getRequestproductImageUrl(
                                                product.orderproductmedia[
                                                  currentSliderIndex
                                                ].productname,
                                                masterDetailsObj
                                              )
                                            : getPlaceHolderImage();
                                        this.setState({
                                          isFullScreenImage: true,
                                          fullScreenImagePath: path,
                                        });
                                      }}
                                      alt=""
                                      style={
                                        product.availablequantity === 0
                                          ? { opacity: "0.7" }
                                          : {}
                                      }
                                      src={getRequestproductImageUrl(
                                        vari.productname,
                                        masterDetailsObj
                                      )}
                                      // className="img-product-slider"
                                      // onError={(e) => {
                                      //   e.target.onerror = null;
                                      //   e.target.src = getPlaceHolderImage();
                                      // }}
                                    />
                                  </div>
                                ))
                              ) : (
                                <img
                                  src={getPlaceHolderImage()}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const path =
                                      product &&
                                      product.orderproductmedia &&
                                      product.orderproductmedia.length &&
                                      product.orderproductmedia.length > 0
                                        ? getRequestproductImageUrl(
                                            product.orderproductmedia[0]
                                              .productname,
                                            masterDetailsObj
                                          )
                                        : getPlaceHolderImage();
                                    this.setState({
                                      isFullScreenImage: true,
                                      fullScreenImagePath: path,
                                    });
                                  }}
                                  // src=""
                                  className="img-fluid"
                                  alt=""
                                />
                              )}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 rtl-text">
                        <div className="product-right">
                          <div className="product-description">
                            <div className="border-product border-0">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_request_id")
                                  ? getValueFromLanguageObject("str_request_id")
                                  : "Request ID"}
                              </h6>
                              {product && product.orderno
                                ? product.orderno
                                : "-"}
                            </div>
                            <div className="border-product">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_date")
                                  ? getValueFromLanguageObject("str_date")
                                  : "Date"}
                              </h6>
                              <Moment format="DD-MMM-YYYY">
                                {product && product.created
                                  ? product.created
                                  : "-"}
                              </Moment>
                            </div>
                            <div className="border-product">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_category")
                                  ? getValueFromLanguageObject("str_category")
                                  : "category"}
                              </h6>
                              <p>
                                {product &&
                                product.category &&
                                product.category.name
                                  ? product.category.name
                                  : "-"}
                              </p>
                            </div>
                            <div className="border-product">
                              <h6 className="product-title">
                                {getValueFromLanguageObject("str_description")
                                  ? getValueFromLanguageObject(
                                      "str_description"
                                    )
                                  : "Description"}
                              </h6>
                              <p>
                                {product && product.description
                                  ? product.description
                                  : "-"}
                              </p>
                            </div>

                            {/* <h6 className="product-title">
                              {getValueFromLanguageObject("str_quantity")
                                ? getValueFromLanguageObject("str_quantity")
                                : "quantity"}
                            </h6>
                            <div className="qty-box">
                              <div className="input-group">
                                <span className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn quantity-left-minus"
                                    onClick={this.minusQty}
                                    data-type="minus"
                                    data-field=""
                                  >
                                    <i className="fa fa-angle-left" />
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  name="quantity"
                                  value={this.state.quantity}
                                  onChange={this.changeQty}
                                  className="form-control input-number"
                                />
                                <span className="input-group-prepend">
                                  <button
                                    type="button"
                                    className="btn quantity-right-plus"
                                    onClick={this.plusQty}
                                    data-type="plus"
                                    data-field=""
                                  >
                                    <i className="fa fa-angle-right" />
                                  </button>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          {isFullScreenImage && (
            <Lightbox
              mainSrc={fullScreenImagePath}
              onCloseRequest={() =>
                this.setState({
                  isFullScreenImage: false,
                  fullScreenImagePath: "",
                })
              }
            />
          )}
          {showErrorMessageModal && (
            <ErrorMessageAnimationModal
              show={showErrorMessageModal}
              message={errorMessage}
              onClose={() => {
                this.setState({ showErrorMessageModal: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(mapStateToProps, { isInquiryOrCart: isInquiryOrCart })(
  withRouter(RequestProductListItem)
);
