import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "./index.scss";
import Dashboard from "./components/layouts/dashboard/main";
import Products from "./components/layouts/products/products";
import SingleProduct from "./components/layouts/singleproduct/singleproduct";
import Orders from "./components/layouts/order/order";
import Layout from "./components/app";
import Cart from "./components/cart";
import checkOut from "./components/checkout";
import OrderSummary from "./components/layouts/ordersummary/order-summary";
import ContactUs from "./components/layouts/contactus/contact";
import EditOrder from "./components/layouts/editorder/edit-order";
import MyProfile from "./components/layouts/myprofile/myprofile";
import OrderDetails from "./components/layouts/orderdetails/order-details";
import PrivacyPolicy from "./components/layouts/privacypolicy/privacypolicy";
import Settings from "./components/layouts/settings/settings";
import AboutUs from "./components/layouts/aboutus/aboutus";
import PageNotFound from "./components/pages/404";
import Register from "./components/layouts/singup/register";
import Login from "./components/layouts/login/login";
import { connect } from "react-redux";
import { getMasterDetailsId } from "./actions";
import { getMasterDetailsIdUrl, getTenantInfoUrl } from "./services";
// import { getMasterDetailsId } from "./actions";
import MainLoader from "./animation/MainLoader";
import { debuglogger } from "./utils/logger";
import SadadPaymentSample from "./components/layouts/sadadpaymentsample/SadadPaymentSample";
import SadadPaymentResponse from "./components/layouts/sadadpaymentsample/SadadPaymentResponse";
import OrderSadadPaymentResponse from "./components/layouts/sadadpaymentsample/OrderSadadPaymentResponse";
import RequestProduct from "./components/layouts/requestproduct/RequestProduct";
import DashboardWrapper from "./app/dashboard/DashboardWrapper";
import { clearLocalStorageReload } from "./utils/utils";
import { getCall } from "./api/post";
import { setLanguageObjectToLocalStorage, setCurrencySymbol } from "./store";
import CollectionProducts from "./components/layouts/collection/CollectionProducts";

// below array is used to avoid same routes twice in url like /login/login
const routerArray = [
  "login",
  "signup",
  "products",
  "c",
  "s",
  "orders",
  "singleproduct",
  "cart",
  "checkout",
  "orderdetails",
  "ordersummary",
  "contactus",
  "editorder",
  "myprofile",
  "aboutus",
  "privacypolicy",
  "settings",
  "p",
  "o",
  "collection",
];

class Routes extends Component {
  state = {
    colorCode: false,
    isLoading: true,
    masterDetailsObj: {},
    isShowXEModule: false,
  };

  componentDidMount() {
    document.querySelector(".loader-wrapper-splash").style = "display: none";
    this.initMasterDetailsId().then(() => {
      this.initLanguage();
      // this.initColorCode();
      this.initCurrency();
      this.initTenantInfo();
      this.initThemeCode().then(() => {
        this.setState({ isLoading: false });
      });
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initLanguage = async () => {
    const { masterDetailsObj } = this.state;
    if (!masterDetailsObj.id) {
      return;
    }
    const languageUrl = `languages/getLanguage?filter[key]=Android_Language&filter[where][masterdetailId]=${masterDetailsObj.id}`;
    const getLanguage = await getCall(languageUrl, []);
    if (getLanguage.error) {
      clearLocalStorageReload({ response: getLanguage });
    }
    setLanguageObjectToLocalStorage(getLanguage);
  };

  // initColorCode = async () => {
  //   const { masterDetailsObj } = this.state;
  //   if (!masterDetailsObj.id) {
  //     return;
  //   }
  //   const colorCodeUrl = `settings/getColorCode?filter[where][masterdetailId]=${masterDetailsObj.id}`;
  //   const colorCode = await getCall(colorCodeUrl, []);
  //   if (colorCode.error) {
  //     clearLocalStorageReload({ response: colorCode });
  //   } else {
  //     if (colorCode.length > 0) {
  //       setColorCodeToLocalStorage(colorCode[0].color_code);
  //     }
  //   }
  // };

  initThemeCode = async () => {
    const { masterDetailsObj } = this.state;
    if (!masterDetailsObj.id) {
      return;
    }
    const themeCodeUrl = `settings/getThemeColor?filter[where][masterdetailId]=${masterDetailsObj.id}`;
    const themeCode = await getCall(themeCodeUrl, []);
    if (themeCode.error) {
      clearLocalStorageReload({ response: themeCode });
    } else {
      if (themeCode && themeCode.selected) {
        document
          .getElementById("color")
          .setAttribute(
            "href",
            `${process.env.PUBLIC_URL}/assets/css/color${themeCode.id}.css`
          );
      }
    }
  };

  initCurrency = async () => {
    const { masterDetailsObj } = this.state;
    if (!masterDetailsObj.id) {
      return;
    }
    const { id } = masterDetailsObj;
    const currencyUrl = `settings?filter[where][masterdetailId]=${id}&filter[where][registerallow]=CURRENCY`;
    const [currencyResponse] = await getCall(currencyUrl, []);
    debuglogger("currencyResponse", currencyResponse);
    if (currencyResponse.error) {
      clearLocalStorageReload({ response: currencyResponse });
    }
    if (currencyResponse) {
      const { text } = currencyResponse;
      if (text) {
        setCurrencySymbol(text);
      }
    }
  };

  renderBasename = () => {
    const { masterDetailsObj } = this.props;
    const publicUrl =
      masterDetailsObj && masterDetailsObj.isFromDomain
        ? ""
        : window.location.pathname.split("/")[1];
    const found = routerArray.find((element) => element === publicUrl);
    return found ? "" : publicUrl;
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (tenantInfo && tenantInfo.length > 0) {
        const tenantItem = tenantInfo[0];
        const { text } = tenantItem || {};
        const tenantSettings = JSON.parse(text);
        if ("isShowXEModule" in tenantSettings) {
          this.setState({
            isShowXEModule: tenantSettings.isShowXEModule,
          });
        } else {
          this.setState({
            isShowXEModule: false,
          });
        }
      }
    }
  };

  render() {
    const { isShowXEModule } = this.state;
    const { masterDetailsObj, isLoading } = this.props;
    const { id } = masterDetailsObj || {};
    return (
      <>
        {id && (
          <BrowserRouter basename={this.renderBasename()}>
            <ScrollContext>
              <Switch>
                {isShowXEModule ? (
                  <Route exact path={`/`} component={DashboardWrapper} />
                ) : (
                  <Route
                    exact
                    path={`/`}
                    render={(routeProps) => (
                      <Dashboard
                        masterDetailsObj={masterDetailsObj}
                        {...routeProps}
                      />
                    )}
                  />
                )}

                <Layout>
                  <Switch>
                    {isShowXEModule ? (
                      <>
                        <Route exact path={`/login`} component={Login} />

                        <Route path="/dashboard" component={DashboardWrapper} />
                        {/* <Route exact path="*" component={PageNotFound} /> */}
                      </>
                    ) : (
                      <>
                        <Route exact path={`/login`} component={Login} />
                        <Route
                          path={`/login/isFromCheckout=:isFromCheckout`}
                          component={Login}
                        />
                        <Route
                          path={`/login/isFromCollection=:isFromCollection/:id`}
                          component={Login}
                        />
                        <Route exact path={`/signup`} component={Register} />
                        <Route
                          path={`/signup/isFromCheckout=:isFromCheckout`}
                          component={Register}
                        />
                        <Route path={`/products`} component={Products} />
                        <Route path={`/c/:id`} component={Products} />
                        <Route path={`/s/:id`} component={Products} />
                        {/* <Route path={`/products/:id`} component={Products} /> */}
                        <Route path={`/orders`} component={Orders} />
                        <Route
                          path={`/singleproduct/:id`}
                          component={SingleProduct}
                        />
                        <Route path={`/cart`} component={Cart} />
                        <Route path={`/checkout`} component={checkOut} />
                        <Route
                          path={`/orderdetails/:id`}
                          component={OrderDetails}
                        />
                        <Route
                          path={`/ordersummary/:id`}
                          component={OrderSummary}
                        />
                        <Route path={`/contactus`} component={ContactUs} />
                        <Route path={`/editorder/:id`} component={EditOrder} />
                        <Route path={`/myprofile`} component={MyProfile} />
                        <Route path={`/aboutus`} component={AboutUs} />
                        <Route
                          path={`/privacypolicy`}
                          component={PrivacyPolicy}
                        />
                        <Route path={`/settings`} component={Settings} />
                        <Route path={`/p/:id`} component={SingleProduct} />
                        <Route path={`/o/:id`} component={OrderSummary} />
                        <Route
                          path={`/sadad-sample`}
                          component={SadadPaymentSample}
                        />
                        <Route
                          path={`/sadad-response`}
                          component={SadadPaymentResponse}
                        />

                        <Route
                          path={`/order-sadad-response`}
                          component={OrderSadadPaymentResponse}
                        />

                        <Route
                          path={`/requestproduct`}
                          component={RequestProduct}
                        />
                        <Route
                          exact
                          path={`/collection`}
                          component={CollectionProducts}
                        />

                        <Route
                          path={`/collection/:id`}
                          component={CollectionProducts}
                        />
                        {/* <Route path={`/cc/:id`} component={CollectionProducts} /> */}
                        <Route path="/dashboard" component={PageNotFound} />
                      </>
                    )}
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        )}
        {isLoading && <MainLoader />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // masterDetailsObj: state.masterDetailsObj,
});

export default connect(mapStateToProps, {
  // getMasterDetailsId: getMasterDetailsId,
})(Routes);
