import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
import { getImageUrl, getPlaceHolderImage } from "../../../services";
import { getValueFromLanguageObject } from "../../../store";
import Lightbox from "react-image-lightbox";

class QuickViewProductDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stock:
        props.product.availablequantity > 0
          ? getValueFromLanguageObject("str_instock")
            ? getValueFromLanguageObject("str_instock")
            : "InStock"
          : getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      isFullScreenImage: false,
      fullScreenImagePath: "",
    };
  }

  minusQty = () => {
    if (this.state.quantity > this.props.product.minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      });
    }
  };

  changeQty = (e) => {
    const { isInquiry, product } = this.props;
    if (!isInquiry && product.availablequantity === 0) {
      return;
    }

    if (e.target.value === "" || e.target.value === "-") {
      this.setState({ quantity: "" });
      return;
    }
    if (e.target.value < 1) {
      return;
    }
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  handleOnClickOfProductImage = () => {
    const { product, masterDetailsObj } = this.props || {};
    const { productmedia } = product || [];
    const [productmediaFirst] = productmedia || [];
    let path = "";
    if (productmediaFirst) {
      const { productname } = productmediaFirst || {};
      path = getImageUrl(productname, masterDetailsObj);
    } else {
      path = getPlaceHolderImage();
    }

    this.setState({
      isFullScreenImage: true,
      fullScreenImagePath: path,
    });
  };

  render() {
    const {
      product,
      masterDetailsObj,
      symbol,
      isInquiry,
      onAddToCartClicked,
      isQuickViewProductDetailsModalShow,
      handleCloseQuickViewProductDetailsModal,
      category,
    } = this.props;

    const {
      name,
      price,
      productmedia,
      totalCartCounter,
      id,
      category: productCategory,
    } = product || {};
    const { name: subCategoryName } = category || {};
    const { name: categoryName } = productCategory || {};

    const { isFullScreenImage, fullScreenImagePath } = this.state || {};

    const strThereAre = getValueFromLanguageObject("str_there_are")
      ? getValueFromLanguageObject("str_there_are")
      : "There are";

    const strUnitsOfItemAlreadyInTheCart = getValueFromLanguageObject(
      "str_units_of_item_already_in_the_cart"
    )
      ? getValueFromLanguageObject("str_units_of_item_already_in_the_cart")
      : "units of item already in the cart";

    const strMinimumOrderQuantity = getValueFromLanguageObject(
      "str_minimum_order_quantity"
    )
      ? getValueFromLanguageObject("str_minimum_order_quantity")
      : "Minimum Order Quantity";

    return (
      <>
        <Modal
          open={isQuickViewProductDetailsModalShow}
          center
          onClose={handleCloseQuickViewProductDetailsModal}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6  col-xs-12">
                    <div
                      className="quick-view-img"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        className="quick-image-box"
                        onClick={() => this.handleOnClickOfProductImage()}
                      >
                        <img
                          src={
                            productmedia.length > 0
                              ? getImageUrl(
                                  productmedia[0].productname,
                                  masterDetailsObj
                                )
                              : getPlaceHolderImage()
                          }
                          alt=""
                          className="img-fluid"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = getPlaceHolderImage();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <div className="border-product">
                        <h2 className="product-title">
                          <EllipsisText text={name} length={35} />
                        </h2>
                        {isInquiry ? (
                          <div />
                        ) : (
                          <h3>
                            {symbol}
                            {parseFloat(price).toFixed(2)}
                          </h3>
                        )}
                        {categoryName && (
                          <div className="border-product">
                            <h6 className="product-title">
                              {getValueFromLanguageObject("str_category")
                                ? getValueFromLanguageObject("str_category")
                                : "category"}
                            </h6>
                            <p>{categoryName}</p>
                          </div>
                        )}
                        <div className="border-product">
                          <h6 className="product-title">
                            {getValueFromLanguageObject("str_sub_category")
                              ? getValueFromLanguageObject("str_sub_category")
                              : "sub-category"}
                          </h6>
                          <p>{subCategoryName}</p>
                        </div>

                        <div className="product-description border-product">
                          {!isInquiry && (
                            <div className="instock-cls">
                              {this.state.stock}
                            </div>
                          )}
                          <h6 className="product-title">
                            {getValueFromLanguageObject("str_quantity")
                              ? getValueFromLanguageObject("str_quantity")
                              : "quantity"}
                          </h6>

                          <div className="qty-box">
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn quantity-left-minus"
                                  onClick={this.minusQty}
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-angle-left" />
                                </button>
                              </span>
                              <input
                                type="number"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.changeQty}
                                className="form-control input-number"
                                min="1"
                                max="100000"
                                minLength="1"
                                maxLength="100000"
                              />
                              <span className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn quantity-right-plus"
                                  onClick={this.plusQty}
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-angle-right" />
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="product-description mb-3 text-danger">
                          {this.props.product &&
                            this.props.product.minimumorderquantity &&
                            this.props.product.minimumorderquantity > 1 && (
                              <>
                                {`${strMinimumOrderQuantity}: ${
                                  this.props.product.minimumorderquantity
                                }`}
                              </>
                            )}
                        </div>
                        <div className="product-description mb-3">
                          {totalCartCounter > 0 && (
                            <>
                              {`${strThereAre} ${totalCartCounter} ${strUnitsOfItemAlreadyInTheCart}`}
                            </>
                          )}
                        </div>
                        <div className="product-buttons">
                          <button
                            className="btn btn-solid"
                            onClick={async () => {
                              onAddToCartClicked(
                                product,
                                this.state.quantity,
                                isInquiry
                              );
                            }}
                            disabled={
                              !isInquiry && product.availablequantity === 0
                                ? true
                                : false
                            }
                          >
                            {isInquiry
                              ? getValueFromLanguageObject("str_add_to_inquiry")
                                ? getValueFromLanguageObject(
                                    "str_add_to_inquiry"
                                  )
                                : "Add To Inquiry"
                              : getValueFromLanguageObject("str_add_to_cart")
                              ? getValueFromLanguageObject("str_add_to_cart")
                              : "Add to cart"}
                          </button>
                          <Link
                            to={`/singleproduct/${id}`}
                            className="btn btn-solid"
                          >
                            {getValueFromLanguageObject("str_view_detail")
                              ? getValueFromLanguageObject("str_view_detail")
                              : "view detail"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </>
    );
  }
}

export default connect()(withRouter(QuickViewProductDetailsModal));
