import React, { Component } from "react";
import Slider from "react-slick";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
} from "../../../../services";
import { getCall } from "../../../../api/post";
import {
  clearLocalStorageReload,
  getYouTubeVideoId,
} from "../../../../utils/utils";
import YoutubeEmbed from "../../../layouts/singleproduct/YoutubeEmbed";

class SmallImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav2: null,
      masterDetailsObj: {},
    };
  }
  componentDidMount() {
    this.setState({
      nav2: this.slider2,
    });
    this.initMasterDetailsId().then(() => {});
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  rendervideourllink = (videourllink) => {
    // this.getId(videourllink);
    return (
      <img
        src={`http://img.youtube.com/vi/${this.getId(videourllink)}/0.jpg`}
        alt=""
        className="img-avtar"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = getPlaceHolderImage();
        }}
      />
    );
  };

  render() {
    const { item, settings } = this.props;
    const { masterDetailsObj } = this.state;
    var productsnav = settings;

    return (
      <div className="row">
        <div className="col-12 p-0">
          <Slider
            {...productsnav}
            asNavFor={this.props.navOne}
            ref={(slider) => (this.slider2 = slider)}
            className="slider-nav cursor-pointer"
          >
            {item.productmedia &&
              item.productmedia.length > 1 &&
              item.productmedia.map((vari, index) => (
                <div key={index}>
                  <img
                    src={getImageUrl(vari.productname, masterDetailsObj)}
                    key={index}
                    alt=""
                    className="img-avtar"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = getPlaceHolderImage();
                    }}
                  />
                </div>
              ))}
            {item.videourllink && this.rendervideourllink(item.videourllink)}
          </Slider>
        </div>
      </div>
    );
  }
}

export default SmallImages;
