import React, { Component } from "react";
import Lottie from "react-lottie";
import Modal from "react-responsive-modal";
import errorAnimJSON from "./errorAnimJSON.json";
class ErrorMessageAnimationModal extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: errorAnimJSON,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    setTimeout(() => {
      props.onClose();
    }, 3500);
  }

  render() {
    const { show, message } = this.props;
    return (
      <>
        <Modal
          open={show}
          showCloseIcon={false}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          center={false}
        >
          <div className="modal-dialog modal-lg">
            <div className="customLottieAnim">
              <Lottie options={this.defaultOptions} />
              <p className="mt-1"> {message}</p>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ErrorMessageAnimationModal;
