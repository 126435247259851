import { connect } from "react-redux";
import React, { Component } from "react";
import { getCall, patchCall } from "../../../api/post";
import {
  removeCurrentLanguage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  saveUserDetailsToLocalStorage,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import Breadcrumb from "../../common/breadcrumb";
import { withRouter } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import {
  getSettingUrl,
  getGroupWiseGSTConfigurationUrl,
  getMasterDetailsIdUrl,
  getTenantInfoUrl,
  getAddressDetailUrl,
} from "../../../services";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";

class Settings extends Component {
  state = {
    text: "",
    countryList: [],
    stateList: [],
    cityList: [],
    shippingCountryList: [],
    shippingStateList: [],
    shippingCityList: [],
    groupWiseGSTINConfig: [],
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    maxCellnumberDigitLimit: "",
  };
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      this.initCountryList();
      this.initShippingCountryList();
      this.initCatelogueSettings();
      this.initGroupWiseGSTINConfig();
      this.initUserDetails();
      this.initTenantInfo();
      this.initAddressDetails();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  removeUserDetailAndRedirectToLogin = () => {
    removeUserDetailAndOtherDetails();
    removeCurrentLanguage();
    navigateTo(this.props, `/login`);
  };

  initCountryList = async () => {
    const countryUrl = `states?getCountries=getCountries`;
    const countryList = await getCall(countryUrl, []);
    if (countryList.error) {
      clearLocalStorageReload({ response: countryList });
      this.loadErrorMessageAnimation(countryList.error.message);
      if (countryList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ countryList: countryList.data });
    }
  };

  initShippingCountryList = async () => {
    const countryUrl = `states?getCountries=getCountries`;
    const countryList = await getCall(countryUrl, []);
    if (countryList.error) {
      clearLocalStorageReload({ response: countryList });
      this.loadErrorMessageAnimation(countryList.error.message);
      if (countryList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingCountryList: countryList.data });
    }
  };

  initStateList = async () => {
    const { addressDetails } = this.state;
    const stateUrl =
      `states?filter[where][parentId]=` + this.state.billingCountry;
    const stateList = await getCall(stateUrl, []);
    if (stateList.error) {
      clearLocalStorageReload({ response: stateList });
      this.loadErrorMessageAnimation(stateList.error.message);
      if (stateList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ stateList: stateList.data });
      if (
        stateList &&
        stateList.data &&
        addressDetails &&
        addressDetails.length > 0 &&
        stateList.data[0] &&
        stateList.data[0].id &&
        addressDetails[10].visible === 0
      ) {
        this.initCityListing(stateList.data[0].id);
      }
    }
  };

  initShippingStateList = async () => {
    const { addressDetails } = this.state;
    const stateUrl =
      `states?filter[where][parentId]=` + this.state.shippingCountry;
    const stateList = await getCall(stateUrl, []);
    if (stateList.error) {
      clearLocalStorageReload({ response: stateList });
      this.loadErrorMessageAnimation(stateList.error.message);
      if (stateList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingStateList: stateList.data });
      if (
        stateList &&
        stateList.data &&
        addressDetails &&
        addressDetails.length > 0 &&
        addressDetails[10].visible === 0
      ) {
        this.initShippingCityListing(stateList.data[0].id);
      }
    }
  };

  initCityList = async () => {
    const cityUrl = `cities?filter[where][stateId]=` + this.state.billingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ cityList: cityList.data });
    }
  };

  initCityListing = async (billingState) => {
    const cityUrl = `cities?filter[where][stateId]=` + billingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ cityList: cityList.data });
    }
  };

  initShippingCityList = async () => {
    const cityUrl = `cities?filter[where][stateId]=` + this.state.shippingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingCityList: cityList.data });
    }
  };

  initShippingCityListing = async (shippingState) => {
    const cityUrl = `cities?filter[where][stateId]=` + shippingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingCityList: cityList.data });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initGroupWiseGSTINConfig = async () => {
    const { masterDetailsObj } = this.state;
    const groupWiseGSTINConfigUrl = getGroupWiseGSTConfigurationUrl(
      masterDetailsObj
    );
    const groupWiseGSTINConfig = await getCall(groupWiseGSTINConfigUrl, []);
    if (groupWiseGSTINConfig.error) {
      clearLocalStorageReload({ response: groupWiseGSTINConfig });
      this.loadErrorMessageAnimation(groupWiseGSTINConfig.error.message);
    } else {
      if (
        groupWiseGSTINConfig &&
        groupWiseGSTINConfig.length > 0 &&
        groupWiseGSTINConfig[0].status === 1
      ) {
        this.setState({
          groupWiseGSTINConfig: JSON.parse(groupWiseGSTINConfig[0].text),
        });
      }
    }
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);

    if (userDetailsResponse.error) {
      clearLocalStorageReload({ response: userDetailsResponse });
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.preFillUpBillingDetailForm(userDetailsResponse);
    }
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (
        tenantInfo &&
        tenantInfo.length > 0 &&
        tenantInfo[0].text &&
        tenantInfo[0].text.length > 0
      ) {
        const tenantSettings = JSON.parse(tenantInfo[0].text);
        if ("maxCellnumberDigitLimit" in tenantSettings) {
          this.setState({
            maxCellnumberDigitLimit: tenantSettings.maxCellnumberDigitLimit,
          });
        } else {
          this.setState({
            maxCellnumberDigitLimit: "10",
          });
        }
      }
    }
  };

  initAddressDetails = async () => {
    const { masterDetailsObj } = this.state;
    const addressDetails = await getCall(
      getAddressDetailUrl(masterDetailsObj),
      []
    );

    if (addressDetails.error) {
      clearLocalStorageReload({ response: addressDetails });
    } else {
      if (addressDetails.length > 0) {
        this.setState({ addressDetails: JSON.parse(addressDetails[0].text) });
      }
    }
  };

  preFillUpBillingDetailForm = (userDetailsResponse) => {
    const { billingaddress, shippingaddress } = userDetailsResponse;
    const billingAddress = JSON.parse(billingaddress);
    const shippingAddress = JSON.parse(shippingaddress);
    if (billingAddress) {
      const {
        name,
        mobile,
        email,
        companyname,
        gstin,
        address1,
        address2,
        landmark,
        cityId,
        stateId,
        countryId,
        zipcode,
        zonenumber,
        streetnumber,
        buildingnumber,
        unitnumber,
      } = billingAddress;
      this.setState({
        billingName: name,
        billingMobileNumber: mobile,
        billingEmailAddress: email,
        billingCompanyName: companyname,
        billingGSTIN: gstin,
        billingAddressLine1: address1,
        billingAddressLine2: address2,
        billingLandmark: landmark,
        billingPincode: zipcode,
        billingZonenumber: zonenumber,
        billingStreetnumber: streetnumber,
        billingBuildingnumber: buildingnumber,
        billingUnitnumber: unitnumber,
      });
      this.initCountryList().then(() => {
        this.setState({ billingCountry: countryId }, () => {
          this.initStateList().then(() => {
            this.setState({ billingState: stateId }, () => {
              this.initCityList().then(() => {
                this.setState({ billingCity: cityId });
              });
            });
          });
        });
      });
    }

    if (shippingAddress) {
      const {
        name,
        mobile,
        email,
        companyname,
        gstin,
        address1,
        address2,
        landmark,
        cityId,
        stateId,
        countryId,
        zipcode,
        zonenumber,
        streetnumber,
        buildingnumber,
        unitnumber,
      } = shippingAddress;
      this.setState({
        shippingName: name,
        shippingMobileNumber: mobile,
        shippingEmailAddress: email,
        shippingCompanyName: companyname,
        shippingGSTIN: gstin,
        shippingAddressLine1: address1,
        shippingAddressLine2: address2,
        shippingZonenumber: zonenumber,
        shippingLandmark: landmark,
        shippingPincode: zipcode,
        shippingStreetnumber: streetnumber,
        shippingBuildingnumber: buildingnumber,
        shippingUnitnumber: unitnumber,
      });
      this.initShippingCountryList().then(() => {
        this.setState({ shippingCountry: countryId }, () => {
          this.initShippingStateList().then(() => {
            this.setState({ shippingState: stateId }, () => {
              this.initShippingCityList().then(() => {
                this.setState({ shippingCity: cityId });
              });
            });
          });
        });
      });
    }
  };

  onChangeOfShippingCheckbox = (event) => {
    const {
      billingName,
      billingMobileNumber,
      billingEmailAddress,
      billingCompanyName,
      billingGSTIN,
      billingAddressLine1,
      billingAddressLine2,
      billingLandmark,
      billingCountry,
      billingState,
      billingCity,
      billingPincode,
      billingZonenumber,
      billingStreetnumber,
      billingBuildingnumber,
      billingUnitnumber,
    } = this.state;
    const isChecked = event.target.checked;
    this.setState({ chkShippingAddress: event.target.checked }, () => {
      if (isChecked) {
        this.setState({
          shippingName: billingName,
          shippingMobileNumber: billingMobileNumber,
          shippingEmailAddress: billingEmailAddress,
          shippingCompanyName: billingCompanyName,
          shippingGSTIN: billingGSTIN,
          shippingAddressLine1: billingAddressLine1,
          shippingAddressLine2: billingAddressLine2,
          shippingLandmark: billingLandmark,
          shippingPincode: billingPincode,
          shippingZonenumber: billingZonenumber,
          shippingStreetnumber: billingStreetnumber,
          shippingBuildingnumber: billingBuildingnumber,
          shippingUnitnumber: billingUnitnumber,
        });
        this.initShippingCountryList().then(() => {
          this.setState({ shippingCountry: billingCountry }, () => {
            this.initShippingStateList().then(() => {
              this.setState({ shippingState: billingState }, () => {
                this.initShippingCityList().then(() => {
                  this.setState({ shippingCity: billingCity });
                });
              });
            });
          });
        });
      }
    });
  };

  updateUserDetails = async () => {
    const {
      billingName,
      billingMobileNumber,
      billingEmailAddress,
      billingCompanyName,
      billingGSTIN,
      billingAddressLine1,
      billingAddressLine2,
      billingLandmark,
      billingCountry,
      billingState,
      billingCity,
      billingPincode,
      billingZonenumber,
      billingStreetnumber,
      billingBuildingnumber,
      billingUnitnumber,
      shippingName,
      shippingMobileNumber,
      shippingEmailAddress,
      shippingCompanyName,
      shippingGSTIN,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingLandmark,
      shippingCountry,
      shippingState,
      shippingCity,
      shippingPincode,
      shippingZonenumber,
      shippingStreetnumber,
      shippingBuildingnumber,
      shippingUnitnumber,
    } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const userDetailsUrl = "users/" + id;
    const userDetailsObj = {
      shippingaddress: {
        name: shippingName,
        mobile: shippingMobileNumber,
        email: shippingEmailAddress,
        companyname: shippingCompanyName,
        gstin: shippingGSTIN,
        address1: shippingAddressLine1,
        address2: shippingAddressLine2,
        landmark: shippingLandmark,
        countryId: shippingCountry,
        stateId: shippingState,
        cityId: shippingCity,
        zipcode: shippingPincode,
        zonenumber: shippingZonenumber,
        streetnumber: shippingStreetnumber,
        buildingnumber: shippingBuildingnumber,
        unitnumber: shippingUnitnumber,
      },
      billingaddress: {
        name: billingName,
        mobile: billingMobileNumber,
        email: billingEmailAddress,
        companyname: billingCompanyName,
        gstin: billingGSTIN,
        address1: billingAddressLine1,
        address2: billingAddressLine2,
        landmark: billingLandmark,
        countryId: billingCountry,
        stateId: billingState,
        cityId: billingCity,
        zipcode: billingPincode,
        zonenumber: billingZonenumber,
        streetnumber: billingStreetnumber,
        buildingnumber: billingBuildingnumber,
        unitnumber: billingUnitnumber,
      },
      updateShippingPrice: true,
    };
    this.setState({ isLoading: true });
    const updateUserDetails = await patchCall(userDetailsUrl, userDetailsObj);
    this.setState({ isLoading: false });
    if (updateUserDetails.error) {
      clearLocalStorageReload({ response: updateUserDetails });
      this.loadErrorMessageAnimation(updateUserDetails.error.message);
      if (updateUserDetails.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject(
          "str_billing_shipping_address_edited_successfully"
        )
          ? getValueFromLanguageObject(
              "str_billing_shipping_address_edited_successfully"
            )
          : "Billing/Shipping address edited successfully"
      );
      this.preFillUpBillingDetailForm(updateUserDetails);
      saveUserDetailsToLocalStorage(updateUserDetails);
    }
  };

  onClickOfSaveButton = () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    // if (this.state.chkShippingAddress) {
    //   console.log(this.state.chkShippingAddress);
    //   const {
    //     billingName,
    //     billingMobileNumber,
    //     billingEmailAddress,
    //     billingCompanyName,
    //     billingGSTIN,
    //     billingAddressLine1,
    //     billingAddressLine2,
    //     billingLandmark,
    //     billingPincode,
    //     groupWiseGSTINConfig,
    //     currentCatelogueType,
    //     billingZonenumber,
    //     billingStreetnumber,
    //     billingBuildingnumber,
    //     billingUnitnumber,
    //   } = this.state;
    //   let found = false;
    //   if (
    //     userDetails &&
    //     userDetails.groupId !== null &&
    //     userDetails.groupId !== undefined
    //   ) {
    //     const temp = groupWiseGSTINConfig.forEach((groupId) => {
    //       if (userDetails.groupId === groupId) {
    //         found = true;
    //       }
    //       return groupId;
    //     });
    //   }
    //   if (
    //     found &&
    //     currentCatelogueType &&
    //     currentCatelogueType.registerallow === "Catalogue_FMCG"
    //   ) {
    //     if (
    //       billingCompanyName === undefined ||
    //       billingCompanyName === "" ||
    //       billingCompanyName.trim().length === 0
    //     ) {
    //       this.loadErrorMessageAnimation(
    //         getValueFromLanguageObject("str_company_name_should_not_be_empty")
    //           ? getValueFromLanguageObject(
    //               "str_company_name_should_not_be_empty"
    //             )
    //           : "Company Name should not be empty!"
    //       );
    //       return;
    //     }
    //     if (
    //       billingGSTIN === undefined ||
    //       billingGSTIN === "" ||
    //       billingGSTIN.trim().length === 0
    //     ) {
    //       this.loadErrorMessageAnimation(
    //         getValueFromLanguageObject("str_gst_number_should_not_be_empty")
    //           ? getValueFromLanguageObject("str_gst_number_should_not_be_empty")
    //           : "GST Number should not be empty!"
    //       );
    //       return;
    //     }
    //   }
    //   this.setState(
    //     {
    //       shippingName: billingName,
    //       shippingMobileNumber: billingMobileNumber,
    //       shippingEmailAddress: billingEmailAddress,
    //       shippingCompanyName: billingCompanyName,
    //       shippingGSTIN: billingGSTIN,
    //       shippingAddressLine1: billingAddressLine1,
    //       shippingAddressLine2: billingAddressLine2,
    //       shippingLandmark: billingLandmark,
    //       shippingPincode: billingPincode,
    //       shippingZonenumber: billingZonenumber,
    //       shippingStreetnumber: billingStreetnumber,
    //       shippingBuildingnumber: billingBuildingnumber,
    //       shippingUnitnumber: billingUnitnumber,
    //     },
    //     () => {
    //       this.initShippingCountryList().then(() => {
    //         this.setState(
    //           { shippingCountry: this.state.billingCountry },
    //           () => {
    //             this.initShippingStateList().then(() => {
    //               this.setState(
    //                 { shippingState: this.state.billingState },
    //                 () => {
    //                   this.initShippingCityList().then(() => {
    //                     this.setState(
    //                       { shippingCity: this.state.billingCity },
    //                       () => {
    //                         if (this.validator.allValid()) {
    //                           // update user detail
    //                           this.updateUserDetails();
    //                         } else {
    //                           this.validator.showMessages();
    //                           this.forceUpdate();
    //                         }
    //                       }
    //                     );
    //                   });
    //                 }
    //               );
    //             });
    //           }
    //         );
    //       });
    //     }
    //   );
    // }
    // else {
    if (this.validator.allValid()) {
      // update user detail
      const {
        billingCompanyName,
        billingGSTIN,
        shippingCompanyName,
        shippingGSTIN,
        groupWiseGSTINConfig,
        currentCatelogueType,
      } = this.state;
      let found = false;
      if (
        userDetails &&
        userDetails.groupId !== null &&
        userDetails.groupId !== undefined
      ) {
        const temp = groupWiseGSTINConfig.map((groupId) => {
          if (userDetails.groupId === groupId) {
            found = true;
          }
          return groupId;
        });

        if (
          found &&
          currentCatelogueType &&
          currentCatelogueType.registerallow === "Catalogue_FMCG"
        ) {
          if (
            billingCompanyName === undefined ||
            billingCompanyName === "" ||
            billingCompanyName.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_company_name_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_company_name_should_not_be_empty"
                  )
                : "Company Name should not be empty!"
            );
            return;
          }
          if (
            billingGSTIN === undefined ||
            billingGSTIN === "" ||
            billingGSTIN.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_gst_number_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_gst_number_should_not_be_empty"
                  )
                : "GST Number should not be empty!"
            );
            return;
          }
          if (
            shippingCompanyName === undefined ||
            shippingCompanyName === "" ||
            shippingCompanyName.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_company_name_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_company_name_should_not_be_empty"
                  )
                : "Company Name should not be empty!"
            );
            return;
          }
          if (
            shippingGSTIN === undefined ||
            shippingGSTIN === "" ||
            shippingGSTIN.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_gst_number_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_gst_number_should_not_be_empty"
                  )
                : "GST Number should not be empty!"
            );
            return;
          }
        }
      }
      this.updateUserDetails();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    // }
  };

  onChangeOfBillingName = (event) => {
    this.setState({ billingName: event.target.value });
  };

  onChangeOfBillingMobileNumber = (event) => {
    this.setState({ billingMobileNumber: event.target.value });
  };
  onChangeOfBillingEmailAddress = (event) => {
    this.setState({ billingEmailAddress: event.target.value });
  };
  onChangeOfBillingCompanyName = (event) => {
    this.setState({ billingCompanyName: event.target.value });
  };

  onChangeOfBillingGSTIN = (event) => {
    this.setState({ billingGSTIN: event.target.value });
  };

  onChangeOfBillingAddressLine1 = (event) => {
    this.setState({ billingAddressLine1: event.target.value });
  };

  onChangeOfBillingAddressLine2 = (event) => {
    this.setState({ billingAddressLine2: event.target.value });
  };

  onChangeOfBillingLandmark = (event) => {
    this.setState({ billingLandmark: event.target.value });
  };

  onChangeOfBillingCountry = (event) => {
    this.setState({ billingCountry: event.target.value }, () => {
      this.initStateList();
    });
  };

  onChangeOfBillingState = (event) => {
    this.setState({ billingState: event.target.value }, () => {
      this.initCityList();
    });
  };

  onChangeOfBillingCity = (event) => {
    this.setState({ billingCity: event.target.value });
  };

  onChangeOfBillingPincode = (event) => {
    this.setState({ billingPincode: event.target.value });
  };

  onChangeOfBillingZonenumber = (event) => {
    this.setState({ billingZonenumber: event.target.value });
  };

  onChangeOfBillingStreetnumber = (event) => {
    this.setState({ billingStreetnumber: event.target.value });
  };

  onChangeOfBillingBuildingnumber = (event) => {
    this.setState({ billingBuildingnumber: event.target.value });
  };

  onChangeOfBillingUnitnumber = (event) => {
    this.setState({ billingUnitnumber: event.target.value });
  };

  onChangeOfShippingName = (event) => {
    this.setState({ shippingName: event.target.value });
  };

  onChangeOfShippingMobileNumber = (event) => {
    this.setState({ shippingMobileNumber: event.target.value });
  };
  onChangeOfShippingEmailAddress = (event) => {
    this.setState({ shippingEmailAddress: event.target.value });
  };
  onChangeOfShippingCompanyName = (event) => {
    this.setState({ shippingCompanyName: event.target.value });
  };

  onChangeOfShippingGSTIN = (event) => {
    this.setState({ shippingGSTIN: event.target.value });
  };

  onChangeOfShippingAddressLine1 = (event) => {
    this.setState({ shippingAddressLine1: event.target.value });
  };

  onChangeOfShippingAddressLine2 = (event) => {
    this.setState({ shippingAddressLine2: event.target.value });
  };

  onChangeOfShippingZonenumber = (event) => {
    this.setState({ shippingZonenumber: event.target.value });
  };

  onChangeOfShippingStreetnumber = (event) => {
    this.setState({ shippingStreetnumber: event.target.value });
  };

  onChangeOfShippingBuildingnumber = (event) => {
    this.setState({ shippingBuildingnumber: event.target.value });
  };

  onChangeOfShippingUnitnumber = (event) => {
    this.setState({ shippingUnitnumber: event.target.value });
  };

  onChangeOfShippingLandmark = (event) => {
    this.setState({ shippingLandmark: event.target.value });
  };

  onChangeOfShippingCountry = (event) => {
    this.setState({ shippingCountry: event.target.value }, () => {
      this.initShippingStateList();
    });
  };

  onChangeOfShippingState = (event) => {
    this.setState({ shippingState: event.target.value }, () => {
      this.initShippingCityList();
    });
  };

  onChangeOfShippingCity = (event) => {
    this.setState({ shippingCity: event.target.value });
  };

  onChangeOfShippingPincode = (event) => {
    this.setState({ shippingPincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      countryList,
      stateList,
      cityList,
      shippingCountryList,
      shippingStateList,
      shippingCityList,
      isLoading,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      maxCellnumberDigitLimit,
      addressDetails,
    } = this.state;
    const maxCellnumberDigitLimitInt = parseInt(maxCellnumberDigitLimit);
    return (
      <>
        <div>
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_settings")
                ? getValueFromLanguageObject("str_settings")
                : "Settings"
            }
          />
          <section className="blog-detail-page section-b-space">
            <div className="container">
              <div className="checkout-page">
                <div className="checkout-form">
                  <div class="d-flex align-items-center">
                    <div class="mr-auto p-2">
                      <h3 className="billing-address-label">
                        {" "}
                        {getValueFromLanguageObject(
                          "str_manage_billing_shipping_address"
                        )
                          ? getValueFromLanguageObject(
                              "str_manage_billing_shipping_address"
                            )
                          : "Manage Billing/Shipping Address"}{" "}
                      </h3>
                    </div>
                    <div class="p-2">
                      <button
                        type="button"
                        className="btn-solid btn"
                        onClick={() => {
                          this.onClickOfSaveButton();
                        }}
                      >
                        {getValueFromLanguageObject("str_save")
                          ? getValueFromLanguageObject("str_save")
                          : "Save"}
                      </button>
                    </div>
                  </div>

                  <form className="mt-3">
                    <div className="checkout row">
                      <div className="col-lg-12 col-sm-12 col-xs-12">
                        {/* Billing Details */}
                        <div className="checkout-box">
                          <div className="check-out">
                            <div className="checkout-title col-md-12">
                              <h3
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_billing_details_checkout"
                                  ),
                                }}
                              >
                                {getValueFromLanguageObject("billing_details")
                                  ? getValueFromLanguageObject(
                                      "billing_details"
                                    )
                                  : "Billing Details"}
                              </h3>
                            </div>
                            {/* field wrapper */}

                            <div className="field-wrapper row">
                              {/* // Billing Details Name */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[0].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_name_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_name")
                                        ? getValueFromLanguageObject("str_name")
                                        : "Name"}{" "}
                                      {addressDetails[0].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingName"
                                      required={
                                        addressDetails[0].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      value={this.state.billingName}
                                      onChange={this.onChangeOfBillingName}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_name"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_name"
                                            )
                                          : "Enter Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingName",
                                      this.state.billingName,
                                      addressDetails[0].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Mobile number */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[1].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_mobile_number_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "mobile_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "mobile_number"
                                          )
                                        : "Mobile number"}{" "}
                                      {addressDetails[1].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="number"
                                      name="billingMobileNumber"
                                      required={
                                        addressDetails[1].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      // pattern="/^\?([0-9]{10})$/"
                                      value={this.state.billingMobileNumber}
                                      onChange={
                                        this.onChangeOfBillingMobileNumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_mobile_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_mobile_number"
                                            )
                                          : "Enter Mobile Number"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingMobileNumber",
                                      this.state.billingMobileNumber,
                                      addressDetails[1].mandatory === 1
                                        ? `required|regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                        : `regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Email Address */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[2].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_email_address_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_email_address"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_email_address"
                                          )
                                        : "Email Address"}
                                      {addressDetails[2].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingEmailAddress"
                                      value={this.state.billingEmailAddress}
                                      required={
                                        addressDetails[2].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingEmailAddress
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_email_address"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_email_address"
                                            )
                                          : "Enter Email Address"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingEmailAddress",
                                      this.state.billingEmailAddress,
                                      addressDetails[2].mandatory === 1
                                        ? "required|email"
                                        : "email"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Company Name */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[3].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_company_name_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_company_name"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_company_name"
                                          )
                                        : "Company Name"}
                                      {addressDetails[3].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingCompanyName"
                                      value={this.state.billingCompanyName}
                                      required={
                                        addressDetails[3].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingCompanyName
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_company_name"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_company_name"
                                            )
                                          : "Enter Company Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingCompanyName",
                                      this.state.billingCompanyName,
                                      addressDetails[3].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details GSTIN */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[4].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_gstin_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_gst_in")
                                        ? getValueFromLanguageObject(
                                            "str_gst_in"
                                          )
                                        : "GSTIN"}
                                      {addressDetails[4].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingGSTIN"
                                      value={this.state.billingGSTIN}
                                      required={
                                        addressDetails[4].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfBillingGSTIN}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_gstin"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_gstin"
                                            )
                                          : "Enter GSTIN"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingGSTIN",
                                      this.state.billingGSTIN,
                                      addressDetails[4].mandatory === 1
                                        ? [
                                            "required",
                                            {
                                              regex:
                                                "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                            },
                                          ]
                                        : [
                                            "",
                                            {
                                              regex:
                                                "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                            },
                                          ]
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Address 1  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[5].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_address_line_1_checkout"
                                        ),
                                      }}
                                    >
                                      {addressDetails[5].mandatory === 1 && "*"}
                                      {getValueFromLanguageObject(
                                        "str_address_line_1"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_address_line_1"
                                          )
                                        : "Address Line 1"}{" "}
                                      {addressDetails[5].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingAddressLine1"
                                      value={this.state.billingAddressLine1}
                                      required={
                                        addressDetails[5].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingAddressLine1
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_billing_address_line_1"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_billing_address_line_1"
                                            )
                                          : "Enter Billing Address Line 1"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingAddressLine1",
                                      this.state.billingAddressLine1,
                                      addressDetails[5].mandatory === 1
                                        ? "required|min:2|max:256"
                                        : "min:2|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Address 2  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[6].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_address_line_2_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_address_line_2"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_address_line_2"
                                          )
                                        : "Address Line 2"}{" "}
                                      {addressDetails[6].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingAddressLine2"
                                      value={this.state.billingAddressLine2}
                                      required={
                                        addressDetails[6].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingAddressLine2
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_billing_address_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_billing_address_line_2"
                                            )
                                          : "Enter Billing Address Line 2"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingAddressLine2",
                                      this.state.billingAddressLine2,
                                      addressDetails[6].mandatory === 1
                                        ? "required|min:2|max:256"
                                        : "min:2|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details zone number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[12].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_zone_number_checkout"
                                        ),
                                      }}
                                    >
                                      {/* add str_zone_number key in lang json */}
                                      {getValueFromLanguageObject(
                                        "str_zone_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_zone_number"
                                          )
                                        : "Zone Number/Name"}{" "}
                                      {addressDetails[12].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingZonenumber"
                                      value={this.state.billingZonenumber}
                                      required={
                                        addressDetails[12].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingZonenumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_billing_zone_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_billing_zone_number"
                                            )
                                          : "Enter Billing Zone Number/Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingZonenumber",
                                      this.state.billingZonenumber,
                                      addressDetails[12].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "min:1|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details street number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[13].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_street_number_checkout"
                                        ),
                                      }}
                                    >
                                      {/* add str_street_number key in lang json */}
                                      {getValueFromLanguageObject(
                                        "str_street_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_street_number"
                                          )
                                        : "Street Number/Name"}{" "}
                                      {addressDetails[13].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingStreetnumber"
                                      value={this.state.billingStreetnumber}
                                      required={
                                        addressDetails[13].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingStreetnumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_billing_street_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_billing_street_number"
                                            )
                                          : "Enter Billing Street Number/Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingStreetnumber",
                                      this.state.billingStreetnumber,
                                      addressDetails[13].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "min:1|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Building number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[14].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_building_number_checkout"
                                        ),
                                      }}
                                    >
                                      {/* add str_building_number key in lang json */}
                                      {getValueFromLanguageObject(
                                        "str_building_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_building_number"
                                          )
                                        : "Building Number/Name"}{" "}
                                      {addressDetails[14].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingBuildingnumber"
                                      value={this.state.billingBuildingnumber}
                                      required={
                                        addressDetails[14].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingBuildingnumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_billing_building_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_billing_building_number"
                                            )
                                          : "Enter Billing Building Number/Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingBuildingnumber",
                                      this.state.billingBuildingnumber,
                                      addressDetails[14].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "min:1|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Unit number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[15].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_unit_number_checkout"
                                        ),
                                      }}
                                    >
                                      {/* add str_unit_number key in lang json */}
                                      {getValueFromLanguageObject(
                                        "str_unit_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_unit_number"
                                          )
                                        : "Unit Number/Name"}{" "}
                                      {addressDetails[15].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingUnitnumber"
                                      value={this.state.billingUnitnumber}
                                      required={
                                        addressDetails[15].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfBillingUnitnumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_billing_unit_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_billing_unit_number"
                                            )
                                          : "Enter Billing Unit Number/Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingUnitnumber",
                                      this.state.billingUnitnumber,
                                      addressDetails[15].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "min:1|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Billing Details Landmark  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[7].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_landmark_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_landmark"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_landmark"
                                          )
                                        : "Landmark"}{" "}
                                      {addressDetails[7].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="billingLandmark"
                                      value={this.state.billingLandmark}
                                      required={
                                        addressDetails[7].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfBillingLandmark}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_landmark"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_landmark"
                                            )
                                          : "Enter Landmark"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingLandmark",
                                      this.state.billingLandmark,
                                      addressDetails[7].mandatory === 1
                                        ? "required|min:2|max:256"
                                        : "min:2|max:256"
                                    )}
                                  </div>
                                )}
                              {/* // Country */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[9].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_country_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_country")
                                        ? getValueFromLanguageObject(
                                            "str_country"
                                          )
                                        : "Country"}{" "}
                                      {addressDetails[9].mandatory === 1 && "*"}
                                    </div>
                                    <select
                                      name="billingCountry"
                                      value={this.state.billingCountry}
                                      onChange={this.onChangeOfBillingCountry}
                                      required={
                                        addressDetails[9].mandatory === 1
                                          ? true
                                          : false
                                      }
                                    >
                                      <option
                                        value=""
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_select_country_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_select_country"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_select_country"
                                            )
                                          : "Select Country"}
                                      </option>
                                      {countryList.length > 0 &&
                                        countryList.map((country) => {
                                          return (
                                            <option
                                              value={country.id}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_country_name_checkout"
                                                ),
                                              }}
                                            >
                                              {country.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.validator.message(
                                      "billingCountry",
                                      this.state.billingCountry,
                                      addressDetails[9].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}{" "}
                              {/* // State */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[10].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_state_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_state")
                                        ? getValueFromLanguageObject(
                                            "str_state"
                                          )
                                        : "State"}{" "}
                                      {addressDetails[10].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <select
                                      name="billingState"
                                      value={this.state.billingState}
                                      onChange={this.onChangeOfBillingState}
                                      required={
                                        addressDetails[10].mandatory === 1
                                          ? true
                                          : false
                                      }
                                    >
                                      <option
                                        value=""
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_select_state_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_select_state"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_select_state"
                                            )
                                          : "Select State"}
                                      </option>
                                      {stateList.length > 0 &&
                                        stateList.map((state) => {
                                          return (
                                            <option
                                              value={state.id}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_state_name_checkout"
                                                ),
                                              }}
                                            >
                                              {state.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.validator.message(
                                      "billingState",
                                      this.state.billingState,
                                      addressDetails[10].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // City */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[11].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_city_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_town_city"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_town_city"
                                          )
                                        : "Town/City"}{" "}
                                      {addressDetails[11].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <select
                                      name="billingCity"
                                      value={this.state.billingCity}
                                      required={
                                        addressDetails[11].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfBillingCity}
                                    >
                                      <option
                                        value=""
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_select_city_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_select_city"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_select_city"
                                            )
                                          : "Select City"}
                                      </option>
                                      {cityList.length > 0 &&
                                        cityList.map((city) => {
                                          return (
                                            <option
                                              value={city.id}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_city_name_checkout"
                                                ),
                                              }}
                                            >
                                              {city.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.validator.message(
                                      "billingCity",
                                      this.state.billingCity,
                                      addressDetails[11].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // Pincode */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[8].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-12 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_postal_code_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_postal_code"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_postal_code"
                                          )
                                        : "Postal Code"}{" "}
                                      {addressDetails[8].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="number"
                                      name="billingPincode"
                                      value={this.state.billingPincode}
                                      required={
                                        addressDetails[8].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfBillingPincode}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_postal_code"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_postal_code"
                                            )
                                          : "Enter Postal Code"
                                      }
                                    />
                                    {this.validator.message(
                                      "billingPincode",
                                      this.state.billingPincode,
                                      addressDetails[8].mandatory === 1
                                        ? "required|integer"
                                        : "integer"
                                    )}
                                  </div>
                                )}
                            </div>
                            {/* end field wrapper */}
                          </div>
                        </div>
                        <div className="checkout-box">
                          {/* // Shipping Details */}
                          <div className="checkout-title col-md-12">
                            <label
                              className="shipp-dif-add"
                              for="account-option"
                            >
                              {/* <input
                                type="checkbox"
                                name="shippingaddress"
                                id="account-option"
                                checked={this.state.chkShippingAddress}
                                onChange={this.onChangeOfShippingCheckbox}
                              /> */}
                              <h3
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_shipping_details_checkout"
                                  ),
                                }}
                              >
                                {getValueFromLanguageObject(
                                  "str_shipping_address"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_shipping_address"
                                    )
                                  : "Shipping Address"}
                              </h3>
                            </label>
                          </div>

                          <div className="check-out">
                            <div className="row field-wrapper">
                              {/* // Shipping Details Name */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[0].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_name_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_name")
                                        ? getValueFromLanguageObject("str_name")
                                        : "Name"}{" "}
                                      {addressDetails[0].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingName"
                                      required={
                                        addressDetails[0].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      value={this.state.shippingName}
                                      onChange={this.onChangeOfShippingName}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_name"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_name"
                                            )
                                          : "Enter Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingName",
                                      this.state.shippingName,
                                      addressDetails[0].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Mobile number */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[1].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_mobile_number_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "mobile_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "mobile_number"
                                          )
                                        : "Mobile Number"}{" "}
                                      {addressDetails[1].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingMobileNumber"
                                      required={
                                        addressDetails[1].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      pattern="/^\?([0-9]{10})$/"
                                      value={this.state.shippingMobileNumber}
                                      onChange={
                                        this.onChangeOfShippingMobileNumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_mobile_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_mobile_number"
                                            )
                                          : "Enter Mobile Number"
                                      }
                                    />
                                    {/* {this.validator.message(
                                  "shippingMobileNumber",
                                  this.state.shippingMobileNumber,
                                  "required"
                                )} */}
                                    {this.validator.message(
                                      "shippingMobileNumber",
                                      this.state.shippingMobileNumber,
                                      addressDetails[1].mandatory === 1
                                        ? `required|regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                        : `regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Email Address */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[2].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_email_address_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_email_address"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_email_address"
                                          )
                                        : "Email Address"}
                                      {addressDetails[2].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingEmailAddress"
                                      value={this.state.shippingEmailAddress}
                                      required={
                                        addressDetails[2].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingEmailAddress
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_email_address"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_email_address"
                                            )
                                          : "Enter Email Address"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingEmailAddress",
                                      this.state.shippingEmailAddress,
                                      addressDetails[2].mandatory === 1
                                        ? "required|email"
                                        : "email"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Company Name */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[3].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_company_name_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_company_name"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_company_name"
                                          )
                                        : "Company Name"}
                                      {addressDetails[3].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingCompanyName"
                                      value={this.state.shippingCompanyName}
                                      required={
                                        addressDetails[3].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingCompanyName
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_company_name"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_company_name"
                                            )
                                          : "Enter Company Name"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingCompanyName",
                                      this.state.shippingCompanyName,
                                      addressDetails[3].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // Shipping Details GSTIN */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[4].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_gstin_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_gst_in")
                                        ? getValueFromLanguageObject(
                                            "str_gst_in"
                                          )
                                        : "GSTIN"}
                                      {addressDetails[4].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingGSTIN"
                                      value={this.state.shippingGSTIN}
                                      required={
                                        addressDetails[4].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfShippingGSTIN}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_gstin"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_gstin"
                                            )
                                          : "Enter GSTIN"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingGSTIN",
                                      this.state.shippingGSTIN,
                                      [
                                        "",
                                        addressDetails[4].mandatory === 1
                                          ? {
                                              regex:
                                                "required|^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                            }
                                          : {
                                              regex:
                                                "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                            },
                                      ]
                                    )}
                                  </div>
                                )}
                              {/* // Shipping Details Address 1  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[5].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_address_line_1_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_address_line_1"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_address_line_1"
                                          )
                                        : "Address Line 1"}{" "}
                                      {addressDetails[5].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingAddressLine1"
                                      value={this.state.shippingAddressLine1}
                                      required={
                                        addressDetails[5].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingAddressLine1
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_shipping_address_line_1"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_shipping_address_line_1"
                                            )
                                          : "Enter shipping Address Line 1"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingAddressLine1",
                                      this.state.shippingAddressLine1,
                                      addressDetails[5].mandatory === 1
                                        ? "required|min:2|max:256"
                                        : "min:2|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Address 2  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[6].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_address_line_2_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_address_line_2"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_address_line_2"
                                          )
                                        : "Address Line 2"}{" "}
                                      {addressDetails[6].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingAddressLine2"
                                      value={this.state.shippingAddressLine2}
                                      required={
                                        addressDetails[6].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingAddressLine2
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_shipping_address_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_shipping_address_line_2"
                                            )
                                          : "Enter shipping Address Line 2"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingAddressLine2",
                                      this.state.shippingAddressLine2,
                                      addressDetails[6].mandatory === 1
                                        ? "required|min:2|max:256"
                                        : "|min:2|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Zone Number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[12].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_zone_number_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_zone_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_zone_number"
                                          )
                                        : "Zone Number/Name"}{" "}
                                      {addressDetails[12].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingZonenumber"
                                      value={this.state.shippingZonenumber}
                                      required={
                                        addressDetails[12].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingZonenumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_zone_number_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_zone_number_line_2"
                                            )
                                          : "Enter Zone Number/Name Line 2"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingZonenumber",
                                      this.state.shippingZonenumber,
                                      addressDetails[12].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "|min:1|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Street Number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[13].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_street_number_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_street_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_street_number"
                                          )
                                        : "Street Number/Name"}{" "}
                                      {addressDetails[13].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingStreetnumber"
                                      value={this.state.shippingStreetnumber}
                                      required={
                                        addressDetails[13].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingStreetnumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_street_number_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_street_number_line_2"
                                            )
                                          : "Enter Street Number/Name Line 2"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingStreetnumber",
                                      this.state.shippingStreetnumber,
                                      addressDetails[13].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "|min:1|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Building Number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[14].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_building_number_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_building_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_building_number"
                                          )
                                        : "Building Number/Name"}{" "}
                                      {addressDetails[14].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingBuildingnumber"
                                      value={this.state.shippingBuildingnumber}
                                      required={
                                        addressDetails[14].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingBuildingnumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_building_number_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_building_number_line_2"
                                            )
                                          : "Enter Building Number/Name Line 2"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingBuildingnumber",
                                      this.state.shippingBuildingnumber,
                                      addressDetails[14].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "|min:1|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Unit Number  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[15].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_unit_number_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_unit_number"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_unit_number"
                                          )
                                        : "Unit Number/Name"}{" "}
                                      {addressDetails[15].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingUnitnumber"
                                      value={this.state.shippingUnitnumber}
                                      required={
                                        addressDetails[15].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={
                                        this.onChangeOfShippingUnitnumber
                                      }
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_unit_number_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_unit_number_line_2"
                                            )
                                          : "Enter Unit Number/Name Line 2"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingUnitnumber",
                                      this.state.shippingUnitnumber,
                                      addressDetails[15].mandatory === 1
                                        ? "required|min:1|max:256"
                                        : "|min:1|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Shipping Details Landmark  */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[7].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_landmark_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_landmark"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_landmark"
                                          )
                                        : "Landmark"}{" "}
                                      {addressDetails[7].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="text"
                                      name="shippingLandmark"
                                      value={this.state.shippingLandmark}
                                      required={
                                        addressDetails[7].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfShippingLandmark}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_landmark"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_landmark"
                                            )
                                          : "Enter Landmark"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingLandmark",
                                      this.state.shippingLandmark,
                                      addressDetails[7].mandatory === 1
                                        ? "required|min:2|max:256"
                                        : "min:2|max:256"
                                    )}
                                  </div>
                                )}

                              {/* // Country */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[9].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_country_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_country")
                                        ? getValueFromLanguageObject(
                                            "str_country"
                                          )
                                        : "Country"}{" "}
                                      {addressDetails[9].mandatory === 1 && "*"}
                                    </div>
                                    <select
                                      name="shippingCountry"
                                      value={this.state.shippingCountry}
                                      onChange={this.onChangeOfShippingCountry}
                                      required={
                                        addressDetails[9].mandatory === 1
                                          ? true
                                          : false
                                      }
                                    >
                                      <option
                                        value=""
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_select_country_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_select_country"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_select_country"
                                            )
                                          : "Select Country"}
                                      </option>
                                      {shippingCountryList.length > 0 &&
                                        shippingCountryList.map((country) => {
                                          return (
                                            <option
                                              value={country.id}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_country_name_checkout"
                                                ),
                                              }}
                                            >
                                              {country.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.validator.message(
                                      "shippingCountry",
                                      this.state.shippingCountry,
                                      addressDetails[9].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // State */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[10].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_state_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject("str_state")
                                        ? getValueFromLanguageObject(
                                            "str_state"
                                          )
                                        : "State"}{" "}
                                      {addressDetails[10].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <select
                                      name="shippingState"
                                      value={this.state.shippingState}
                                      required={
                                        addressDetails[10].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfShippingState}
                                    >
                                      <option
                                        value=""
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_select_state_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_select_state"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_select_state"
                                            )
                                          : "Select State"}
                                      </option>
                                      {shippingStateList.length > 0 &&
                                        shippingStateList.map((state) => {
                                          return (
                                            <option
                                              value={state.id}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_state_name_checkout"
                                                ),
                                              }}
                                            >
                                              {state.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.validator.message(
                                      "shippingState",
                                      this.state.shippingState,
                                      addressDetails[10].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // City */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[11].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_city_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_town_city"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_town_city"
                                          )
                                        : "Town/City"}{" "}
                                      {addressDetails[11].mandatory === 1 &&
                                        "*"}
                                    </div>
                                    <select
                                      name="shippingCity"
                                      value={this.state.shippingCity}
                                      required={
                                        addressDetails[11].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfShippingCity}
                                    >
                                      <option
                                        value=""
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_select_city_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_select_city"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_select_city"
                                            )
                                          : "Select City"}
                                      </option>
                                      {shippingCityList.length > 0 &&
                                        shippingCityList.map((city) => {
                                          return (
                                            <option
                                              value={city.id}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_city_name_checkout"
                                                ),
                                              }}
                                            >
                                              {city.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.validator.message(
                                      "shippingCity",
                                      this.state.shippingCity,
                                      addressDetails[11].mandatory === 1
                                        ? "required"
                                        : ""
                                    )}
                                  </div>
                                )}
                              {/* // Pincode */}
                              {addressDetails &&
                                addressDetails.length > 0 &&
                                addressDetails[8].visible === 1 && (
                                  <div className="form-group col-md-4 col-sm-6 col-xs-12">
                                    <div
                                      className="field-label"
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_postal_code_checkout"
                                        ),
                                      }}
                                    >
                                      {getValueFromLanguageObject(
                                        "str_postal_code"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_postal_code"
                                          )
                                        : "Postal Code"}{" "}
                                      {addressDetails[8].mandatory === 1 && "*"}
                                    </div>
                                    <input
                                      type="number"
                                      name="shippingPincode"
                                      value={this.state.shippingPincode}
                                      required={
                                        addressDetails[8].mandatory === 1
                                          ? true
                                          : false
                                      }
                                      onChange={this.onChangeOfShippingPincode}
                                      placeholder={
                                        getValueFromLanguageObject(
                                          "str_enter_postal_code"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_enter_postal_code"
                                            )
                                          : "Enter Postal Code"
                                      }
                                    />
                                    {this.validator.message(
                                      "shippingPincode",
                                      this.state.shippingPincode,
                                      addressDetails[8].mandatory === 1
                                        ? "required|integer"
                                        : "integer"
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(Settings));
