import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { isInquiryOrCart } from "../../../actions";

class FilterBar extends Component {
  //List Layout View
  listLayout = (colSize) => {
    document.querySelector(".collection-grid-view").style = "opacity:0";
    document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function(el) {
      el.className = "";
      el.classList.add("col-lg-" + colSize);
    });
    setTimeout(function() {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
    this.props.onLayoutViewClicked(colSize);
  };
  // Layout Column View
  LayoutView = (colSize) => {
    if (
      !document
        .querySelector(".product-wrapper-grid")
        .classList.contains("list-view")
    ) {
      var elems = document.querySelector(".infinite-scroll-component .row")
        .childNodes;
      [].forEach.call(elems, function(el) {
        el.className = "";
        el.classList.add("col-lg-" + colSize);
      });
    }
    this.props.onLayoutViewClicked(colSize);
  };

  //Grid Layout View
  gridLayout = (colSize) => {
    document.querySelector(".collection-grid-view").style = "opacity:1";
    document
      .querySelector(".product-wrapper-grid")
      .classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function(el) {
      el.className = "";
      el.classList.add("col-lg-" + colSize);
    });
    this.props.onLayoutViewClicked(colSize);
  };

  render() {
    const { isInquiry, productList } = this.props;
    const { length } = productList || [];
    return (
      <div className="product-filter-content filter-border-bottom">
        <div className="search-count" style={{ maxWidth: "35%" }}>
          <h5>
            {`${
              getValueFromLanguageObject("str_showing_products")
                ? getValueFromLanguageObject("str_showing_products")
                : "Showing Products"
            } ${length} ${
              getValueFromLanguageObject("str_results")
                ? getValueFromLanguageObject("str_results")
                : "Results"
            }`}
          </h5>
        </div>
        <div className="collection-view">
          <ul>
            <li>
              <i
                className="fa fa-th grid-layout-view"
                onClick={() => this.gridLayout(3)}
              />
            </li>
            <li>
              <i
                className="fa fa-list-ul list-layout-view"
                onClick={() => this.listLayout(12)}
              />
            </li>
          </ul>
        </div>
        <div className="collection-grid-view">
          <ul>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`}
                alt=""
                className="product-2-layout-view"
                onClick={() => this.LayoutView(6)}
              />
            </li>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                alt=""
                className="product-3-layout-view"
                onClick={() => this.LayoutView(4)}
              />
            </li>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`}
                alt=""
                className="product-4-layout-view"
                onClick={() => this.LayoutView(3)}
              />
            </li>
            {/* <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                alt=""
                className="product-6-layout-view"
                onClick={() => this.LayoutView(2)}
              />
            </li> */}
          </ul>
        </div>
        <div className="product-page-filter">
          <select
            onChange={(e) =>
              this.props.handleSortingProductFilter(e.target.value)
            }
          >
            <option value={1}>
              {getValueFromLanguageObject("str_sorting_items")
                ? getValueFromLanguageObject("str_sorting_items")
                : "Sorting items"}
            </option>
            {!isInquiry && (
              <>
                <option value={2}>
                  {getValueFromLanguageObject("str_price_high_to_low")
                    ? getValueFromLanguageObject("str_price_high_to_low")
                    : "Price: High to Low"}
                </option>
                <option value={3}>
                  {getValueFromLanguageObject("str_price_low_to_high")
                    ? getValueFromLanguageObject("str_price_low_to_high")
                    : "Price: Low to High"}
                </option>
              </>
            )}
            <option value={4}>
              {getValueFromLanguageObject("str_best_selling")
                ? getValueFromLanguageObject("str_best_selling")
                : "Best Selling"}
            </option>
            <option value={5}>
              {getValueFromLanguageObject("str_newest_products")
                ? getValueFromLanguageObject("str_newest_products")
                : "Newest Products"}
            </option>
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
});

export default connect(mapStateToProps, {
  isInquiryOrCart: isInquiryOrCart,
})(FilterBar);
