import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getValueFromLanguageObject,
} from "../../store";

class Breadcrumb extends Component {
  redirectToRoutes = () => {
    const { title, orderId } = this.props;

    const strProducts = getValueFromLanguageObject("str_products")
      ? getValueFromLanguageObject("str_products")
      : "Products";

    const strCheckout = getValueFromLanguageObject("checkout")
      ? getValueFromLanguageObject("checkout")
      : "CheckOut";

    const strCartPage = getValueFromLanguageObject("cart_page")
      ? getValueFromLanguageObject("cart_page")
      : "Cart Page";

    const strContactUs = getValueFromLanguageObject("str_contact_us")
      ? getValueFromLanguageObject("str_contact_us")
      : "Contact Us";

    const strAboutUs = getValueFromLanguageObject("str_about_us")
      ? getValueFromLanguageObject("str_about_us")
      : "About Us";

    const strOrders = getValueFromLanguageObject("str_orders")
      ? getValueFromLanguageObject("str_orders")
      : "Orders";

    const strEditOrder = getValueFromLanguageObject("str_edit_order")
      ? getValueFromLanguageObject("str_edit_order")
      : "Edit Order";

    if (title === strProducts) {
      return "/products";
    }else if (title === strCheckout) {
      return "/checkout";
    }else if (title === strCartPage) {
      return "/cart";
    }else if (title === strContactUs) {
      return "/contactus";
    }else if (title === strAboutUs) {
      return "/aboutus";
    }else if (title === strOrders) {
      return "/orders";
    }else if (title === strEditOrder) {
      return `/editorder/${orderId}`;
    }else{
      return "/"
    }
  };

  render() {
    const {
      title,
      productName,
      parentCategory,
      subCategory,
    } = this.props;
    const { id: parentCategoryId, name: parentCategoryName } =
      parentCategory || {};
    const { id: subCategoryId, name: subCategoryName } = subCategory || {};

    return (
      <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div>
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/`}>
                      {getValueFromLanguageObject("str_home")
                        ? getValueFromLanguageObject("str_home")
                        : "Home"}
                    </Link>
                  </li>

                  {title && (
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link
                        // to={`${strProducts === title ? "/products" : "/"} `}
                        to={this.redirectToRoutes(title)}
                      >
                        {title}
                      </Link>
                    </li>
                  )}
                  {parentCategoryId && (
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to={`/c/${parentCategoryId}`}>
                        {`${parentCategoryName}`}
                      </Link>
                    </li>
                  )}

                  {subCategoryId && (
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to={`/s/${subCategoryId}`}>
                        {`${subCategoryName}`}
                      </Link>
                    </li>
                  )}
                  {productName && (
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to="#"> {`${productName}`}</Link>
                    </li>
                  )}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
