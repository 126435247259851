import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getCurrentLanguage,
} from "../../store";
import { customPostCall, getCall, patchCall, postCall } from "../../api/post";
import { payment } from "../../api/payment";
import { getNotificationCounter, isInquiryOrCart } from "../../actions";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import { paytmConfigure } from "./paytm_config";
import { GooglePayConfigure } from "./goople_pay_config";
import PaypalExpressBtn from "react-paypal-express-checkout";
import GooglePayButton from "@google-pay/button-react";
import {
  getSettingUrl,
  getShippingOptionsUrl,
  getMerchantInformationUrl,
  getPaymentDetailsUrl,
  getOrderViaWhatsAppUrl,
  getGroupWiseGSTConfigurationUrl,
  getPincodeDeliveryUrl,
  getMasterDetailsIdUrl,
  getAddressDetailUrl,
  getSadadPaymentChecksumUrl,
  getServiceChargesUrl,
} from "../../services";
import AddBillingAddressModal from "./AddBillingAddressModal";
import EditBillingAddressModal from "./EditBillingAddressModal";
import MainLoader from "../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import {
  clearLocalStorageReload,
  getCurrentDate,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../utils/utils";
import { withRouter } from "react-router-dom";
import { sadadPaymentConfigure } from "./sadad_config";
import SadadPaymentForm from "./SadadPaymentForm";

const SSR_SIMULATE = "SSR Simulate : ";

class checkOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartList: [],
      isLoading: true,
      merchantInfo: {},
      isMerchantGSTEnable: false,
      shippingCharges: [],
      paymentDetails: [],
      paymentMethod: "Cash On Delivery",
      groupWiseGSTINConfig: [],
      paytmConfig: {},
      showCheckout: false,
      openInPopup: true,
      checkoutJsInstance: null,
      textAreaValue: "",
      isPlaceOrderButtonDisable: false,
      payUMoneyConfig: {},
      payPalConfig: {},
      googlePayConfig: {},
      isPaymentDone: false,
      AppTitle: "",
      currentCatelogueType: {},
      isPincodeDeliveryEnable: false,
      userDetails: {},
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
      masterDetailsObj: {},
      sadadPaymentChecksum: "",
      sadadPaymentConfig: {},
      isOpenPayWithSadad: false,
      isHidePlaceOrderButton: true,
    };
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login/isFromCheckout=${true}`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initCartList().then(() => {
        this.setState({ isLoading: false });
      });
      this.initMerchantInfo();
      this.initOrderViaWhatsApp();
      this.initShippingCharges();
      this.initServiceCharges();
      this.initPaymentDetails();
      this.initGroupWiseGSTINConfig();
      this.initCatelogueSettings();
      this.initPincodeDelivery();
      this.initUserDetails();
      this.props.notificationCounter();
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initBillingShippingAddressConfig();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCartList = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const cartListUrl = `orders?filter[where][userId]=${id}&filter[where][inshoppingcart]=${1}&filter[order]=created`;

    const cartList = await getCall(cartListUrl, []);
    if (cartList.error) {
      clearLocalStorageReload({ response: cartList });
      this.loadErrorMessageAnimation(cartList.error.message);
      if (cartList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (cartList.length === 0) {
        this.props.history.push("/");
        return;
      }
      this.setState({ cartList });
    }
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      clearLocalStorageReload({ response: paymentDetails });
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  initShippingCharges = async () => {
    const { masterDetailsObj } = this.state;
    const shippingChargesUrl = getShippingOptionsUrl(masterDetailsObj);
    const shippingCharges = await getCall(shippingChargesUrl, []);
    if (shippingCharges.error) {
      clearLocalStorageReload({ response: shippingCharges });
    }
    this.setState({ shippingCharges: shippingCharges });
  };

  initServiceCharges = async () => {
    const { masterDetailsObj } = this.state;
    const serviceChargesUrl = getServiceChargesUrl(masterDetailsObj);
    const serviceChargesResponse = await getCall(serviceChargesUrl, []);

    const { error } = serviceChargesResponse || {};
    if (error) {
      clearLocalStorageReload({ response: serviceChargesResponse });
    }
    const [serviceCharges] = serviceChargesResponse || {};
    this.setState({ serviceCharges: serviceCharges });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initOrderViaWhatsApp = async () => {
    const { masterDetailsObj } = this.state;
    const orderViaWhatsappUrl = getOrderViaWhatsAppUrl(masterDetailsObj);
    const orderViaWhatsappResponse = await getCall(orderViaWhatsappUrl, []);
    if (orderViaWhatsappResponse.error) {
      clearLocalStorageReload({ response: orderViaWhatsappResponse });
      this.setState({ orderViaWhatsapp: {} });
    } else {
      if (orderViaWhatsappResponse.length > 0) {
        this.setState({
          orderViaWhatsapp: orderViaWhatsappResponse[0],
        });
      } else {
        this.setState({ orderViaWhatsapp: {} });
      }
    }
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);

    if (userDetailsResponse.error) {
      clearLocalStorageReload({ response: userDetailsResponse });
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ userDetails: userDetailsResponse });
    }
  };

  initGroupWiseGSTINConfig = async () => {
    const { masterDetailsObj } = this.state;
    const groupWiseGSTINConfigUrl = getGroupWiseGSTConfigurationUrl(
      masterDetailsObj
    );
    const groupWiseGSTINConfig = await getCall(groupWiseGSTINConfigUrl, []);
    if (groupWiseGSTINConfig.error) {
      clearLocalStorageReload({ response: groupWiseGSTINConfig });
      this.loadErrorMessageAnimation(groupWiseGSTINConfig.error.message);
    } else {
      if (
        groupWiseGSTINConfig &&
        groupWiseGSTINConfig.length > 0 &&
        groupWiseGSTINConfig[0].status === 1
      ) {
        this.setState({
          groupWiseGSTINConfig: JSON.parse(groupWiseGSTINConfig[0].text),
        });
      }
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const pincodeDelivery = await getCall(
      getPincodeDeliveryUrl(masterDetailsObj),
      []
    );
    if (pincodeDelivery.error) {
      clearLocalStorageReload({ response: pincodeDelivery });
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  onChangeOfOrderNotes = (event) => {
    this.setState({ orderNotes: event.target.value });
  };

  makePaytmPayment = () => {
    this.loadCheckoutScript();
  };

  initiateSadadPayment = () => {
    this.getChecksumSadadPayment();
  };

  onClickOfPlaceOrder = () => {
    const { currentCatelogueType, userDetails } = this.state;
    const { billingaddress, shippingaddress } = userDetails;
    const billingAddress = JSON.parse(billingaddress)
      ? JSON.parse(billingaddress)
      : null;
    const shippingAddress = JSON.parse(shippingaddress)
      ? JSON.parse(shippingaddress)
      : null;

    const { groupWiseGSTINConfig } = this.state;
    let found = false;
    if (
      userDetails &&
      userDetails.groupId !== null &&
      userDetails.groupId !== undefined
    ) {
      const temp = groupWiseGSTINConfig.map((groupId) => {
        if (userDetails.groupId === groupId) {
          found = true;
        }
        return groupId;
      });

      if (
        found &&
        currentCatelogueType &&
        currentCatelogueType.registerallow === "Catalogue_FMCG"
      ) {
        if (
          billingAddress.companyname === undefined ||
          billingAddress.companyname === "" ||
          billingAddress.companyname.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            getValueFromLanguageObject(
              "str_company_name is_required_in_billing_address"
            )
              ? getValueFromLanguageObject(
                  "str_company_name is_required_in_billing_address"
                )
              : "Company Name is required in billing address, Please add!"
          );
          return;
        }
        if (
          billingAddress.gstin === undefined ||
          billingAddress.gstin === "" ||
          billingAddress.gstin.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            getValueFromLanguageObject(
              "str_gst_number is_required_in_billing_address"
            )
              ? getValueFromLanguageObject(
                  "str_gst_number is_required_in_billing_address"
                )
              : "GST Number is required in billing address, Please add!"
          );
          return;
        }
        if (
          shippingAddress.companyname === undefined ||
          shippingAddress.companyname === "" ||
          shippingAddress.companyname.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            getValueFromLanguageObject(
              "str_company_name_is_required_in_shipping_address"
            )
              ? getValueFromLanguageObject(
                  "str_company_name_is_required_in_shipping_address"
                )
              : "Company Name is required in shipping address, Please add!"
          );
          return;
        }
        if (
          shippingAddress.gstin === undefined ||
          shippingAddress.gstin === "" ||
          shippingAddress.gstin.trim().length === 0
        ) {
          this.loadErrorMessageAnimation(
            getValueFromLanguageObject(
              "str_gst_number_is_required_in_shipping_address"
            )
              ? getValueFromLanguageObject(
                  "str_gst_number_is_required_in_shipping_address"
                )
              : "GST Number is required in shipping address, Please add!"
          );
          return;
        }
      }
    }
    this.paymnetDetailStatus();
  };

  onClickOfOrderViaWhatsApp = () => {
    const { userDetails, cartList, AppTitle, orderViaWhatsapp } = this.state;
    const { symbol, isInquiry } = this.props;

    let CustomerDetails = `${userDetails.username},\n${
      userDetails.cellnumber
    },`;

    if (!isInquiry) {
      const { billingaddress, shippingaddress } = userDetails;
      const billingaddressObj = billingaddress
        ? JSON.parse(billingaddress)
        : null;
      const shippingaddressObj = shippingaddress
        ? JSON.parse(shippingaddress)
        : null;

      var productList = "";
      if (cartList && cartList.length > 0) {
        cartList[0].orderdetail.map((d, index) => {
          return (productList = productList.concat(
            ` ${d.products.name} : (${symbol}${parseFloat(d.amount).toFixed(
              2
            )} × ${d.quantity})\n `
          ));
        });
      }
      const subTotal = this.getSubTotalFromTotalandServiceCharges();
      let TotalAmount = this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      );

      let billingAddressText = "";
      let shippingAddressText = "";

      if (billingaddressObj.name) {
        billingAddressText = billingAddressText.concat(
          `\nname : ${billingaddressObj.name}\n`
        );
      }

      if (billingaddressObj.mobile) {
        billingAddressText = billingAddressText.concat(
          `mobile : ${billingaddressObj.mobile}\n`
        );
      }

      if (billingaddressObj.email) {
        billingAddressText = billingAddressText.concat(
          ` ${
            getValueFromLanguageObject("str_email_address")
              ? getValueFromLanguageObject("str_email_address")
              : "Email Address"
          } : ${billingaddressObj.email}\n`
        );
      }

      if (billingaddressObj.companyname) {
        billingAddressText = billingAddressText.concat(
          `companyname : ${billingaddressObj.companyname}\n`
        );
      }

      if (billingaddressObj.gstin) {
        billingAddressText = billingAddressText.concat(
          ` ${
            getValueFromLanguageObject("str_gstin")
              ? getValueFromLanguageObject("str_gstin")
              : "GSTIN"
          } : ${billingaddressObj.gstin}\n`
        );
      }

      if (billingaddressObj.address1) {
        billingAddressText = billingAddressText.concat(
          `address1 : ${billingaddressObj.address1}\n`
        );
      }

      if (billingaddressObj.address2) {
        billingAddressText = billingAddressText.concat(
          `address2 : ${billingaddressObj.address2}\n`
        );
      }

      if (billingaddressObj.zonenumber) {
        billingAddressText = billingAddressText.concat(
          `zonenumber : ${billingaddressObj.zonenumber}\n`
        );
      }

      if (billingaddressObj.streetnumber) {
        billingAddressText = billingAddressText.concat(
          `streetnumber : ${billingaddressObj.streetnumber}\n`
        );
      }

      if (billingaddressObj.buildingnumber) {
        billingAddressText = billingAddressText.concat(
          `buildingnumber : ${billingaddressObj.buildingnumber}\n`
        );
      }

      if (billingaddressObj.unitnumber) {
        billingAddressText = billingAddressText.concat(
          `unitnumber : ${billingaddressObj.unitnumber}\n`
        );
      }

      if (billingaddressObj.landmark) {
        billingAddressText = billingAddressText.concat(
          `landmark : ${billingaddressObj.landmark}\n`
        );
      }

      if (billingaddressObj.cityname) {
        billingAddressText = billingAddressText.concat(
          `city : ${billingaddressObj.cityname}\n`
        );
      }
      if (billingaddressObj.statename) {
        billingAddressText = billingAddressText.concat(
          `state : ${billingaddressObj.statename}\n`
        );
      }
      if (billingaddressObj.countryname) {
        billingAddressText = billingAddressText.concat(
          `country : ${billingaddressObj.countryname}\n`
        );
      }

      if (billingaddressObj.zipcode) {
        billingAddressText = billingAddressText.concat(
          `zipcode : ${billingaddressObj.zipcode}`
        );
      }

      if (shippingaddressObj.name) {
        shippingAddressText = shippingAddressText.concat(
          `\nname : ${shippingaddressObj.name}\n`
        );
      }

      if (shippingaddressObj.mobile) {
        shippingAddressText = shippingAddressText.concat(
          `mobile : ${shippingaddressObj.mobile}\n`
        );
      }

      if (shippingaddressObj.email) {
        shippingAddressText = shippingAddressText.concat(
          ` ${
            getValueFromLanguageObject("str_email_address")
              ? getValueFromLanguageObject("str_email_address")
              : "Email Address"
          } : ${shippingaddressObj.email}\n`
        );
      }

      if (shippingaddressObj.companyname) {
        shippingAddressText = shippingAddressText.concat(
          `companyname : ${shippingaddressObj.companyname}\n`
        );
      }

      if (shippingaddressObj.gstin) {
        shippingAddressText = shippingAddressText.concat(
          ` ${
            getValueFromLanguageObject("str_gstin")
              ? getValueFromLanguageObject("str_gstin")
              : "GSTIN"
          } : ${shippingaddressObj.gstin}\n`
        );
      }

      if (shippingaddressObj.address1) {
        shippingAddressText = shippingAddressText.concat(
          `address1 : ${shippingaddressObj.address1}\n`
        );
      }

      if (shippingaddressObj.address2) {
        shippingAddressText = shippingAddressText.concat(
          `address2 : ${shippingaddressObj.address2}\n`
        );
      }

      if (shippingaddressObj.zonenumber) {
        shippingAddressText = shippingAddressText.concat(
          `zonenumber : ${shippingaddressObj.zonenumber}\n`
        );
      }

      if (shippingaddressObj.streetnumber) {
        shippingAddressText = shippingAddressText.concat(
          `streetnumber : ${shippingaddressObj.streetnumber}\n`
        );
      }

      if (shippingaddressObj.buildingnumber) {
        shippingAddressText = shippingAddressText.concat(
          `buildingnumber : ${shippingaddressObj.buildingnumber}\n`
        );
      }

      if (shippingaddressObj.unitnumber) {
        shippingAddressText = shippingAddressText.concat(
          `unitnumber : ${shippingaddressObj.unitnumber}\n`
        );
      }

      if (shippingaddressObj.landmark) {
        shippingAddressText = shippingAddressText.concat(
          `landmark : ${shippingaddressObj.landmark}\n`
        );
      }

      if (shippingaddressObj.cityname) {
        shippingAddressText = shippingAddressText.concat(
          `city : ${shippingaddressObj.cityname}\n`
        );
      }
      if (shippingaddressObj.statename) {
        shippingAddressText = shippingAddressText.concat(
          `state : ${shippingaddressObj.statename}\n`
        );
      }
      if (shippingaddressObj.countryname) {
        shippingAddressText = shippingAddressText.concat(
          `country : ${shippingaddressObj.countryname}\n`
        );
      }

      if (shippingaddressObj.zipcode) {
        shippingAddressText = shippingAddressText.concat(
          `zipcode : ${shippingaddressObj.zipcode}`
        );
      }

      let orderNotesText = "";
      if (this.state.orderNotes) {
        orderNotesText = `Order Notes : ${this.state.orderNotes}`;
      }

      let text = `New order - ${AppTitle}\nOrderId : ${this.getOrderId(
        cartList
      )}\nItems : ${productList} \nAmount Payable : ${TotalAmount}\n\nCustomer Details : \n${CustomerDetails} \nBilling Address : ${billingAddressText} \n\nShipping Address : ${shippingAddressText} \n\n${orderNotesText}`;
      const mobileNumber = JSON.parse(orderViaWhatsapp.text).cellnumber;
      const countryCode = JSON.parse(orderViaWhatsapp.text).countryCode;
      var whatsAppLink = `https://api.whatsapp.com/send?phone=${countryCode}${mobileNumber}&text=${encodeURI(
        text
      )}`;
      window.open(whatsAppLink);
      return;
    } else {
      productList = "";
      if (cartList && cartList.length > 0) {
        cartList[0].orderdetail.map((d, index) => {
          return (productList = productList.concat(
            ` ${d.products.name} - Qty : ${d.quantity}\n `
          ));
        });
      }

      let text = `New Inquiry - ${AppTitle}\nInquiryId : ${this.getOrderId(
        cartList
      )}\nItems : ${productList} \nCustomer Details : \n${CustomerDetails}`;

      const mobileNumber = JSON.parse(orderViaWhatsapp.text).cellnumber;
      const countryCode = JSON.parse(orderViaWhatsapp.text).countryCode;
      whatsAppLink = `https://api.whatsapp.com/send?phone=${countryCode}${mobileNumber}&text=${encodeURI(
        text
      )}`;
      window.open(whatsAppLink);
      return;
    }
  };

  paymnetDetailStatus = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { paymentMethod, paymentDetails } = this.state;

    if (
      paymentDetails &&
      paymentDetails.length > 0 &&
      paymentDetails[0].status === 0
    ) {
      this.setState({ isLoading: true });
      this.placeAnOrder().then(() => {
        this.setState({ isLoading: false });
      });
    } else {
      if (paymentMethod === "Cash On Delivery") {
        this.setState({ isLoading: true });
        this.placeAnOrder().then(() => {
          this.setState({ isLoading: false }, () => {
            this.initUpdatePaymentStatusCOD();
          });
        });
      } else if (paymentMethod === "RazorPay") {
        this.setState({ isLoading: true }, () => {
          this.placeAnOrder().then(() => {
            this.setState({ isLoading: false });
            setTimeout(() => {
              this.initRazorPayPayment();
            }, 500);
          });
        });
      } else if (paymentMethod === "Paytm") {
        this.setState({ isLoading: true }, () => {
          this.placeAnOrder().then(() => {
            this.setState({ isLoading: false });
            setTimeout(() => {
              this.generateChecksumPaytmPayment();
            }, 500);
          });
        });
      } else if (paymentMethod === "PayU") {
        this.setState({ isLoading: true }, () => {
          this.placeAnOrder().then(() => {
            this.setState({ isLoading: false });
            setTimeout(() => {
              this.initPayUMoneyPayment();
            }, 750);
          });
        });
      } else if (paymentMethod === "Paypal") {
        this.setState({ isLoading: true }, () => {
          this.placeAnOrder().then(() => {
            this.setState({ isLoading: false, isHidePlaceOrderButton: false });
            setTimeout(() => {
              this.initPaypalPayment();
            }, 750);
          });
        });
      } else if (paymentMethod === "GooglePay") {
        this.setState({ isLoading: true }, () => {
          this.placeAnOrder().then(() => {
            this.setState({ isLoading: false, isHidePlaceOrderButton: false });
            this.initGooglePayPayment();
          });
        });
      } else if (paymentMethod === "Sadad") {
        this.setState({ isLoading: true }, () => {
          this.placeAnOrder().then(() => {
            this.setState({ isLoading: false });
            this.payWithSadad();
          });
        });
      }
    }
  };

  renderLogoDynamic = async () => {
    const { masterDetailsObj } = this.state;
    let path = `${process.env.SERVER_URL}`;
    if (masterDetailsObj && masterDetailsObj.id) {
      const logoUrl = getMerchantInformationUrl(masterDetailsObj);
      const logoResponse = await getCall(logoUrl, []);
      if (logoResponse.error) {
        clearLocalStorageReload({ response: logoResponse });
        return;
      } else {
        path = `${path}/api/containers/profilepic-${
          masterDetailsObj.codename
        }/download/${logoResponse.profilepic}`;
        return path;
      }
    }
  };

  initUpdatePaymentStatusCOD = async () => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(cartList);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;
    let transactionNumber = "";

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: 1, // Pending
      order_id: orderId,
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${orderId}`);
      }, 1000);
    }
  };

  // RazorPay
  initRazorPayPayment = async () => {
    const {
      isMerchantGSTEnable,
      cartList,
      AppTitle,
      currentCatelogueType,
      paymentDetails,
      masterDetailsObj,
    } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    const logo = this.renderLogoDynamic(masterDetailsObj);
    var paymentAmount = 0;
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      paymentAmount = this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      );
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      paymentAmount = this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      );
    } else {
      paymentAmount = subTotal + this.getShippingCharges(subTotal);
    }

    if (paymentDetails && paymentDetails.length > 0 && paymentDetails[0]) {
      const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
        (paymentMethods) => {
          return paymentMethods.name === "RazorPay";
        }
      );
      const mId = paytmPaymentMethodDetails[0].config.key_id;
      const paymentResponse = await payment({
        key: mId,
        clientName: AppTitle,
        clientLogo: logo,
        amount: paymentAmount.toFixed(2),
        mobile: userDetails.cellnumber,
        email: userDetails.email,
        name: userDetails.username,
        currency: "INR",
        orderId: this.getOrderId(cartList),
      });
      if (paymentResponse.error) {
        this.loadErrorMessageAnimation(paymentResponse.error.message);
      } else {
        // TODO Add to language object
        this.loadSuccessMessageAnimation("Payment is successfully completed");
      }
      this.setState({ paymentResponse }, () => {
        this.updatePaymentStatusRazorPay();
      });
    }
  };

  // RazorPay
  updatePaymentStatusRazorPay = async () => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(cartList);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;
    let transactionNumber = "";
    if (paymentMethod === "RazorPay") {
      transactionNumber = paymentResponse.razorpay_payment_id;
    }
    const {
      error,
      razorpay_payment_id,
      transaction_status: transactionStatus,
    } = paymentResponse || {};
    const { transaction_status } = error || {};
    const paymentStatus = razorpay_payment_id
      ? transactionStatus
      : transaction_status;

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: transactionNumber,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      order_id: orderId,
    };

    this.setState({ isLoading: true });
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    this.setState({ isLoading: false });

    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${orderId}`);
      }, 1000);
    }
  };

  // PayTM
  generateChecksumPaytmPayment = async () => {
    const { cartList, paymentDetails } = this.state;
    const orderId = this.getOrderId(cartList);
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    const totalAmount = this.getGrandTotal(
      subTotal,
      this.getCGSTFromTotal(subTotal),
      this.getSGSTFromTotal(subTotal),
      this.getIGSTFromTotal(subTotal),
      this.getShippingCharges(subTotal)
    );

    const initPaytmPaymentUrl = "orders/genratepaytmchecksum";
    let initPaytmPaymentObj = {
      orderId: orderId,
      amount: totalAmount,
      callbackUrl: window.location.href,
    };
    this.setState({ isPlaceOrderButtonDisable: true }, async () => {
      this.setState({ isLoading: true });
      const initPaytmPaymentResponse = await postCall(
        initPaytmPaymentUrl,
        initPaytmPaymentObj
      );
      this.setState({ isLoading: false, isPlaceOrderButtonDisable: false });
      if (initPaytmPaymentResponse.error) {
        this.loadErrorMessageAnimation(initPaytmPaymentResponse.error.message);
        clearLocalStorageReload({ response: initPaytmPaymentResponse });
      }
      if (
        initPaytmPaymentResponse &&
        initPaytmPaymentResponse.body &&
        initPaytmPaymentResponse.body.txnToken
      ) {
        this.setState({ isPlaceOrderButtonDisable: false });
        if (paymentDetails && paymentDetails.length > 0 && paymentDetails[0]) {
          const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
            (paymentMethods) => {
              return paymentMethods.name === "Paytm";
            }
          );
          const mId = paytmPaymentMethodDetails[0].config.PaytmMerchantID;
          const logo = this.renderLogoDynamic();
          let obj = {
            mid: "" + mId,
            orderId: orderId,
            amount: this.getGrandTotal(
              subTotal,
              this.getCGSTFromTotal(subTotal),
              this.getSGSTFromTotal(subTotal),
              this.getIGSTFromTotal(subTotal),
              this.getShippingCharges(subTotal)
            ),
            token: initPaytmPaymentResponse.body.txnToken,
            merchantName:
              paytmPaymentMethodDetails[0].config.paymentMerchantName,
            logo: logo,
            callbackUrl: window.location.href,
          };
          const paytmC = this.appendHandler(paytmConfigure(obj));
          this.setState({ paytmConfig: paytmC }, () => {
            this.makePaytmPayment();
          });
        }
      } else if (
        initPaytmPaymentResponse &&
        initPaytmPaymentResponse.body &&
        initPaytmPaymentResponse.body.resultInfo
      ) {
        this.loadErrorMessageAnimation(
          initPaytmPaymentResponse.body.resultInfo.resultMsg
        );
      }
    });
  };

  // Paypal - 1
  initPaypalPayment = () => {
    const { cartList, paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Paypal";
      }
    );
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    let obj = {
      env: "production", // set this to prduction
      currency: "USD", // Paypal is not allowing user to pay in india
      total: this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      ),
      client: {
        sandbox: paytmPaymentMethodDetails[0].config.PaypalClientToken,
        production: paytmPaymentMethodDetails[0].config.PaypalClientToken,
      },
    };
    this.setState({ payPalConfig: obj }, () => {});
  };

  // Paypal - 2
  onSuccessOfPaypalPayment = (payment) => {
    // Congratulation, it came here means everything's fine!
    console.log("The payment was succeeded!", payment);
    if (payment.paid) {
      // TODO Add to language object
      this.loadSuccessMessageAnimation("Payment is successfully completed");
      this.setState({ paymentResponse: payment }, () => {
        this.updatePaymentStatusPayPal(2);
      });
    } else {
      // TODO Add to language object
      this.loadErrorMessageAnimation("Payment failed");
      this.setState({ paymentResponse: payment }, () => {
        this.updatePaymentStatusPayPal(3);
      });
    }
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  // Paypal - 3
  onCancelOfPaypalPayment = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    // TODO Add to language object
    this.loadErrorMessageAnimation("Payment failed");
    this.setState({ paymentResponse: data }, () => {
      this.updatePaymentStatusPayPal(3);
    });
  };

  // Paypal - 4
  onErrorOfPaypalPayment = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    // TODO Add to language object
    this.loadErrorMessageAnimation("Payment failed");
    this.setState({ paymentResponse: err }, () => {
      this.updatePaymentStatusPayPal(3);
    });
  };

  // Paypal - 5
  updatePaymentStatusPayPal = async (paymentStatus) => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(cartList);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;
    const { paymentID, payerID } = paymentResponse;

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: paymentID,
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      orderId: payerID,
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${orderId}`);
      }, 1000);
    }
  };

  // PayU Money - 1
  initPayUMoneyPayment = async () => {
    try {
      const { cartList, paymentDetails } = this.state;
      // Get User Details From Local Storage
      const userDetails = loadUserDetailsFromLocalStorage();
      const { billingaddress } = userDetails;
      const billingaddressObj = billingaddress
        ? JSON.parse(billingaddress)
        : {};
      const { name, email, cellnumber } = billingaddressObj;
      const orderId = this.getOrderId(cartList);

      // Get Selected Payment Details from payment details config
      const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
        (paymentMethods) => {
          return paymentMethods.name === "PayU";
        }
      );

      // Merchant Key
      const key = paytmPaymentMethodDetails[0].config.PayUMerchantKey;
      const subTotal = this.getSubTotalFromTotalandServiceCharges();
      // Total Amount
      const totalAmount = this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      );

      // Product Details
      const productInfo = this.getProductIds(cartList);

      // Row Object for checkou script
      var rowObject = {
        key: key,
        txnid: orderId,
        amount: totalAmount,
        firstname: name,
        email,
        phone: cellnumber,
        productinfo: productInfo,
        surl: `/${process.env.PUBLIC_URL}/checkout`,
        furl: `/${process.env.PUBLIC_URL}/checkout`,
        hash: "",
      };

      const { txnid, amount, productinfo, firstname } = rowObject;

      // Generate Checksum Object
      let data = {
        txnId: txnid,
        email,
        amount,
        productinfo: productinfo,
        firstname: firstname,
      };

      this.setState({ isLoading: true });
      const payUMoneyHash = await postCall("orders/genratepayumoneyhash", data);
      this.setState({ isLoading: false });
      if (payUMoneyHash && payUMoneyHash.error) {
        clearLocalStorageReload({ response: payUMoneyHash });
        this.loadErrorMessageAnimation(payUMoneyHash.error.message);
        if (payUMoneyHash.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
        // Update Payment Status if any error and redirect user to order summary page
        setTimeout(() => {
          this.setState({ isLoading: true });
          this.updatePaymentStatusPayUMoney(3).then(() => {
            this.setState({ isLoading: false });
          });
        }, 750);
      } else {
        rowObject.hash = payUMoneyHash.hash;
        this.setState({ payUMoneyConfig: rowObject }, () => {
          this.loadPayUMoneyBoltCheckout();
        });
      }
    } catch (error) {
      // Update Payment Status if any error and redirect user to order summary page
      this.setState({ isLoading: true });
      this.updatePaymentStatusPayUMoney(3).then(() => {
        this.setState({ isLoading: false });
      });
    }
  };

  // PayU Money - 2
  loadPayUMoneyBoltCheckout = () => {
    const { payUMoneyConfig } = this.state;

    window.bolt.launch(payUMoneyConfig, {
      responseHandler: (response) => {
        // console.log("response");
        // console.log(response);
        // your payment response Code goes here
        if (response.response.status === "success") {
          this.setState(
            {
              paymentResponse: response,
              isLoading: true,
            },
            () => {
              this.updatePaymentStatusPayUMoney(2).then(() => {
                this.setState({ isLoading: false });
              });
            }
          );
        } else if (response.response.txnStatus === "CANCEL") {
          this.loadErrorMessageAnimation(response.response.txnMessage);
          setTimeout(() => {
            this.setState({ isLoading: true });
            this.updatePaymentStatusPayUMoney(3).then(() => {
              this.setState({ isLoading: false });
            });
          }, 750);
        }
      },
      catchException: (error) => {
        console.log("error");
        console.log(error);
        this.setState({ isLoading: true });
        this.updatePaymentStatusPayUMoney(3).then(() => {
          this.setState({ isLoading: false });
        });
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
      },
    });
  };

  // PayU Money - 3
  updatePaymentStatusPayUMoney = async (paymentStatus) => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(cartList);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;

    const { response } = paymentResponse || {};
    const { encryptedPaymentId, txnid } = response || {};

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: encryptedPaymentId,
        other_transaction_details: response,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      order_id: txnid,
    };

    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );

    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      // TODO Add to language object
      this.loadSuccessMessageAnimation("Payment is successfully completed");
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${orderId}`);
      }, 1000);
    }
  };

  // Google Pay - 1
  initGooglePayPayment = () => {
    const { cartList, paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "GooglePay";
      }
    );
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    let obj = {
      merchantId: paytmPaymentMethodDetails[0].config.MERCHANT_ID,
      merchantName: paytmPaymentMethodDetails[0].config.MERCHANT_NAME,
      amount: this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      ),
      gateway: "example",
    };

    const gPayConfig = GooglePayConfigure(obj);
    this.setState({ googlePayConfig: gPayConfig });
  };

  // Google Pay - 2
  makeGooglePayPayment = (payment) => {
    // TODO Add to language object
    this.loadSuccessMessageAnimation("Payment is successfully completed");
    this.setState({ paymentResponse: payment }, () => {
      this.updatePaymentStatusGooglePay(2);
    });
  };

  // Google Pay - 3
  updatePaymentStatusGooglePay = async (paymentStatus) => {
    const { cartList, paymentResponse, paymentMethod } = this.state;
    const orderId = this.getOrderId(cartList);
    const updatePaymentTransactionIdUrl = `orders/${orderId}`;

    const { paymentMethodData } = paymentResponse || {};
    const { tokenizationData } = paymentMethodData || {};
    const { token } = tokenizationData || {};

    const updatePaymentTransactionIdObj = {
      paymentDetail: {
        transaction_no: "",
        other_transaction_details: paymentResponse,
        pay_via: paymentMethod,
      },
      paymentstatus: paymentStatus,
      orderId: orderId,
    };
    const updatePaymentTransactionId = await patchCall(
      updatePaymentTransactionIdUrl,
      updatePaymentTransactionIdObj
    );
    if (updatePaymentTransactionId.error) {
      clearLocalStorageReload({ response: updatePaymentTransactionId });
      this.loadErrorMessageAnimation(updatePaymentTransactionId.error.message);
      if (updatePaymentTransactionId.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    }
    setTimeout(() => {
      this.props.history.push(`/orderdetails/${orderId}`);
    }, 1000);
  };

  // Sadad
  payWithSadad = async () => {
    const { cartList, paymentDetails } = this.state;
    const {
      id: userId,
      email: userEmail,
      cellnumber: userCellnumber,
    } = loadUserDetailsFromLocalStorage();
    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Sadad";
      }
    );

    const merchantId = paytmPaymentMethodDetails[0].config.MERCHANT_ID;
    const secretKey = paytmPaymentMethodDetails[0].config.MERCHANT_KEY;
    const orderId = this.getOrderId(cartList);
    const totalProductItems = this.getTotalProductItems(cartList);
    const sadadPageLanguage = getCurrentLanguage();
    const currentUrl = window.location.href;
    const callbackPageUrl = currentUrl.substring(
      0,
      currentUrl.lastIndexOf("/")
    );
    const sadadCallbackUrl = process.env.SADAD_CALLBACK_URL;
    const sadadPaymentWebsite = process.env.SADAD_WEBSITE_URL;
    const transactionDate = getCurrentDate();
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    const totalAmount = this.getGrandTotal(
      subTotal,
      this.getCGSTFromTotal(subTotal),
      this.getSGSTFromTotal(subTotal),
      this.getIGSTFromTotal(subTotal),
      this.getShippingCharges(subTotal)
    );
    const productionDetails = [
      {
        order_id: orderId,
        itemname: "Product",
        amount: totalAmount.toFixed(2),
        quantity: totalProductItems.toString(),
        // type: "line_item",
      },
    ];
    const sadadCheckoutVersion = process.env.SADAD_CHECKOUT_VERSION;

    const checksumObj = sadadPaymentConfigure({
      merchantId,
      orderId,
      sadadPaymentWebsite,
      totalAmount: totalAmount.toFixed(2),
      userId,
      userEmail,
      userCellnumber,
      sadadPageLanguage,
      sadadCallbackUrl,
      transactionDate,
      productionDetails,
      sadadCheckoutVersion,
    });

    const sadadPaymentChecksumUrl = getSadadPaymentChecksumUrl();
    const checksumResponse = await customPostCall(
      sadadPaymentChecksumUrl,
      checksumObj,
      secretKey
    );
    this.setState({ sadadPaymentConfig: checksumObj }, () => {
      this.setState({ sadadPaymentChecksum: checksumResponse.checksum }, () => {
        this.setState({ isOpenPayWithSadad: true }, () => {
          const paymentForm = document.getElementById("paymentform");
          // console.log("paymentForm");
          // console.log(paymentForm);
          paymentForm.submit();
        });
      });
    });
  };

  initBillingShippingAddressConfig = async () => {
    const { masterDetailsObj } = this.state;
    const billingShippingAddressUrl = getAddressDetailUrl(masterDetailsObj);

    const billingShippingAddressResponse = await getCall(
      billingShippingAddressUrl,
      []
    );

    if (billingShippingAddressResponse.error) {
      clearLocalStorageReload({ response: billingShippingAddressResponse });
    } else {
      if (billingShippingAddressResponse.length > 0) {
        const billingShippingAddress = JSON.parse(
          billingShippingAddressResponse[0].text
        );
        this.setState({ billingShippingAddress });
      }
    }
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };

  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;

      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;

      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }

    return grandTotal;
  };

  getShippingCharges = (total) => {
    const { shippingCharges, isPincodeDeliveryEnable } = this.state;
    var charge = 0;
    if (isPincodeDeliveryEnable) {
      return this.getShippingPriceFromCart();
    }
    if (shippingCharges.length > 0) {
      for (let index = 0; index < shippingCharges.length; index++) {
        if (
          shippingCharges[index].id === 3 &&
          shippingCharges[index].status === 1
        ) {
          for (
            let jindex = 0;
            jindex < shippingCharges[index].options.length;
            jindex++
          ) {
            if (
              !shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total &&
              shippingCharges[index].options[jindex].maxValue >= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
            if (
              shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
          }
        }
      }
    }
    return charge;
  };

  placeAnInquiry = async () => {
    const placeAninquiryUrl = "orders/placeOrder";
    var placeAnInquiryObj = {};
    const { cartList } = this.state;
    const user = loadUserDetailsFromLocalStorage();
    if (user === undefined) {
      removeUserDetailAndOtherDetails();
      navigateTo(this.props, `/login`);
      return;
    }
    const { id } = user;
    placeAnInquiryObj = {
      orderId: this.getOrderId(cartList),
      userId: id,
      inshoppingcart: 2,
    };
    const placeInquiry = await patchCall(placeAninquiryUrl, placeAnInquiryObj);
    if (placeInquiry.error) {
      clearLocalStorageReload({ response: placeInquiry });
      this.loadErrorMessageAnimation(placeInquiry.error.message);
      if (placeInquiry.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_your_inquiry_placed_successfully")
          ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
          : "Your inquiry placed successfully"
      );
      setTimeout(() => {
        this.props.history.push(`/orderdetails/${this.getOrderId(cartList)}`);
      }, 750);
    }
  };

  placeAnOrder = async () => {
    const { isInquiry } = this.props;
    const {
      isMerchantGSTEnable,
      merchantInfo,
      userDetails,
      serviceCharges,
    } = this.state;
    const shippingAddress = JSON.parse(userDetails.shippingaddress);
    const billingAddress = JSON.parse(userDetails.billingaddress);
    const serviceChargeFirst = JSON.parse(serviceCharges.text);
    const additionalchargedetailsArray = serviceChargeFirst;
    const [labelObj] = additionalchargedetailsArray.filter(
      (sc) => sc.key === "label"
    );
    const [isPercentageObj] = additionalchargedetailsArray.filter(
      (sc) => sc.key === "isPercentage"
    );
    const [percentageObj] = additionalchargedetailsArray.filter(
      (sc) => sc.key === "percentage"
    );
    const [amountObj] = additionalchargedetailsArray.filter(
      (sc) => sc.key === "amount"
    );

    const additionalchargedetailsObj = {
      label: labelObj.value,
      type: isPercentageObj.value ? "isPercentage" : "isAmount",
      typevalue: isPercentageObj.value ? percentageObj.value : amountObj.value,
      charges: this.getServiceChargesBasedOnTotalAmount(),
    };

    const user = loadUserDetailsFromLocalStorage();
    if (user === undefined) {
      removeUserDetailAndOtherDetails();
      navigateTo(this.props, `/login`);
      return;
    }

    const { id } = user;
    const { cartList, currentCatelogueType } = this.state;
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    const totalAmount = this.getGrandTotal(
      subTotal,
      this.getCGSTFromTotal(subTotal),
      this.getSGSTFromTotal(subTotal),
      this.getIGSTFromTotal(subTotal),
      this.getShippingCharges(subTotal)
    );
    const placeOrderUrl = "orders/placeOrder";
    var placeOrderObj = {};

    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      placeOrderObj = {
        orderId: this.getOrderId(cartList),
        totalamount: totalAmount,
        userId: id,
        inshoppingcart: 0,
        description: this.state.orderNotes,
        shippingaddress: shippingAddress,
        billingaddress: billingAddress,
        additionalcharge: this.getServiceChargesBasedOnTotalAmount(),
        additionalchargedetails: additionalchargedetailsObj,
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice: this.getCGSTFromTotal(subTotal),
          sgstPrice: this.getSGSTFromTotal(subTotal),
        },
        baseprice: subTotal,
        shippingprice: this.getShippingCharges(subTotal),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      placeOrderObj = {
        orderId: this.getOrderId(cartList),
        totalamount: totalAmount,
        userId: id,
        inshoppingcart: 0,
        description: this.state.orderNotes,
        shippingaddress: shippingAddress,
        billingaddress: billingAddress,
        additionalcharge: this.getServiceChargesBasedOnTotalAmount(),
        additionalchargedetails: additionalchargedetailsObj,
        baseprice: subTotal,
        shippingprice: this.getShippingCharges(subTotal),
      };
    } else {
      placeOrderObj = {
        orderId: this.getOrderId(cartList),
        totalamount: totalAmount,
        userId: id,
        inshoppingcart: 0,
        description: this.state.orderNotes,
        shippingaddress: shippingAddress,
        billingaddress: billingAddress,
        additionalcharge: this.getServiceChargesBasedOnTotalAmount(),
        additionalchargedetails: additionalchargedetailsObj,
        shippingprice: this.getShippingCharges(subTotal),
      };
    }

    const placeOrder = await patchCall(placeOrderUrl, placeOrderObj);
    if (placeOrder.error) {
      clearLocalStorageReload({ response: placeOrder });
      this.loadErrorMessageAnimation(placeOrder.error.message);
      if (placeOrder.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            : "Your inquiry placed successfully"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_order_placed_successfully")
            ? getValueFromLanguageObject("str_your_order_placed_successfully")
            : "Your order placed successfully"
        );
      }
    }
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList[0].orderdetail.length; i++) {
        total =
          total +
          cartList[0].orderdetail[i].amount *
            cartList[0].orderdetail[i].quantity;
      }
    return total;
  }

  getSubTotalFromTotalandServiceCharges = () => {
    const { cartList } = this.state || {};
    let subTotal = 0;
    const total = this.getTotalofCart(cartList);
    const serviceChargeAmount = this.getServiceChargesBasedOnTotalAmount();
    subTotal = total + serviceChargeAmount;
    return subTotal;
  };

  getShippingPriceFromCart() {
    const { cartList } = this.state;
    let total = 0;
    if (cartList.length > 0)
      for (let i = 0; i < cartList.length; i++) {
        total = cartList[i].shippingprice;
      }
    return total;
  }

  getProductIds = (cartList) => {
    let productIds = "";
    if (cartList.length > 0)
      for (let i = 0; i < cartList[0].orderdetail.length; i++) {
        productIds = productIds.concat(
          `, ${cartList[0].orderdetail[i].productId}`
        );
      }
    return productIds;
  };

  getOrderId(cartList) {
    let orderId = "";
    if (cartList.length > 0) {
      orderId = cartList[0].id;
    }

    return orderId;
  }

  getTotalProductItems = () => {
    let totalProductItems = "";
    const { cartList } = this.state;
    if (cartList.length > 0) {
      const [product] = cartList;
      const { totalitems = "" } = product || {};
      totalProductItems = totalitems;
    }
    return totalProductItems;
  };

  onChangeOfPaymentMethod = (paymentMethod) => {
    if (paymentMethod === "Cash On Delivery") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }
    if (paymentMethod === "Paytm") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }
    if (paymentMethod === "RazorPay") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }

    if (paymentMethod === "PayU") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }

    if (paymentMethod === "GooglePay") {
      this.setState({ paymentMethod });
    }

    if (paymentMethod === "Paypal") {
      this.setState({ paymentMethod });
    }

    if (paymentMethod === "Sadad") {
      this.setState({ paymentMethod, isPlaceOrderButtonDisable: false });
    }
  };

  appendHandler(config) {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler,
    };

    return newConfig;
  }

  notifyMerchantHandler = (eventType, data) => {
    console.log("MERCHANT NOTIFY LOG", data);
    if (
      data &&
      data.body &&
      data.body.content &&
      data.body.content.STATUS === "TXN_FAILURE"
    ) {
      this.loadErrorMessageAnimation(data.body.content.RESPMSG);
    }
    if (
      data &&
      data.body &&
      data.body.resultInfo &&
      data.body.resultInfo.resultStatus === "F" &&
      data.body.resultInfo.resultCode === "1006"
    ) {
      this.loadErrorMessageAnimation(data.body.resultInfo.resultMsg);
    }
    if (
      data &&
      data.body &&
      data.body.resultInfo &&
      data.body.resultInfo.resultStatus === "F" &&
      data.body.resultInfo.resultCode !== "1006"
    ) {
      this.loadErrorMessageAnimation(data.body.resultInfo.resultMsg);
    }
  };

  // PaytM
  loadCheckoutScript = () => {
    const { paymentDetails } = this.state;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Paytm";
      }
    );
    const mId = paytmPaymentMethodDetails[0].config.PaytmMerchantID;

    // Development URL
    // const url =
    //   "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/";

    // Production URL
    const url = "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/";

    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = url.concat(mId);
    scriptElement.type = "application/javascript";
    scriptElement.onload = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.setState({
            checkoutJsInstance,
          });
        });
      } else {
        console.error(SSR_SIMULATE + "onload not available!");
      }
    };
    scriptElement.onerror = (error) => {
      console.error("SSR Simulate: script load fail!");
    };
    document.body.appendChild(scriptElement);
    this.setState({ isScriptLoading: true });
  };

  getCheckoutJsObj() {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    } else {
      console.error(SSR_SIMULATE + "Checkout instance not found!");
    }

    return null;
  }

  renderServiceChargesLable = (serviceCharges) => {
    const { status, text = "" } = serviceCharges || {};
    if (status === 1) {
      const serviceChargeList = JSON.parse(text) || {};
      if (serviceChargeList) {
        const [labelObj] = serviceChargeList.filter((sc) => sc.key === "label");
        return labelObj.value;
      }
    }
    return "";
  };

  getServiceChargesBasedOnTotalAmount = () => {
    const { cartList, serviceCharges } = this.state || [];
    const { status, text } = serviceCharges || {};
    let serviceChargesAmount = 0;

    if (status === 1) {
      const serviceChargeList = JSON.parse(text) || {};
      if (serviceChargeList) {
        const [isPercentageObj] = serviceChargeList.filter(
          (sc) => sc.key === "isPercentage"
        );
        const [percentageObj] = serviceChargeList.filter(
          (sc) => sc.key === "percentage"
        );
        const [amountObj] = serviceChargeList.filter(
          (sc) => sc.key === "amount"
        );
        if (isPercentageObj && isPercentageObj.value) {
          const totalAmount = this.getTotalofCart(cartList);
          serviceChargesAmount = (totalAmount * percentageObj.value) / 100;
        }
        if (isPercentageObj && !isPercentageObj.value) {
          serviceChargesAmount = parseFloat(amountObj.value);
        }
      }
    }
    return serviceChargesAmount;
  };

  rednerBillingAndShippingAddress = (billingaddressObj, shippingaddressObj) => {
    const { billingShippingAddress } = this.state;
    const {
      name = "",
      mobile = "",
      email = "",
      companyname = "",
      gstin = "",
      address1 = "",
      address2 = "",
      zonenumber = "",
      streetnumber = "",
      buildingnumber = "",
      unitnumber = "",
      landmark = "",
      cityname = "",
      statename = "",
      countryname = "",
      zipcode = "",
    } = billingaddressObj || {};

    const {
      name: shippingAddressName = "",
      mobile: shippingAddressMobile = "",
      email: shippingAddressEmail = "",
      companyname: shippingAddressCompanyname = "",
      gstin: shippingAddressGSTIN = "",
      address1: shippingAddressAddress1 = "",
      address2: shippingAddressAddress2 = "",
      zonenumber: shippingAddressZonenumber = "",
      streetnumber: shippingAddressStreetnumber = "",
      buildingnumber: shippingAddressBuildingnumber = "",
      unitnumber: shippingAddressUnitnumber = "",
      landmark: shippingAddressLandmark = "",
      cityname: shippingAddressCityname = "",
      statename: shippingAddressStatename = "",
      countryname: shippingAddressCountryname = "",
      zipcode: shippingAddressZipcode = "",
    } = shippingaddressObj || {};

    const strYourOrderDeliveredToBelowAddress = getValueFromLanguageObject(
      "str_your_order_delivered_to_below_address"
    )
      ? getValueFromLanguageObject("str_your_order_delivered_to_below_address")
      : "Your Order delivered to below address";

    const strBillingDetails = getValueFromLanguageObject("billing_details")
      ? getValueFromLanguageObject("billing_details")
      : "Billing Details";

    const strEmailAdrreses = getValueFromLanguageObject("str_email_address")
      ? getValueFromLanguageObject("str_email_address")
      : "Email Address";

    const strCompanyName = getValueFromLanguageObject("str_company_name")
      ? getValueFromLanguageObject("str_company_name")
      : "Company Name";

    const strGSTIN = getValueFromLanguageObject("str_gstin")
      ? getValueFromLanguageObject("str_gstin")
      : "GSTIN";

    const strAddress = getValueFromLanguageObject("str_address")
      ? getValueFromLanguageObject("str_address")
      : "Address";

    const strShippingDetails = getValueFromLanguageObject("shipping_details")
      ? getValueFromLanguageObject("shipping_details")
      : "Shipping Details";

    return (
      <>
        <div className="row">
          <div className="checkout-title col-md-12 mb-0">
            <h5>
              {strYourOrderDeliveredToBelowAddress}
              <i
                className="fa fa-pencil ml-2"
                onClick={() => {
                  this.setState({
                    isEditBillingDetailModalVisible: true,
                  });
                }}
              />
            </h5>
          </div>
        </div>
        <div className="checkout-box">
          <div className="check-out">
            <div className="checkout-title col-md-12">
              <h3>{strBillingDetails}</h3>
            </div>
            <div className="col-12 f22 mt-2">
              <ul className="order-detail">
                <li className="mb-2 d-block" style={{ fontWeight: 550 }}>
                  {billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[0].visible === 1 &&
                    name}{" "}
                  -{" "}
                  {billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[1].visible === 1 &&
                    mobile}
                </li>

                <li className="mb-2 d-block">
                  {email &&
                    billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[2].visible === 1 && (
                      <>
                        {strEmailAdrreses}: {email}
                      </>
                    )}
                </li>
                <li className="mb-2 d-block">
                  {companyname &&
                    billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[3].visible === 1 && (
                      <>
                        {strCompanyName} : {companyname}
                      </>
                    )}
                </li>
                <li className="mb-2 d-block">
                  {gstin &&
                    billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[4].visible === 1 && (
                      <>
                        {strGSTIN} : {gstin}
                      </>
                    )}
                </li>

                {billingShippingAddress &&
                  billingShippingAddress.length > 0 &&
                  billingShippingAddress[5].visible === 1 && (
                    <li className="mb-2 d-block">
                      {strAddress} : {address1 && address1 + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[6].visible === 1 &&
                        address2 &&
                        address2 + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[12].visible === 1 &&
                        zonenumber &&
                        zonenumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[13].visible === 1 &&
                        streetnumber &&
                        streetnumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[14].visible === 1 &&
                        buildingnumber &&
                        buildingnumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[15].visible === 1 &&
                        unitnumber &&
                        unitnumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[7].visible === 1 &&
                        landmark &&
                        landmark + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[11].visible === 1 &&
                        cityname &&
                        cityname + ","}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[10].visible === 1 &&
                        statename &&
                        statename + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[9].visible === 1 &&
                        countryname &&
                        countryname + " - "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[8].visible === 1 &&
                        zipcode}
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>

        <div className="checkout-box">
          <div className="check-out">
            <div className="checkout-title col-md-12">
              <h3>{strShippingDetails}</h3>
            </div>
            <div className="col-12 f22 mt-2">
              <ul className="order-detail">
                <li className="mb-2 d-block" style={{ fontWeight: 550 }}>
                  {billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[0].visible === 1 &&
                    shippingAddressName &&
                    shippingAddressName + " - "}

                  {billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[1].visible === 1 &&
                    shippingAddressMobile &&
                    shippingAddressMobile}
                </li>
                <li className="mb-2 d-block">
                  {shippingAddressEmail &&
                    billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[2].visible === 1 && (
                      <>
                        {strEmailAdrreses}: {shippingAddressEmail}
                      </>
                    )}
                </li>
                <li className="mb-2 d-block">
                  {shippingAddressCompanyname &&
                    billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[3].visible === 1 &&
                    shippingAddressCompanyname && (
                      <>
                        {strCompanyName}: {shippingAddressCompanyname}
                      </>
                    )}
                </li>
                <li className="mb-2 d-block">
                  {shippingAddressGSTIN &&
                    billingShippingAddress &&
                    billingShippingAddress.length > 0 &&
                    billingShippingAddress[4].visible === 1 && (
                      <>
                        {strGSTIN}: {shippingAddressGSTIN}
                      </>
                    )}
                </li>

                {billingShippingAddress &&
                  billingShippingAddress.length > 0 &&
                  billingShippingAddress[5].visible === 1 && (
                    <li className="mb-2 d-block">
                      {strAddress}: {shippingAddressAddress1},
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[6].visible === 1 &&
                        shippingAddressAddress2 &&
                        shippingAddressAddress2 + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[12].visible === 1 &&
                        shippingAddressZonenumber &&
                        shippingAddressZonenumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[13].visible === 1 &&
                        shippingAddressStreetnumber &&
                        shippingAddressStreetnumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[14].visible === 1 &&
                        shippingAddressBuildingnumber &&
                        shippingAddressBuildingnumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[12].visible === 1 &&
                        shippingAddressUnitnumber &&
                        shippingAddressUnitnumber + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[7].visible === 1 &&
                        shippingAddressLandmark &&
                        shippingAddressLandmark + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[11].visible === 1 &&
                        shippingAddressCityname &&
                        shippingAddressCityname + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[10].visible === 1 &&
                        shippingAddressStatename &&
                        shippingAddressStatename + ", "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[9].visible === 1 &&
                        shippingAddressCountryname &&
                        shippingAddressCountryname + " - "}
                      {billingShippingAddress &&
                        billingShippingAddress.length > 0 &&
                        billingShippingAddress[8].visible === 1 &&
                        shippingAddressZipcode}
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderNoBillingAddress = () => {
    const { isInquiry } = this.props;
    if (isInquiry) {
      return <div />;
    }
    return (
      <div>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  {!isInquiry && (
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icon/address.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <div>
                        <h3>
                          <strong>
                            {getValueFromLanguageObject("str_no_address_found")
                              ? getValueFromLanguageObject(
                                  "str_no_address_found"
                                )
                              : "No Billing/Shipping Address Found in your account."}
                          </strong>
                        </h3>
                        <h4>
                          {getValueFromLanguageObject(
                            "str_add_billing_addresss"
                          )
                            ? getValueFromLanguageObject(
                                "str_add_billing_addresss"
                              )
                            : "Add Billing/Shipping Address."}
                        </h4>
                      </div>

                      <button
                        type="button"
                        className="btn-solid btn mobile-btn-block mt-2"
                        onClick={() => {
                          this.setState({
                            isAddBillingDetailModalVisible: true,
                          });
                        }}
                      >
                        {getValueFromLanguageObject("add_address")
                          ? getValueFromLanguageObject("add_address")
                          : "Add Address"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const { symbol, isInquiry } = this.props;
    const {
      cartList,
      // countryList,
      // stateList,
      // cityList,
      // shippingCountryList,
      // shippingStateList,
      // shippingCityList,
      isLoading,
      isMerchantGSTEnable,
      paymentDetails,
      paymentMethod,
      isPaymentDone,
      orderViaWhatsapp,
      AppTitle,
      currentCatelogueType,
      userDetails,
      isAddBillingDetailModalVisible,
      isEditBillingDetailModalVisible,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      sadadPaymentConfig,
      isOpenPayWithSadad,
      sadadPaymentChecksum,
      isHidePlaceOrderButton,
      serviceCharges,
    } = this.state;

    const { billingaddress, shippingaddress } = userDetails;
    const billingaddressObj = billingaddress
      ? JSON.parse(billingaddress)
      : null;
    const shippingaddressObj = shippingaddress
      ? JSON.parse(shippingaddress)
      : null;

    const subTotal = this.getSubTotalFromTotalandServiceCharges();

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {AppTitle} |{" "}
            {getValueFromLanguageObject("str_checkout_page")
              ? getValueFromLanguageObject("str_checkout_page")
              : "CheckOut Page"}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb
          title={
            getValueFromLanguageObject("checkout")
              ? getValueFromLanguageObject("checkout")
              : "CheckOut"
          }
        />

        {isAddBillingDetailModalVisible && (
          <AddBillingAddressModal
            isAddBillingDetailModalVisible={isAddBillingDetailModalVisible}
            onCloseModalAddBillingDetail={() => {
              this.setState({ isAddBillingDetailModalVisible: false }, () => {
                this.initUserDetails();
              });
            }}
          />
        )}

        {isEditBillingDetailModalVisible && (
          <EditBillingAddressModal
            isEditBillingDetailModalVisible={isEditBillingDetailModalVisible}
            onCloseModalEditBillingDetail={() => {
              this.setState({ isEditBillingDetailModalVisible: false }, () => {
                this.initUserDetails();
              });
            }}
          />
        )}

        <section className="section-b-space">
          <div className="container">
            <div className="checkout-page">
              <div className="checkout-form">
                <form>
                  <div className="checkout row justify-content-center">
                    {!isInquiry && (
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        <div className="row">
                          <div className="checkout-title col-md-12 mb-0">
                            <h5>
                              {getValueFromLanguageObject("str_order_notes")
                                ? getValueFromLanguageObject("str_order_notes")
                                : "Order Notes"}
                            </h5>
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <input
                              type="text"
                              name="orderNotes"
                              value={this.state.orderNotes}
                              onChange={this.onChangeOfOrderNotes}
                              placeholder={
                                getValueFromLanguageObject(
                                  "str_enter_order_notes"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_enter_order_notes"
                                    )
                                  : "Enter Order Notes"
                              }
                            />
                            {/* {this.validator.message(
                            "orderNotes",
                            this.state.orderNotes,
                            ""
                          )} */}
                          </div>
                        </div>

                        {billingaddressObj &&
                          shippingaddressObj &&
                          this.rednerBillingAndShippingAddress(
                            billingaddressObj,
                            shippingaddressObj
                          )}

                        {!billingaddressObj &&
                          !shippingaddressObj &&
                          this.renderNoBillingAddress()}
                      </div>
                    )}

                    {isInquiry && (
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        <div className="checkout-details">
                          <div className="order-box">
                            <div className="title-box">
                              <div>
                                {getValueFromLanguageObject("str_product")
                                  ? getValueFromLanguageObject("str_product")
                                  : "Product"}
                              </div>
                            </div>
                            <ul className="qty row">
                              {cartList.length > 0 &&
                                cartList[0].orderdetail.map((d, index) => {
                                  return (
                                    <li key={index} className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-9">
                                          <p>
                                            {d.products.name} {d.products.name}
                                          </p>
                                        </div>
                                        <div className="col-md-3">
                                          <p>Qty : {d.quantity}</p>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="text-right">
                            {orderViaWhatsapp && orderViaWhatsapp.status === 1 && (
                              <button
                                type="button"
                                className="btn-solid-wp btn mobile-btn-block"
                                onClick={() => this.onClickOfOrderViaWhatsApp()}
                              >
                                {getValueFromLanguageObject(
                                  "str_inquiry_via_whatsapp"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_inquiry_via_whatsapp"
                                    )
                                  : "Inquiry Via WhatsApp"}
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn-solid btn mobile-btn-block"
                              onClick={() => this.placeAnInquiry()}
                            >
                              {getValueFromLanguageObject("str_place_inquiry")
                                ? getValueFromLanguageObject(
                                    "str_place_inquiry"
                                  )
                                : "Place Inquiry"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isInquiry && (
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        {/* checkout details */}

                        <div className="checkout-details">
                          <div className="order-box">
                            <div className="title-box">
                              <div>
                                {getValueFromLanguageObject("str_product")
                                  ? getValueFromLanguageObject("str_product")
                                  : "Product"}{" "}
                                <span>
                                  {" "}
                                  {getValueFromLanguageObject("str_total")
                                    ? getValueFromLanguageObject("str_total")
                                    : "Total"}
                                </span>
                              </div>
                            </div>
                            <ul className="qty">
                              {cartList.length > 0 &&
                                cartList[0].orderdetail.map((d, index) => {
                                  return (
                                    <li key={index}>
                                      {`${
                                        d.products.name
                                      } (${symbol}${parseFloat(
                                        d.amount
                                      ).toFixed(2)} × ${d.quantity}) `}
                                      <span>
                                        {`${symbol}${parseFloat(
                                          d.quantity * d.amount
                                        ).toFixed(2)}`}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                            <ul className="sub-total">
                              <li>
                                {getValueFromLanguageObject("str_total")
                                  ? getValueFromLanguageObject("str_total")
                                  : "Total"}
                                <span className="count">
                                  {`${symbol}${parseFloat(
                                    this.getTotalofCart(cartList)
                                  ).toFixed(2)}`}
                                </span>
                              </li>
                              {serviceCharges && serviceCharges.status === 1 && (
                                <li>
                                  {`+ ${this.renderServiceChargesLable(
                                    serviceCharges
                                  )}`}
                                  <span className="count">
                                    {`${symbol}${parseFloat(
                                      this.getServiceChargesBasedOnTotalAmount()
                                    ).toFixed(2)}`}
                                  </span>
                                </li>
                              )}

                              <li>
                                {"+ "}
                                {getValueFromLanguageObject("shipping_charges")
                                  ? getValueFromLanguageObject(
                                      "shipping_charges"
                                    )
                                  : "Shipping Charges"}
                                <span className="count">
                                  {`${symbol}${parseFloat(
                                    this.getShippingCharges(subTotal)
                                  ).toFixed(2)}`}
                                </span>
                              </li>
                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname ===
                                  "India" && (
                                  <li>
                                    {"+ "}
                                    {getValueFromLanguageObject("str_cgst")
                                      ? getValueFromLanguageObject("str_cgst")
                                      : "CGST"}
                                    {"("}
                                    {this.state.merchantInfo.CGST}
                                    {"%)"}
                                    <span className="count">
                                      {`${symbol}${parseFloat(
                                        this.getCGSTFromTotal(subTotal)
                                      ).toFixed(2)}`}
                                    </span>
                                  </li>
                                )}
                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname ===
                                  "India" && (
                                  <li>
                                    {"+ "}{" "}
                                    {getValueFromLanguageObject("str_sgst")
                                      ? getValueFromLanguageObject("str_sgst")
                                      : "SGST"}{" "}
                                    {"("}
                                    {this.state.merchantInfo.SGST}
                                    {"%)"}
                                    <span className="count">
                                      {`${symbol}${parseFloat(
                                        this.getSGSTFromTotal(subTotal)
                                      ).toFixed(2)}`}
                                    </span>
                                  </li>
                                )}

                              {isMerchantGSTEnable &&
                                currentCatelogueType &&
                                currentCatelogueType.registerallow !==
                                  "Catalogue_Jewellary" &&
                                this.state.merchantInfo.countryname !==
                                  "India" && (
                                  <li>
                                    {"+ "}
                                    {getValueFromLanguageObject("str_igst")
                                      ? getValueFromLanguageObject("str_igst")
                                      : "IGST"}
                                    {"("}
                                    {this.state.merchantInfo.IGST}
                                    {"%)"}
                                    <span className="count">
                                      {`${symbol}${parseFloat(
                                        this.getIGSTFromTotal(subTotal)
                                      ).toFixed(2)}`}
                                    </span>
                                  </li>
                                )}

                              <li>
                                {"- "}
                                {getValueFromLanguageObject("discount")
                                  ? getValueFromLanguageObject("discount")
                                  : "Discount"}
                                <span className="count">
                                  {`${symbol}${parseFloat(0).toFixed(2)}`}
                                </span>
                              </li>
                            </ul>
                            <ul className="total">
                              <li>
                                {getValueFromLanguageObject(
                                  "str_amount_payable_with_colun"
                                )
                                  ? getValueFromLanguageObject(
                                      "str_amount_payable_with_colun"
                                    )
                                  : "Amount Payable : "}
                                <span className="count">
                                  {symbol}
                                  {parseFloat(
                                    this.getGrandTotal(
                                      subTotal,
                                      this.getCGSTFromTotal(subTotal),
                                      this.getSGSTFromTotal(subTotal),
                                      this.getIGSTFromTotal(subTotal),
                                      this.getShippingCharges(subTotal)
                                    )
                                  ).toFixed(2)}
                                </span>
                              </li>
                            </ul>
                          </div>

                          {billingaddressObj && shippingaddressObj && (
                            <div className="payment-box">
                              <div className="upper-box">
                                {isHidePlaceOrderButton &&
                                  paymentDetails &&
                                  paymentDetails.length > 0 &&
                                  paymentDetails[0].status === 1 && (
                                    <div className="payment-options">
                                      <div className="order-box mb-0">
                                        <div className="title-box border-0 pb-0 mb-3">
                                          {getValueFromLanguageObject(
                                            "str_make_your_payment_via"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_make_your_payment_via"
                                              )
                                            : "Make your payment via"}
                                        </div>
                                      </div>
                                      <ul>
                                        {paymentDetails &&
                                          paymentDetails.length > 0 &&
                                          paymentDetails[0].status === 1 &&
                                          paymentDetails[0].text &&
                                          paymentDetails[0].text.length > 0 &&
                                          paymentDetails[0].text.map(
                                            (paymentOption) => {
                                              return paymentOption.status ===
                                                1 ? (
                                                <li>
                                                  <div className="radio-option">
                                                    <input
                                                      type="radio"
                                                      name="payment-group"
                                                      id={paymentOption.name}
                                                      onClick={() => {
                                                        this.onChangeOfPaymentMethod(
                                                          paymentOption.name
                                                        );
                                                      }}
                                                      checked={
                                                        this.state
                                                          .paymentMethod ===
                                                        paymentOption.name
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={
                                                        paymentOption.name
                                                      }
                                                      style={{
                                                        color: "#444444",
                                                      }}
                                                    >
                                                      {paymentOption.name}
                                                      <span className="image" />
                                                    </label>
                                                  </div>
                                                </li>
                                              ) : (
                                                ""
                                              );
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  )}
                              </div>
                              <div className="mb-2 text-right" />
                              {isHidePlaceOrderButton && (
                                <div className="text-right">
                                  {orderViaWhatsapp &&
                                    orderViaWhatsapp.status === 1 && (
                                      <button
                                        type="button"
                                        className="btn-solid-wp btn mobile-btn-block"
                                        onClick={() =>
                                          this.onClickOfOrderViaWhatsApp()
                                        }
                                      >
                                        {getValueFromLanguageObject(
                                          "str_order_via_whatsapp"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_order_via_whatsapp"
                                            )
                                          : "Order Via WhatsApp"}
                                      </button>
                                    )}

                                  <button
                                    type="button"
                                    className="btn-solid btn mobile-btn-block"
                                    onClick={() => this.onClickOfPlaceOrder()}
                                  >
                                    {getValueFromLanguageObject(
                                      "str_place_order"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_place_order"
                                        )
                                      : "Place Order"}
                                  </button>
                                </div>
                              )}

                              <div className="mb-2 text-right">
                                {!isHidePlaceOrderButton &&
                                  paymentMethod === "Paypal" &&
                                  this.state.payPalConfig &&
                                  this.state.payPalConfig.env && (
                                    <PaypalExpressBtn
                                      env={this.state.payPalConfig.env}
                                      client={this.state.payPalConfig.client}
                                      currency={
                                        this.state.payPalConfig.currency
                                      }
                                      total={this.state.payPalConfig.total}
                                      onError={this.onErrorOfPaypalPayment}
                                      onSuccess={this.onSuccessOfPaypalPayment}
                                      onCancel={this.onCancelOfPaypalPayment}
                                      shipping={1}
                                      style={{
                                        color: "gold",
                                        shape: "rect",
                                        size: "large",
                                        label: "pay",
                                      }}
                                    />
                                  )}
                                {!isHidePlaceOrderButton &&
                                  paymentMethod === "GooglePay" && (
                                    <GooglePayButton
                                      environment="PRODUCTION" // TEST, PRODUCTION
                                      buttonType="long"
                                      style={{ marginTop: "10px" }}
                                      paymentRequest={
                                        this.state.googlePayConfig
                                      }
                                      onLoadPaymentData={
                                        this.makeGooglePayPayment
                                      }
                                      onCancel={(cancel) => {
                                        console.log("cancel");
                                        console.log(cancel);
                                        this.loadErrorMessageAnimation(
                                          "Payment failed"
                                        );
                                        this.setState(
                                          { paymentResponse: cancel },
                                          () => {
                                            this.updatePaymentStatusGooglePay(
                                              3
                                            );
                                          }
                                        );
                                      }}
                                      onError={(error) => {
                                        console.log("error");
                                        console.log(error);
                                        this.loadErrorMessageAnimation(
                                          "Payment failed"
                                        );
                                        this.setState(
                                          { paymentResponse: error },
                                          () => {
                                            this.updatePaymentStatusGooglePay(
                                              3
                                            );
                                          }
                                        );
                                      }}
                                      onReadyToPayChange={(change) => {
                                        console.log("change");
                                        console.log(change);
                                      }}
                                      // onPaymentAuthorized={(auth) => {
                                      //   console.log("auth");
                                      //   console.log(auth);
                                      // }}
                                      // onPaymentDataChanged={(dataChange) => {
                                      //   console.log("dataChange");
                                      //   console.log(dataChange);
                                      // }}
                                    />
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          {this.state.paytmConfig &&
            this.state.paytmConfig.merchant &&
            this.state.paytmConfig.merchant.mid && (
              <div>
                <CheckoutProvider
                  config={this.state.paytmConfig}
                  checkoutJsInstance={this.state.checkoutJsInstance}
                  openInPopup={this.state.openInPopup}
                >
                  <Checkout />
                </CheckoutProvider>
              </div>
            )}
        </section>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        <SadadPaymentForm
          sadadPaymentConfig={sadadPaymentConfig}
          sadadPaymentChecksum={sadadPaymentChecksum}
          isOpenPayWithSadad={isOpenPayWithSadad}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(withRouter(checkOut));
