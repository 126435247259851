import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
// import { PDFDownloadLink, Page, Document, pdfjs } from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page, pdfjs } from "react-pdf";
import { getValueFromLanguageObject } from "../../../store";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getCall } from "../../../api/post";
import {
  getSettingUrl,
  getMasterDetailsIdUrl,
  getProductPdfUrl,
} from "../../../services";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import { clearLocalStorageReload } from "../../../utils/utils";
import YoutubeEmbed from "./YoutubeEmbed";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

class ProductDetailsTab extends Component {
  state = {
    pageNumber: 1,
    currentCatelogueType: {},
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      this.initCatelogueSettings();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  renderProductDetails = (productdetails) => {
    const { currentCatelogueType } = this.state;
    const details = JSON.parse(productdetails);
    return details.map((detail) => {
      return (
        <tr>
          {currentCatelogueType &&
            currentCatelogueType.registerallow !== "Catalogue_Electronics" && (
              <th> {detail.lable} : </th>
            )}
          {currentCatelogueType &&
            currentCatelogueType.registerallow !== "Catalogue_Electronics" && (
              <td> {detail.data}</td>
            )}
          {currentCatelogueType &&
            currentCatelogueType.registerallow === "Catalogue_Electronics" && (
              <td> - {detail.data}</td>
            )}
        </tr>
      );
    });
  };
  
  // getId = (url) => {
  //   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //   const match = url.match(regExp);

  //   return match && match[2].length === 11 ? match[2] : null;
  // };

  // rendervideourllink = (videourllink) => {
  //   this.getId(videourllink);
  //   return (
  //     <tr>
  //       <div className="App">
  //         <YoutubeEmbed embedId={this.getId(videourllink)} />
  //       </div>
  //     </tr>
  //   );
  // };

  renderPriceBreakdown = (other) => {
    const priceBreakdown = JSON.parse(other);
    return (
      <tbody>
        <tr>
          <th>{priceBreakdown.jewelleryData.name}</th>
          <td>-</td>
          <td>{priceBreakdown.jewelleryData.weightintoprice}</td>
        </tr>

        {priceBreakdown &&
          priceBreakdown.jewelleryData &&
          priceBreakdown.jewelleryData.makingcharges &&
          priceBreakdown.jewelleryData.makingcharges.map((makingcharge) => {
            return (
              <tr>
                <th>{makingcharge.pricelable}</th>
                <td>-</td>
                <td>{makingcharge.amount}</td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  onDocumentLoadSuccess = (pdf) => {};

  onDocumentLoadError = (error) => {};

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const { item } = this.props;
    const {
      pageNumber,
      currentCatelogueType,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
    } = this.state;

    const strLoadingPleaseWait = getValueFromLanguageObject(
      "str_loading_please_wait_with_dots"
    )
      ? getValueFromLanguageObject("str_loading_please_wait_with_dots")
      : "Loading Please Wait...";

    return (
      <>
        <section className="tab-product m-0">
          <div className="row">
            {/* <div className="col-lg-6" /> */}
            <div className="col-lg-12">
              <Tabs className="tab-content nav-material">
                <TabList className="nav nav-tabs nav-material">
                  {item.productdetails &&
                    JSON.parse(item.productdetails).length > 0 && (
                      <Tab className="nav-item">
                        <span className="nav-link active">
                          <i className="icofont icofont-ui-home" />
                          {getValueFromLanguageObject("str_details")
                            ? getValueFromLanguageObject("str_details")
                            : "Details"}
                        </span>
                        <div className="material-border" />
                      </Tab>
                    )}

                  {/* {item.videourllink && (
                    <Tab className="nav-item">
                      <span className="nav-link active">
                        <i className="icofont icofont-ui-home" />
                        {getValueFromLanguageObject("str_video")
                          ? getValueFromLanguageObject("str_video")
                          : "Video"}
                      </span>
                      <div className="material-border" />
                    </Tab>
                  )} */}

                  {item.productbrochure && item.productbrochure !== null && (
                    <Tab className="nav-item">
                      <span className="nav-link">
                        <i className="icofont icofont-man-in-glasses" />
                        {getValueFromLanguageObject("str_specification")
                          ? getValueFromLanguageObject("str_specification")
                          : "Specification"}
                        {"   "}
                        <CopyToClipboard
                          text={getProductPdfUrl(
                            item.productbrochure,
                            masterDetailsObj
                          )}
                          onCopy={() => {
                            this.loadSuccessMessageAnimation(
                              getValueFromLanguageObject(
                                "str_pdf_url_is_copied_to_clipboard"
                              )
                                ? getValueFromLanguageObject(
                                    "str_pdf_url_is_copied_to_clipboard"
                                  )
                                : "PDF URL is copied to clipboard"
                            );
                          }}
                        >
                          <span className="text-muted ml-2 mr-2">
                            <i class="fa fa-share-alt" aria-hidden="true" />
                          </span>
                        </CopyToClipboard>
                        <a
                          className="text-muted"
                          href={getProductPdfUrl(
                            item.productbrochure,
                            masterDetailsObj
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={item.productbrochure}
                        >
                          <i class="fa fa-download " aria-hidden="true" />
                        </a>
                      </span>

                      <div className="material-border" />
                    </Tab>
                  )}

                  {/* // this tab panel is only for SJ */}
                  {currentCatelogueType &&
                    currentCatelogueType.registerallow ===
                      "Catalogue_Jewellary" && (
                      <Tab className="nav-item">
                        <span className="nav-link">
                          <i className="icofont icofont-contacts" />
                          {getValueFromLanguageObject("str_price_breakdown")
                            ? getValueFromLanguageObject("str_price_breakdown")
                            : "Price Breakdown"}
                        </span>
                        <div className="material-border" />
                      </Tab>
                    )}
                  {/* <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Video</span>
                                    <div className="material-border"></div>
                                </Tab> */}
                  {/* <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Write Review</span>
                                    <div className="material-border"></div>
                                </Tab> */}
                </TabList>
                {item.productdetails &&
                  JSON.parse(item.productdetails).length > 0 && (
                    <TabPanel className="tab-pane fade mt-4 show active">
                      <table className="table table-striped mb-0 product-detail-table">
                        <tbody>
                          {this.renderProductDetails(item.productdetails)}
                          {!item.productdetails && item.description && (
                            <div>
                              <h6 className="product-title">
                                {item.description}
                              </h6>
                            </div>
                          )}
                        </tbody>
                      </table>
                    </TabPanel>
                  )}

                {/* // Video moved to slider section */}
                {/* {item.videourllink && (
                  <TabPanel className="tab-pane fade mt-4 show active">
                    <table className="table table-striped mb-0 product-detail-table">
                      <tbody>
                        {this.rendervideourllink(item.videourllink)}
                        {!item.videourllink && item.description && (
                          <div>
                            <h6 className="product-title">
                              {item.videourllink}
                            </h6>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </TabPanel>
                )} */}

                {/* // This tab panel is only for nextview */}

                {item.productbrochure && item.productbrochure !== null && (
                  <TabPanel>
                    <div className="mt-4 text-center">
                      <div
                        className="embed-responsive embed-responsive-16by9"
                        onClick={() => {
                          window.open(
                            getProductPdfUrl(
                              item.productbrochure,
                              masterDetailsObj
                            )
                          );
                        }}
                      >
                        <Document
                          // file={{
                          //   url: `https://cors-anywhere.herokuapp.com/${
                          //     item.productbrochure
                          //   }`,
                          // }}
                          file={getProductPdfUrl(
                            item.productbrochure,
                            masterDetailsObj
                          )}
                          loading={strLoadingPleaseWait}
                          onLoadSuccess={this.onDocumentLoadSuccess}
                          onLoadError={this.onDocumentLoadError}
                        >
                          <Page scale={"0.75"} pageNumber={pageNumber} />
                        </Document>
                      </div>
                    </div>
                  </TabPanel>
                )}

                {/* // this tab panel is only for SJ */}
                {currentCatelogueType &&
                  currentCatelogueType.registerallow ===
                    "Catalogue_Jewellary" && (
                    <TabPanel className="tab-pane fade mt-4 show active">
                      <table className="table table-striped mb-0">
                        {item.other && this.renderPriceBreakdown(item.other)}
                      </table>
                    </TabPanel>
                  )}

                {/* <TabPanel>
                                <div className="mt-4 text-center">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe
                                            src="https://www.youtube.com/embed/BUWzX78Ye_8"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                            </TabPanel> */}
                {/* <TabPanel>
                                <form className="theme-form mt-4">
                                    <div className="form-row">
                                        <div className="col-md-12 ">
                                            <div className="media m-0">
                                                <label>Rating</label>
                                                <div className="media-body ml-3">
                                                    <div className="rating three-star">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Email" required />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="review">Review Title</label>
                                            <input type="text" className="form-control" id="review" placeholder="Enter your Review Subjects" required />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="review">Review Title</label>
                                            <textarea className="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-solid" type="submit">Submit YOur Review</button>
                                        </div>
                                    </div>
                                </form>
                            </TabPanel> */}
              </Tabs>
            </div>
          </div>
        </section>
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default ProductDetailsTab;
