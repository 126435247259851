import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../xeUtils/xeUtils";
import { getPlaceHolderFlag } from "../../services";
const KTSVG = ({
  className = "",
  path,
  svgClassName = "mh-50px",
  onError = "",
}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <img
        alt=""
        src={toAbsoluteUrl(path)}
        className={svgClassName}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = getPlaceHolderFlag();
        }}
      />
      {/* <SVG
        src={toAbsoluteUrl(path)}
        className={svgClassName}
        // onError={onError}
        onError={onError}
      /> */}
    </span>
  );
};
export default KTSVG;
