import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { SlideUpDown } from "../../../services/script";
import { getCall } from "../../../api/post";
import {
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getTenantInfoUrl,
  getCategoriesLastSixUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import { Link } from "react-router-dom";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { debuglogger } from "../../../utils/logger";
import NavBar from "../navbar/navbar";

class MainFooter extends Component {
  state = {
    contactUs: {},
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
    merchantPanelType: "",
    categories: [],
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const userDetails = loadUserDetailsFromLocalStorage();
      this.setState({ userDetails });
      this.initTenantInfo();
      this.initContactUs();
      this.initCategory();
      var contentwidth = window.innerWidth;
      if (contentwidth < 750) {
        SlideUpDown("footer-title");
      } else {
        var elems = document.querySelectorAll(".footer-title");
        [].forEach.call(elems, function(elemt) {
          let el = elemt.nextElementSibling;
          el.style = "display: block";
        });
      }
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    debuglogger("masterDetailsIdUrl", masterDetailsIdUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (
        tenantInfo &&
        tenantInfo.length > 0 &&
        tenantInfo[0].text &&
        tenantInfo[0].text.length > 0
      ) {
        const tenantSettings = JSON.parse(tenantInfo[0].text);
        if ("merchantPanelType" in tenantSettings) {
          this.setState({
            merchantPanelType: tenantSettings.merchantPanelType,
          });
        } else {
          this.setState({
            merchantPanelType: "Sufalamtech Pvt Ltd",
          });
        }
      }
    }
  };

  initContactUs = async () => {
    const { masterDetailsObj } = this.state;
    if (masterDetailsObj && masterDetailsObj.id) {
      const contantusUrl = getMerchantInformationUrl(masterDetailsObj);
      debuglogger("contantusUrl", contantusUrl);
      const contactUs = await getCall(contantusUrl, []);
      if (contactUs.error) {
        clearLocalStorageReload({ response: contactUs });
        this.loadErrorMessageAnimation(contactUs.error.message);
        if (contactUs.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, "/login");
          return;
        }
      } else {
        this.setState({ contactUs });
      }
    }
  };

  initCategory = async () => {
    const categoryUrl = getCategoriesLastSixUrl(6);
    const categories = await getCall(categoryUrl, []);
    if (categories.error) {
      clearLocalStorageReload({ response: categories });
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({ categories: categories.categories });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      contactUs,
      showErrorMessageModal,
      errorMessage,
      merchantPanelType,
      categories,
      userDetails,
    } = this.state;
    return (
      <>
        {/* <footer className="footer-light"> */}
        {/* <div className="light-layout">
          <div className="container">
            <section className="small-section border-section border-top-0">
              <div className="row">
                {isOpenStoreIdExist() && <NavBar />}
                {!isOpenStoreIdExist() && userDetails && <NavBar />}
              </div>
            </section>
          </div>
        </div> */}
        {/* <section className="section-b-space light-layout pt-5 pb-5">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-md-3">
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage />
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="sub-title">
                  <div className="footer-title" />
                  <div className="footer-contant">
                    <ul className="contact-list">
                      {contactUs.address1 && (
                        <li
                          onClick={() => {
                            window.open(
                              `https://www.google.com.sa/maps/search/${
                                contactUs.address1
                              },${
                                contactUs.address2 ? contactUs.address2 : ""
                              }?hl=en`
                            );
                          }}
                        >
                          <a href>
                            <i className="fa fa-map-marker" />
                            {contactUs.address1},{contactUs.address2}
                          </a>
                        </li>
                      )}
                      {contactUs.cellnumber && (
                        <li>
                          <a href={`tel:${contactUs.cellnumber}`}>
                            <i className="fa fa-phone" />{" "}
                            {getValueFromLanguageObject("str_call_us")
                              ? getValueFromLanguageObject("str_call_us")
                              : "Call Us"}
                            : {contactUs.cellnumber}
                          </a>
                        </li>
                      )}

                      {contactUs.email && (
                        <li>
                          <a href={`mailto:${contactUs.email}`}>
                            <i className="fa fa-envelope" />{" "}
                            {getValueFromLanguageObject("str_email_address")
                              ? getValueFromLanguageObject("str_email_address")
                              : "Email Address"}{" "}
                            : {contactUs.email}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <footer className="newDesign">
          <div className="footer-top">
            <div className="title-left">
              <div>
                <h3>
                  {getValueFromLanguageObject("str_we_are_always_here_to_help")
                    ? getValueFromLanguageObject(
                        "str_we_are_always_here_to_help"
                      )
                    : "We're Always Here To Help"}
                </h3>
                <p>
                  {getValueFromLanguageObject(
                    "str_reach_out_to_us_through_any_of_these_support_channels"
                  )
                    ? getValueFromLanguageObject(
                        "str_reach_out_to_us_through_any_of_these_support_channels"
                      )
                    : "Reach out to us through any of these support channels"}
                </p>
              </div>
            </div>
            <div className="contact-right">
              <a
                href={`tel:${contactUs.cellnumber}`}
                className="contact-details-right"
              >
                <div className="icon">
                  <i className="fa fa-phone" />
                </div>
                <div className="top-tag" />
                <div className="contact-info">
                  <span>
                    {getValueFromLanguageObject("str_call_us")
                      ? getValueFromLanguageObject("str_call_us")
                      : "Call Us"}
                  </span>
                  {contactUs.cellnumber}
                </div>
              </a>
              <div className="contact-details-right">
                <div className="icon">
                  <i className="fa fa-envelope" />
                </div>
                <div className="top-tag" />
                <div className="contact-info">
                  <span>
                    {getValueFromLanguageObject("str_email_address")
                      ? getValueFromLanguageObject("str_email_address")
                      : "Email Address"}{" "}
                  </span>
                  {contactUs.email}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-middle">
            <div className="footer-cat">
              {categories &&
                categories.map((category) => {
                  const { subcategories } = category || {};
                  return (
                    <div class="column">
                      <div
                        class="title cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(`/c/${category.id}`);
                        }}
                      >
                        {" "}
                        {category.name}{" "}
                      </div>
                      <ul class="footer-links">
                        {subcategories.map((subCategory) => (
                          <li>
                            <a
                              className="cursor-pointer"
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(`/s/${subCategory.id}`);
                              }}
                            >
                              {subCategory.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
            </div>
            {/* <div className="footer-app">
              <div>
                <div className="title">
                  {getValueFromLanguageObject("str_collections")
                    ? getValueFromLanguageObject("str_collections")
                    : "Collections"}
                </div>
                <div>
                  <img src="https://z.nooncdn.com/s/app/com/common/images/logos/app-store.svg" />
                  <img src="https://z.nooncdn.com/s/app/com/common/images/logos/google-play.svg" />
                </div>
              </div>
            </div> */}
          </div>
          <div className="sub-footer">
            <div className="footer-end">
              <p>
                <i className="fa fa-copyright" aria-hidden="true" /> 2021
                {` `}
                {merchantPanelType === "Sadad"
                  ? getValueFromLanguageObject("str_sadad_store")
                    ? getValueFromLanguageObject("str_sadad_store")
                    : "Sadad store |"
                  : getValueFromLanguageObject("str_bizon")
                  ? getValueFromLanguageObject("str_bizon")
                  : "Bizon |"}
                {` `}
                {/* Sufalamtech Pvt Ltd | {console.log(merchantPanelType)} */}
                <Link className="text-primary" to={`/privacypolicy`}>
                  {getValueFromLanguageObject("str_privacy_policy")
                    ? getValueFromLanguageObject("str_privacy_policy")
                    : "Privacy Policy"}
                </Link>
              </p>
            </div>
            <div className="payment-card-bottom">
              <ul>
                <li>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/paypal.png`}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`}
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              {isOpenStoreIdExist() && <NavBar />}
              {!isOpenStoreIdExist() && userDetails && <NavBar />}
            </div>
          </div>
        </footer>
        {/* </footer> */}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(MainFooter);
