// import routesFromFile1 from './the/route';
import React, { Component } from "react";
import PageNotFound from "../components/pages/404";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainLoader from "../animation/MainLoader";
import { ScrollContext } from "react-router-scroll-4";
import DashboardWrapper from "./dashboard/DashboardWrapper";
import login from "../components/layouts/login/login";

class XERoutes extends Component {
  componentDidMount() {
    document.querySelector(".loader-wrapper-splash").style = "display: none";
  }

  render() {

    return (
      <>
        <BrowserRouter>
          <ScrollContext>
            <Switch>
              {/* <Route exact path={`/`} component={Dashboard} /> */}
              <Route path="/dashboard" component={DashboardWrapper} />
              <Route path={`/`} component={login} />
              
              {/* <Route exact path="*" component={PageNotFound} /> */}
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </>
    );
  }
}
export default XERoutes;
