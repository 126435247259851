import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import "./index.scss";
// import "swiper/css/bundle";
import 'swiper/swiper-bundle.css'

// Import custom components
import store, {
  getCurrentLanguage,
  getOpenStoreIdApp,
  loadUserDetailsFromLocalStorage,
  removeOpenStoreIdApp,
  setCurrentTheme,
  setCurrencySymbol,
  setDefaultLoader,
  setLanguageObjectToLocalStorage,
  setMDIInLocal,
  setOpenStoreIdApp,
  setStoreOpen,
} from "./store";
import translations from "./constants/translations";
import {
  getCommonCounter,
  getNotificationCounter,
  isUserAllowedToRegister,
  isInquiryOrCart,
} from "./actions";

import Routes from "./routes";
import {
  getMasterDetailsIdUrl,
  getOpenStoreConfigUrl,
  getTenantInfoUrl,
  getMerchantInformationUrl,
} from "./services";
import { getCall } from "./api/post";
import ErrorMessageAnimationModal from "./animation/ErrorMessageAnimationModal";
import { clearLocalStorageReload } from "./utils/utils";
import { debuglogger } from "./utils/logger";
import XERoutes from "./app/xeRoutes";
// import { debuglogger } from "./utils/logger";

class Root extends React.Component {
  state = {
    colorCode: false,
    isLoading: true,
    masterDetailsObj: {},
    showErrorMessageModal: false,
    errorMessage: "",
    isShowXEModule: false,
  };

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      // To Hide Global Sadad/Bizon Loader
      document.querySelector(".loader-wrapper-splash").style = "display: none";
      this.setState({ isLoading: false });
      this.initOpenStoreConfig();
      this.initTenantInfo();
      this.initMerchantInformation();
      this.initThemeCode();
      this.initLanguage();
      this.initCurrency();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []);
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
        if (masterDetails && masterDetails.id) {
          setMDIInLocal(masterDetails.id);
        }
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initOpenStoreConfig = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const openStoreConfigUrl = getOpenStoreConfigUrl(id);
    const openStoreConfig = await getCall(openStoreConfigUrl, []);
    console.log('openStoreConfig .... ', openStoreConfig);
    if (openStoreConfig.error) {
      this.loadErrorMessageAnimation(openStoreConfig.error.message);
    } else {
      if (
        openStoreConfig &&
        openStoreConfig.length > 0 &&
        openStoreConfig[0].status === 1
      ) {
        // Dont Store if user logged in
        const userDetails = loadUserDetailsFromLocalStorage();
        console.log('userDetails .... ', userDetails);
        if (userDetails === undefined) {
          const id = getOpenStoreIdApp();
          if (!id) {
            console.log('id .... ', id);
            const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
              /[xy]/g,
              function (c) {
                var r = (Math.random() * 16) | 0,
                  v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
              }
            );

            console.log('uuid .... ', uuid);

            setOpenStoreIdApp(uuid);
          }
          setStoreOpen(true);
        }
      } else {
        setStoreOpen(false);
        removeOpenStoreIdApp();
      }
    }
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }

    this.setState({ isLoading: true });
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    this.setState({ isLoading: false });
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
      setDefaultLoader(true);
    } else {
      if (tenantInfo && tenantInfo.length > 0) {
        const tenantItem = tenantInfo[0];
        const { text } = tenantItem || {};
        const tenantSettings = JSON.parse(text);
        const { isDarkMode } = tenantSettings || {};
        if (isDarkMode) {
          document.body.classList.add("default-dark");
          setCurrentTheme("Dark");
        } else {
          setCurrentTheme("Light");
        }
        const { isDefaultLoader } = tenantSettings || {};
        const { isShowXEModule } = tenantSettings || {};
        if ("isShowXEModule" in tenantSettings) {
          this.setState({
            isShowXEModule: tenantSettings.isShowXEModule,
          });
        } else {
          this.setState({
            isShowXEModule: false,
          });
        }
        if (isDefaultLoader) {
          setDefaultLoader(true);
        } else {
          setDefaultLoader(false);
        }
      } else {
        setDefaultLoader(true);
      }
    }
  };

  initMerchantInformation = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const logoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfoResponse = await getCall(logoUrl, []);
    if (merchantInfoResponse.error) {
      clearLocalStorageReload({ response: merchantInfoResponse });
      return;
    } else {
      const favicon = document.getElementById("favicon");
      const { profilepic } = merchantInfoResponse || {};
      if (profilepic) {
        let path = `${process.env.SERVER_URL}`;
        path = `${path}/api/containers/profilepic-${masterDetailsObj.codename}/download/${profilepic}`;
        favicon.href = path;
      } else {
        // TODO Change Below URL for SADAD and BIZON
        const path = `${process.env.PUBLIC_URL}/assets/images/icon/favicon_bizon_152.png`;
        favicon.href = path;
      }
    }
  };

  initLanguage = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const languageUrl = `languages/getLanguage?filter[key]=Android_Language&filter[where][masterdetailId]=${id}`;
    const getLanguage = await getCall(languageUrl, []);
    if (getLanguage.error) {
      clearLocalStorageReload({ response: getLanguage });
    }
    setLanguageObjectToLocalStorage(getLanguage);
  };

  initThemeCode = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const themeCodeUrl = `settings/getThemeColor?filter[where][masterdetailId]=${id}`;
    const themeCode = await getCall(themeCodeUrl, []);
    if (themeCode.error) {
      clearLocalStorageReload({ response: themeCode });
    } else {
      if (themeCode && themeCode.selected) {
        document
          .getElementById("color")
          .setAttribute(
            "href",
            `${process.env.PUBLIC_URL}/assets/css/color${themeCode.id}.css`
          );
      }
    }
  };

  initCurrency = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const currencyUrl = `settings?filter[where][masterdetailId]=${id}&filter[where][registerallow]=CURRENCY`;
    const [currencyResponse] = await getCall(currencyUrl, []);
    debuglogger("currencyResponse", currencyResponse);
    if (currencyResponse.error) {
      clearLocalStorageReload({ response: currencyResponse });
    }
    if (currencyResponse) {
      const { text } = currencyResponse;
      if (text) {
        setCurrencySymbol(text);
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      isLoading,
      masterDetailsObj,
      showErrorMessageModal,
      errorMessage,
      isShowXEModule,
    } = this.state;
    store.dispatch(
      getCommonCounter(),
      getNotificationCounter(),
      isUserAllowedToRegister(masterDetailsObj),
      isInquiryOrCart(masterDetailsObj)
    );

    return (
      <>
        <Provider store={store}>
          <IntlProvider
            translations={translations}
            locale={getCurrentLanguage()}
          >
            <Routes masterDetailsObj={masterDetailsObj} isLoading={isLoading} />
          </IntlProvider>
        </Provider>

        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
