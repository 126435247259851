import React, { Component } from "react";
import Lottie from "react-lottie";
import deleteJSON from "../json/delete.json";
import { getValueFromLanguageObject } from "../../store";
import { isInquiryOrCart } from "../../actions";
import { connect } from "react-redux";

import noProductFoundOrderJSON from "../json/noProductFoundOrderJSON.json"

class NoProductFoundOrderAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: noProductFoundOrderJSON,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  }

  render() {
    const { isInquiry } = this.props;

    return (
      <>
        <center>
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <center>
                      <div className="col-sm-12 empty-cart-cls text-center mt-5 animationSize-300-300">
                        <Lottie
                          options={this.defaultOptions}
                        />
                        <h3>
                          {isInquiry ? (<strong>
                            {getValueFromLanguageObject(
                              "str_you_have_not_placed_any_inquiry"
                            )
                              ? getValueFromLanguageObject(
                                  "str_you_have_not_placed_any_inquiry"
                                )
                              : "You have not placed any inquiry yet"}
                          </strong>): (<strong>
                            {getValueFromLanguageObject(
                              "str_no_order_found_desc"
                            )
                              ? getValueFromLanguageObject(
                                  "str_no_order_found_desc"
                                )
                              : "You have not placed any orders yet"}
                          </strong>)}
                          
                        </h3>
                        <h4>
                          {getValueFromLanguageObject(
                            "str_explore_more_shortlist_some_items"
                          )
                            ? getValueFromLanguageObject(
                                "str_explore_more_shortlist_some_items"
                              )
                            : "Explore more shortlist some items."}
                        </h4>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </center>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(NoProductFoundOrderAnim);
// export default NoProductFoundOrderAnim;
