import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import { getCall, postCall, patchCall } from "../../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getMerchantInformationUrl,
  getPaymentDetailsUrl,
  getOrderStatusUrl,
  getMasterDetailsIdUrl,
  getAddressDetailUrl,
  getShringOptionSettingsUrl,
} from "../../../services";
import { Link, withRouter } from "react-router-dom";
import { getNotificationCounter } from "../../../actions";
import { NotificationManager } from "react-notifications";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import RepeatOrderAnim from "../../../animation/AnimFiles/RepeatOrderAnim";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import { isInquiryOrCart } from "../../../actions";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import Lightbox from "react-image-lightbox";

const publicUrl = window.location.pathname.split("/")[1];
const client = process.env.CLIENT_NAME;

class OrderSummary extends Component {
  state = {
    orderId: this.props.match.params.id,
    orderSummary: {},
    isLoading: true,
    orderNotes: "",
    isEditOrderNotesInputVisible: false,
    isMerchantGSTEnable: false,
    merchantInfo: {},
    shareUrl: "",
    sharingOptionSettings: null,
    paymentDetails: [],
    currentCatelogueType: {},
    orderStatusList: [],
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    isFullScreenImage: false,
    fullScreenImagePath: "",
  };
  componentDidMount() {
    this.init();
  }

  UNSAFE_componentWillUpdate(nextProps, newState) {
    const { params: nextParams } = nextProps.match || {};
    const { id } = nextParams || {};
    const { orderId } = newState;
    if (id !== orderId) {
      this.setState({ orderId: id, isLoading: true }, () => {
        this.init();
      });
    }
  }

  init = () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    if (isOpenStoreIdExist()) {
      navigateTo(this.props, `/`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      this.initOrderSummary().then(() => {
        this.setState({ isLoading: false });
        this.initShareLink();
        this.initSharingOption();
      });
      this.initCatelogueSettings();
      this.initPaymentDetails();
      this.initOrderStatus();
      this.initMerchantInfo();
      this.props.notificationCounter();
      this.initAddressDetails();
    });
  };

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initInquirySummary = async () => {
    const { inquiryId } = this.state;
    const inquirySummaryUrl = `orders/${inquiryId} `;
    const inquirySummary = await getCall(inquirySummaryUrl, []);

    if (inquirySummary.error) {
      clearLocalStorageReload({ response: inquirySummary });
      this.loadErrorMessageAnimation(inquirySummary.error.message);
      if (inquirySummary.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ inquirySummary, orderNotes: inquirySummary.description });
    }
  };

  initOrderSummary = async () => {
    const { orderId } = this.state;
    const orderSummaryUrl = `orders/${orderId}?filter[include]=orderdetails&filter[include]=productmedia`;
    const orderSummary = await getCall(orderSummaryUrl, []);
    if (orderSummary.error) {
      clearLocalStorageReload({ response: orderSummary });
      this.loadErrorMessageAnimation(orderSummary.error.message);
      if (orderSummary.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    }
    this.setState({ orderSummary, orderNotes: orderSummary.description });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({ isMerchantGSTEnable: false, merchantInfo: {} });
    } else {
      this.setState({
        merchantInfo,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      clearLocalStorageReload({ response: paymentDetails });
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  initShareLink = async () => {
    const { orderId } = this.state;
    const shareLinkUrl = "products/shortnewURL";
    const link = `http://${window.location.host}/${
      client ? client : publicUrl
    }/o/${orderId}`;
    const shareLinkObj = {
      url: link,
    };
    const shareLinkResponse = await postCall(shareLinkUrl, shareLinkObj);
    if (shareLinkResponse.error) {
      clearLocalStorageReload({ response: shareLinkResponse });
      this.loadErrorMessageAnimation(shareLinkResponse.error.message);
    } else {
      this.setState({ shareUrl: shareLinkResponse.shortUrl });
    }
  };

  initSharingOption = async () => {
    const { masterDetailsObj } = this.state;
    const shringOptionSettingsUrl = getShringOptionSettingsUrl(
      masterDetailsObj
    );
    const shringOptionSettingsResponse = await getCall(shringOptionSettingsUrl);
    if (shringOptionSettingsResponse.error) {
      clearLocalStorageReload({ response: shringOptionSettingsResponse });
      this.loadErrorMessageAnimation(
        shringOptionSettingsResponse.error.message
      );
    } else {
      if (
        shringOptionSettingsResponse &&
        shringOptionSettingsResponse.length > 0
      ) {
        this.setState({
          sharingOptionSettings: shringOptionSettingsResponse[0],
        });
      } else {
        this.setState({ sharingOptionSettings: null });
      }
    }
  };

  initOrderStatus = async () => {
    const { masterDetailsObj } = this.state;
    const orderStatusUrl = getOrderStatusUrl(masterDetailsObj);
    const orderStatusList = await getCall(orderStatusUrl, []);
    if (orderStatusList.error) {
      clearLocalStorageReload({ response: orderStatusList });
      this.loadErrorMessageAnimation(orderStatusList.error.message);
      if (orderStatusList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ orderStatusList });
    }
  };

  initAddressDetails = async () => {
    const { masterDetailsObj } = this.state;
    const addressDetails = await getCall(
      getAddressDetailUrl(masterDetailsObj),
      []
    );

    if (addressDetails.error) {
      clearLocalStorageReload({ response: addressDetails });
    } else {
      if (addressDetails.length > 0) {
        this.setState({ addressDetails: JSON.parse(addressDetails[0].text) });
      }
    }
  };

  getTotalofCart(orderSummary) {
    let total = 0;
    if (orderSummary.orderdetail)
      for (let i = 0; i < orderSummary.orderdetail.length; i++) {
        total =
          total +
          orderSummary.orderdetail[i].amount *
            orderSummary.orderdetail[i].quantity;
      }
    return total;
  }

  getSubTotalFromTotalandServiceCharges = () => {
    const { orderSummary } = this.state || {};
    let subTotal = 0;
    const total = this.getTotalofCart(orderSummary);
    const serviceChargeAmount = parseFloat(this.renderServiceCharges());
    subTotal = total + serviceChargeAmount;
    return subTotal;
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharges) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharges;
    } else {
      grandTotal = total + igst + shippingCharges;
    }
    return grandTotal;
  };

  renderBillingAddress = () => {
    const { orderSummary, addressDetails } = this.state;
    if (orderSummary !== undefined && orderSummary.address !== undefined) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length == 0) {
        return;
      }
      if (address !== undefined) {
        let billingAddress = {};
        try {
          billingAddress = address.billingaddress;
        } catch (error) {}

        return (
          <ul className="order-detail">
            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[0].visible === 1 && (
                <li style={{ fontWeight: 550 }}>
                  {" "}
                  {getValueFromLanguageObject("str_name")
                    ? getValueFromLanguageObject("str_name")
                    : "Name"}{" "}
                  : {billingAddress.name}
                </li>
              )}

            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[1].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("mobile_number")
                    ? getValueFromLanguageObject("mobile_number")
                    : "Mobile number"}{" "}
                  : {billingAddress.mobile}
                </li>
              )}

            {billingAddress.email &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[2].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_email_address")
                    ? getValueFromLanguageObject("str_email_address")
                    : "Email Address"}
                  : {billingAddress.email}
                </li>
              )}
            {billingAddress.companyname &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[3].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_company_name")
                    ? getValueFromLanguageObject("str_company_name")
                    : "Company Name"}{" "}
                  : {billingAddress.companyname}
                </li>
              )}
            {billingAddress.gstin &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[4].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_gstin")
                    ? getValueFromLanguageObject("str_gstin")
                    : "GSTIN"}{" "}
                  : {billingAddress.gstin}
                </li>
              )}

            {billingAddress.address1 &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[5].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_address")
                    ? getValueFromLanguageObject("str_address")
                    : "Address"}{" "}
                  : {billingAddress.address1},
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[6].visible === 1 &&
                    billingAddress.address2 + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[12].visible === 1 &&
                    billingAddress.zonenumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[13].visible === 1 &&
                    billingAddress.streetnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[14].visible === 1 &&
                    billingAddress.buildingnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[15].visible === 1 &&
                    billingAddress.unitnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[7].visible === 1 &&
                    billingAddress.landmark + `,`}
                </li>
              )}
            <li>
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[11].visible === 1 &&
                billingAddress.cityname + `,`}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[10].visible === 1 &&
                billingAddress.statename}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[9].visible === 1 &&
                billingAddress.countryname}{" "}
              -{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[8].visible === 1 &&
                billingAddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  renderShippingAddress = () => {
    const { orderSummary, addressDetails } = this.state;
    if (orderSummary !== undefined && orderSummary.address !== undefined) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length == 0) {
        return;
      }
      if (address !== undefined) {
        let shippingaddress = {};
        try {
          shippingaddress = address.shippingaddress;
        } catch (error) {}
        return (
          <ul className="order-detail">
            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[0].visible === 1 && (
                <li style={{ fontWeight: 550 }}>
                  {" "}
                  {getValueFromLanguageObject("str_name")
                    ? getValueFromLanguageObject("str_name")
                    : "Name"}{" "}
                  : {shippingaddress.name}
                </li>
              )}

            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[1].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("mobile_number")
                    ? getValueFromLanguageObject("mobile_number")
                    : "Mobile number"}{" "}
                  : {shippingaddress.mobile}
                </li>
              )}

            {shippingaddress.email &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[2].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_email_address")
                    ? getValueFromLanguageObject("str_email_address")
                    : "Email Address"}
                  : {shippingaddress.email}
                </li>
              )}
            {shippingaddress.companyname &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[3].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_company_name")
                    ? getValueFromLanguageObject("str_company_name")
                    : "Company Name"}{" "}
                  : {shippingaddress.companyname}
                </li>
              )}
            {shippingaddress.gstin &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[4].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_gstin")
                    ? getValueFromLanguageObject("str_gstin")
                    : "GSTIN"}{" "}
                  : {shippingaddress.gstin}
                </li>
              )}

            {shippingaddress.address1 &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[5].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_address")
                    ? getValueFromLanguageObject("str_address")
                    : "Address"}{" "}
                  : {shippingaddress.address1},
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[6].visible === 1 &&
                    shippingaddress.address2 + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[12].visible === 1 &&
                    shippingaddress.zonenumber + `,`}{" "}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[13].visible === 1 &&
                    shippingaddress.streetnumber + `,`}{" "}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[14].visible === 1 &&
                    shippingaddress.buildingnumber + `,`}{" "}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[15].visible === 1 &&
                    shippingaddress.unitnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[7].visible === 1 &&
                    shippingaddress.landmark + `,`}
                </li>
              )}
            <li>
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[11].visible === 1 &&
                shippingaddress.cityname}
              ,{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[10].visible === 1 &&
                shippingaddress.statename}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[9].visible === 1 &&
                shippingaddress.countryname}{" "}
              -{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[8].visible === 1 &&
                shippingaddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  onClickOfRepeatOrder = () => {
    const { isInquiry } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.repeatOrder().then(() => {
              this.setState({ isLoading: false });
              onClose();
            });
          });
        };
        return (
          <>
            {isInquiry ? (
              <ConfirmAlertPopup
                title={
                  getValueFromLanguageObject("str_repeat_inquiry")
                    ? getValueFromLanguageObject("str_repeat_inquiry")
                    : "Repeat Inquiry"
                }
                message={
                  getValueFromLanguageObject(
                    "str_are_you_sure_you_want_to_repeat_inquiry"
                  )
                    ? getValueFromLanguageObject(
                        "str_are_you_sure_you_want_to_repeat_inquiry"
                      )
                    : "Are you sure you want to repeat this Inquiry?"
                }
                animation={<RepeatOrderAnim />}
                submit={
                  getValueFromLanguageObject("str_yes_repeat")
                    ? getValueFromLanguageObject("str_yes_repeat")
                    : "Yes, Repeat!"
                }
                submitClick={submitClick}
                cancelClick={onClose}
                cancel={
                  getValueFromLanguageObject("str_no")
                    ? getValueFromLanguageObject("str_no")
                    : "No"
                }
              />
            ) : (
              <ConfirmAlertPopup
                title={
                  getValueFromLanguageObject("str_repeat_order")
                    ? getValueFromLanguageObject("str_repeat_order")
                    : "Repeat Order"
                }
                message={
                  getValueFromLanguageObject("str_repeat_order")
                    ? getValueFromLanguageObject("str_repeat_order")
                    : "Are you sure you want to repeat this order?"
                }
                animation={<RepeatOrderAnim />}
                submit={
                  getValueFromLanguageObject("str_yes_repeat")
                    ? getValueFromLanguageObject("str_yes_repeat")
                    : "Yes, Repeat!"
                }
                submitClick={submitClick}
                cancelClick={onClose}
                cancel={
                  getValueFromLanguageObject("str_no")
                    ? getValueFromLanguageObject("str_no")
                    : "No"
                }
              />
            )}
          </>
          // <div>
          //   <h3>
          //     {getValueFromLanguageObject("str_repeat_order")
          //       ? getValueFromLanguageObject("str_repeat_order")
          //       : "Repeat Order"}
          //   </h3>
          //   <center>
          //     <div className="anim-container">
          //       <RepeatOrderAnim />
          //     </div>
          //     <p>
          //       {getValueFromLanguageObject("str_repeat_order_desc")
          //         ? getValueFromLanguageObject("str_repeat_order_desc")
          //         : "Are you sure you want to repeat this order?"}
          //     </p>
          //   </center>
          //   <div className="align-button">
          //     <button
          //       className="btn btn-solid"
          //       onClick={() => {
          //         this.setState({ isLoading: true }, () => {
          //           this.repeatOrder().then(() => {
          //             this.setState({ isLoading: false });
          //             onClose();
          //           });
          //         });
          //       }}
          //     >
          //       {getValueFromLanguageObject("str_yes_repeat")
          //         ? getValueFromLanguageObject("str_yes_repeat")
          //         : "Yes, Repeat!"}
          //     </button>
          //     <div className="divider" />
          //     <button className="btn btn-solid" onClick={onClose}>
          //       {getValueFromLanguageObject("str_no")
          //         ? getValueFromLanguageObject("str_no")
          //         : "No"}
          //     </button>
          //   </div>
          // </div>
        );
      },
    });
  };

  repeatOrder = async () => {
    const { isInquiry } = this.props;
    const { orderId } = this.state;
    const repeatOrderUrl = `orders/repeatOrder`;
    const repeatOrderObj = { orderId: orderId };

    const repeatOrder = await postCall(repeatOrderUrl, repeatOrderObj);
    if (repeatOrder.error) {
      clearLocalStorageReload({ response: repeatOrder });
      this.loadErrorMessageAnimation(repeatOrder.error.message);
      if (repeatOrder.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            ? getValueFromLanguageObject("str_your_inquiry_placed_successfully")
            : "Your inquiry placed successfully"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("str_your_order_placed_successfully")
            ? getValueFromLanguageObject("str_your_order_placed_successfully")
            : "Your order placed successfully"
        );
      }
    }
  };

  onClickOfEditOrderNotes = (description) => {
    const { orderSummary } = this.state;
    const { isInquiry } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="mt-0 pt-3">
            <div className="modal-header border-0 p-4">
              <h4 className="mb-0 text-left">
                {getValueFromLanguageObject("str_update_order_notes")
                  ? getValueFromLanguageObject("str_update_order_notes")
                  : "UPDATE ORDER NOTES"}
              </h4>
            </div>
            <div className="px-4">
              <label className="text-left font-weight-bold">
                {getValueFromLanguageObject("str_enter_order_notes")
                  ? getValueFromLanguageObject("str_enter_order_notes")
                  : "Enter Order Notes "}{" "}
                :
              </label>
              <input
                style={{ height: "50px", maxWidth: "100%" }}
                placeholder="Enter order notes"
                className="form-control m-0"
                type="text"
                defaultValue={orderSummary.description}
                onChange={(e) => {
                  orderSummary.description = e.target.value;
                  //this.setState({ orderSummary });
                }}
              />

              <li className="justify-content-end align-button mt-2 pb-4">
                <button
                  className="btn btn-solid"
                  onClick={() => {
                    this.setState({ isLoading: true }, () => {
                      this.editOrderNotesApi().then(() => {
                        this.setState({ isLoading: false, orderSummary });
                      });
                    });
                    onClose();
                  }}
                >
                  {getValueFromLanguageObject("str_update")
                    ? getValueFromLanguageObject("str_update")
                    : "Update"}
                </button>
                <div className="divider" />
                <button
                  className="btn btn-solid align-button"
                  onClick={() => {
                    this.onClickOfEditOrderNotes();
                    onClose();
                  }}
                >
                  {getValueFromLanguageObject("str_cancel")
                    ? getValueFromLanguageObject("str_cancel")
                    : "Cancel"}
                </button>
              </li>
            </div>
          </div>
        );
      },
    });
  };

  // onClickOfEditOrderNotes = () => {
  //   const { isEditOrderNotesInputVisible } = this.state;
  //   this.setState({
  //     isEditOrderNotesInputVisible: !isEditOrderNotesInputVisible,
  //   });
  // };

  renderPriceBreakdownSJ = (other) => {
    const jwelleryData = JSON.parse(other);
    if (jwelleryData.jewelleryData) {
      return (
        <div className="col-12" style={{ textAlign: "end" }}>
          <h4>Price Breakdown</h4>
          <p>
            {jwelleryData.jewelleryData.name} :{" "}
            {jwelleryData.jewelleryData.weightintoprice}
          </p>
          {jwelleryData.jewelleryData.makingcharges &&
            jwelleryData.jewelleryData.makingcharges.map((makingcharge) => {
              return (
                <p>
                  {makingcharge.pricelable} : {makingcharge.amount}
                </p>
              );
            })}
        </div>
      );
    } else {
      return <div />;
    }
  };

  editOrderNotesApi = async () => {
    const { orderSummary, orderId, isEditOrderNotesInputVisible } = this.state;
    const editOrderNotesUrl = "orders";
    const editOrderObj = {
      id: orderId,
      description: orderSummary.description,
      created: new Date(),
      modified: new Date(),
    };
    const editOrderNotes = await patchCall(editOrderNotesUrl, editOrderObj);
    if (editOrderNotes.error) {
      clearLocalStorageReload({ response: editOrderNotes });
      this.loadErrorMessageAnimation(editOrderNotes.error.message);
      if (editOrderNotes.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
      this.setState({ orderSummary: orderSummary });
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_order_notes_edited_sucessfully")
          ? getValueFromLanguageObject("str_order_notes_edited_sucessfully")
          : "Order notes edited sucessfully"
      );
      this.setState({
        orderSummary: orderSummary,
        isEditOrderNotesInputVisible: !isEditOrderNotesInputVisible,
      });
    }
  };

  renderEditIcon = () => {
    const { orderSummary, orderStatusList, orderId } = this.state;
    return (
      <>
        {orderStatusList.map((orderStatusItem) => {
          return (
            orderSummary &&
            orderStatusItem.id === orderSummary.orderstatus &&
            orderStatusItem.status === "PENDING" && (
              <div className="icon">
                <Link to={`/editorder/${orderId}`}>
                  <i className="fa fa-pencil" />
                </Link>
              </div>
            )
          );
        })}
      </>
    );
  };

  renderEditOrderNotesIcon = () => {
    const { orderSummary, orderStatusList } = this.state;
    return (
      <>
        {orderStatusList.map((orderStatusItem) => {
          return (
            orderSummary &&
            orderSummary.description &&
            orderStatusItem.id === orderSummary.orderstatus &&
            orderStatusItem.status === "PENDING" && (
              <div
                className="icon"
                onClick={() => this.onClickOfEditOrderNotes()}
              >
                <i className="fa fa-pencil" />
              </div>
            )
          );
        })}
      </>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  renderPaymentStatus = (paymentstatus) => {
    const strPaymentStatus = getValueFromLanguageObject("str_payment_status")
      ? getValueFromLanguageObject("str_payment_status")
      : "Payment Status";
    const paymentStatus =
      paymentstatus === 1
        ? "PENDING"
        : paymentstatus === 2
        ? "SUCCESS"
        : "FAILED";
    return <li>{`${strPaymentStatus}: ${paymentStatus}`}</li>;
  };

  renderServiceChargesLabel = () => {
    let labelServiceCharges = "";
    const { orderSummary } = this.state || {};
    const { additionalchargedetails } = orderSummary || {};
    if (additionalchargedetails) {
      const additionalchargedetailsObj =
        JSON.parse(additionalchargedetails) || {};
      const { label = "" } = additionalchargedetailsObj || {};
      labelServiceCharges = label;
    }
    return labelServiceCharges;
  };

  renderServiceCharges = () => {
    let serviceCharges = 0;
    const { orderSummary } = this.state || {};
    const { additionalchargedetails } = orderSummary || {};
    if (additionalchargedetails) {
      const additionalchargedetailsObj =
        JSON.parse(additionalchargedetails) || {};
      const { charges = 0 } = additionalchargedetailsObj || {};
      serviceCharges = parseFloat(charges).toFixed(2);
    }
    return serviceCharges;
  };

  render() {
    const {
      orderSummary,
      isLoading,
      isEditOrderNotesInputVisible,
      isMerchantGSTEnable,
      shareUrl,
      sharingOptionSettings,
      paymentDetails,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
      isFullScreenImage,
      fullScreenImagePath,
    } = this.state;
    const { symbol } = this.props;
    const { isInquiry } = this.props;

    const subTotal = this.getSubTotalFromTotalandServiceCharges(orderSummary);

    return (
      <>
        <div>
          <section className="section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-order order-success-sec">
                    <h4 className="f22">
                      {isInquiry
                        ? getValueFromLanguageObject("str_your_inquiry_details")
                          ? getValueFromLanguageObject(
                              "str_your_inquiry_details"
                            )
                          : "your inquiry details"
                        : getValueFromLanguageObject("str_your_order_details")
                        ? getValueFromLanguageObject("str_your_order_details")
                        : "your order details"}

                      {this.renderEditIcon()}
                    </h4>

                    {orderSummary.orderdetail &&
                      orderSummary.orderdetail.map((item, index) => {
                        return (
                          <div className="row product-order-detail" key={index}>
                            <div
                              className="col-12 col-sm-2"
                              onClick={() => {
                                const path =
                                  item.products.productmedia.length > 0
                                    ? getImageUrl(
                                        item.products.productmedia[0]
                                          .productname,
                                        masterDetailsObj
                                      )
                                    : getPlaceHolderImage();
                                this.setState({
                                  isFullScreenImage: true,
                                  fullScreenImagePath: path,
                                });
                              }}
                            >
                              <img
                                src={
                                  item.products.productmedia.length > 0
                                    ? getImageUrl(
                                        item.products.productmedia[0]
                                          .productname,
                                        masterDetailsObj
                                      )
                                    : getPlaceHolderImage()
                                }
                                alt=""
                                className="img-fluid"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = getPlaceHolderImage();
                                }}
                              />
                            </div>
                            <div className="col-12 col-sm-4 order_detail">
                              <div>
                                <h4>
                                  {getValueFromLanguageObject(
                                    "str_product_name"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_product_name"
                                      )
                                    : "product name"}
                                </h4>
                                <h5>{item.products.name}</h5>
                              </div>
                            </div>
                            <div className="col-6 col-sm-3 order_detail">
                              <div>
                                <h4>
                                  {getValueFromLanguageObject("str_quantity")
                                    ? getValueFromLanguageObject("str_quantity")
                                    : "quantity"}
                                </h4>
                                <h5>{item.quantity}</h5>
                              </div>
                            </div>
                            {!isInquiry && (
                              <div className="col-6 col-sm-3 order_detail">
                                <div>
                                  <h4>
                                    {getValueFromLanguageObject("str_price")
                                      ? getValueFromLanguageObject("str_price")
                                      : "price"}
                                  </h4>
                                  <h5>
                                    {symbol}
                                    {parseFloat(item.amount).toFixed(2)}
                                  </h5>
                                </div>
                              </div>
                            )}

                            {currentCatelogueType &&
                              currentCatelogueType.registerallow ===
                                "Catalogue_Jewellary" &&
                              item.products.other &&
                              item.products.other &&
                              this.renderPriceBreakdownSJ(item.products.other)}
                          </div>
                        );
                      })}
                    {!isInquiry && (
                      <div className="total-sec">
                        <div className="final-total">
                          <h4>
                            {getValueFromLanguageObject("str_total")
                              ? getValueFromLanguageObject("str_total")
                              : "total"}
                            <span>
                              {symbol}
                              {parseFloat(
                                this.getTotalofCart(orderSummary)
                              ).toFixed(2)}
                            </span>
                          </h4>
                          {orderSummary && orderSummary.additionalcharge && (
                            <h4>
                              {`+ ${this.renderServiceChargesLabel(
                                orderSummary.additionalchargedetails
                              )}`}
                              <span>
                                {symbol}
                                {parseFloat(
                                  this.renderServiceCharges()
                                ).toFixed(2)}
                              </span>
                            </h4>
                          )}
                          <h4>
                            {"+"}{" "}
                            {getValueFromLanguageObject("shipping_charges")
                              ? getValueFromLanguageObject("shipping_charges")
                              : "Shipping Charges"}
                            <span>
                              {symbol}
                              {parseFloat(
                                orderSummary.shippingprice
                                  ? orderSummary.shippingprice
                                  : 0
                              ).toFixed(2)}
                            </span>
                          </h4>
                          {isMerchantGSTEnable &&
                            currentCatelogueType &&
                            currentCatelogueType.registerallow !==
                              "Catalogue_Jewellary" &&
                            this.state.merchantInfo.countryname === "India" && (
                              <h4>
                                {"+"}{" "}
                                {getValueFromLanguageObject("str_cgst")
                                  ? getValueFromLanguageObject("str_cgst")
                                  : "CGST"}
                                {"("}
                                {this.state.merchantInfo.CGST}
                                {"%"}
                                {")"}
                                <span>
                                  {symbol}
                                  {parseFloat(
                                    this.getCGSTFromTotal(subTotal)
                                  ).toFixed(2)}
                                </span>
                              </h4>
                            )}
                          {isMerchantGSTEnable &&
                            currentCatelogueType &&
                            currentCatelogueType.registerallow !==
                              "Catalogue_Jewellary" &&
                            this.state.merchantInfo.countryname === "India" && (
                              <h4>
                                {"+"}{" "}
                                {getValueFromLanguageObject("str_sgst")
                                  ? getValueFromLanguageObject("str_sgst")
                                  : "SGST"}
                                {"("}
                                {this.state.merchantInfo.SGST}
                                {"%"}
                                {")"}
                                <span>
                                  {symbol}
                                  {parseFloat(
                                    this.getSGSTFromTotal(subTotal)
                                  ).toFixed(2)}
                                </span>
                              </h4>
                            )}
                          {isMerchantGSTEnable &&
                            currentCatelogueType &&
                            currentCatelogueType.registerallow !==
                              "Catalogue_Jewellary" &&
                            this.state.merchantInfo.countryname !== "India" && (
                              <h4>
                                {"+ "}{" "}
                                {getValueFromLanguageObject("str_igst")
                                  ? getValueFromLanguageObject("str_igst")
                                  : "IGST"}{" "}
                                {"("}
                                {this.state.merchantInfo.IGST}
                                {"%"}
                                {")"}
                                <span>
                                  {symbol}
                                  {parseFloat(
                                    this.getIGSTFromTotal(subTotal)
                                  ).toFixed(2)}
                                </span>
                              </h4>
                            )}
                          <h4>
                            {"-"}{" "}
                            {getValueFromLanguageObject("discount")
                              ? getValueFromLanguageObject("discount")
                              : "Discount"}
                            <span>
                              {symbol}
                              {parseFloat(0).toFixed(2)}
                            </span>
                          </h4>

                          <h4>
                            {getValueFromLanguageObject("amount_payable")
                              ? getValueFromLanguageObject("amount_payable")
                              : "Amount Payable"}
                            <span>
                              {symbol}
                              {parseFloat(
                                this.getGrandTotal(
                                  subTotal,
                                  this.getCGSTFromTotal(subTotal),
                                  this.getSGSTFromTotal(subTotal),
                                  this.getIGSTFromTotal(subTotal),
                                  orderSummary.shippingprice
                                )
                              ).toFixed(2)}
                            </span>
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row order-success-sec">
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4 className="f22">
                        {getValueFromLanguageObject("str_summery")
                          ? getValueFromLanguageObject("str_summery")
                          : "summery"}
                      </h4>
                      <ul className="order-detail">
                        <li>
                          {isInquiry
                            ? getValueFromLanguageObject("inquiry_id")
                              ? getValueFromLanguageObject("inquiry_id")
                              : "Inquiry ID"
                            : getValueFromLanguageObject("order_id")
                            ? getValueFromLanguageObject("order_id")
                            : "Order ID"}
                          : {orderSummary.orderno}
                        </li>
                        <li>
                          {isInquiry
                            ? getValueFromLanguageObject("str_inquiry_date")
                              ? getValueFromLanguageObject("str_inquiry_date")
                              : "Inquiry Date"
                            : getValueFromLanguageObject("str_order_date")
                            ? getValueFromLanguageObject("str_order_date")
                            : "Order Date"}
                          :
                          <Moment format="DD-MMM-YYYY">
                            {orderSummary.date}
                          </Moment>
                        </li>
                        {!isInquiry && (
                          <li>
                            {getValueFromLanguageObject("str_delivery_date")
                              ? getValueFromLanguageObject("str_delivery_date")
                              : "Delivery Date"}
                            :
                            <Moment format="DD-MMM-YYYY">
                              {orderSummary.deliverydate}
                            </Moment>
                          </li>
                        )}
                        {!isInquiry && (
                          <li>
                            {getValueFromLanguageObject("str_order_total")
                              ? getValueFromLanguageObject("str_order_total")
                              : "Order Total"}
                            : {symbol}
                            {parseFloat(
                              this.getGrandTotal(
                                subTotal,
                                this.getCGSTFromTotal(subTotal),
                                this.getSGSTFromTotal(subTotal),
                                this.getIGSTFromTotal(subTotal),
                                orderSummary.shippingprice
                              )
                            ).toFixed(2)}
                          </li>
                        )}
                        {!isInquiry && (
                          <>
                            <li>
                              <b>
                                {getValueFromLanguageObject("str_order_notes")
                                  ? getValueFromLanguageObject(
                                      "str_order_notes"
                                    )
                                  : "Order Notes"}
                                :{this.renderEditOrderNotesIcon()}
                              </b>
                            </li>
                            <li>{orderSummary.description || "-"}</li>
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4 className="f22">
                        {getValueFromLanguageObject("str_customer_detail")
                          ? getValueFromLanguageObject("str_customer_detail")
                          : "Customer Details"}
                      </h4>
                      <ul className="order-detail">
                        <li>
                          {getValueFromLanguageObject("str_name")
                            ? getValueFromLanguageObject("str_name")
                            : "Name"}
                          :{" "}
                          {orderSummary.orderuser &&
                            orderSummary.orderuser.username}
                        </li>
                        <li>
                          {getValueFromLanguageObject("mobile_number")
                            ? getValueFromLanguageObject("mobile_number")
                            : "Mobile Number"}
                          :{" "}
                          {orderSummary.orderuser &&
                            orderSummary.orderuser.cellnumber}
                        </li>
                        {orderSummary.orderuser &&
                          orderSummary.orderuser.cityname && (
                            <li>
                              {getValueFromLanguageObject("str_city")
                                ? getValueFromLanguageObject("str_city")
                                : "City"}
                              : {orderSummary.orderuser.cityname}
                            </li>
                          )}
                      </ul>
                    </div>
                    {paymentDetails &&
                      paymentDetails.length > 0 &&
                      paymentDetails[0].status === 1 &&
                      !isInquiry && (
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                          <h4 className="f22">
                            {getValueFromLanguageObject("payment_details")
                              ? getValueFromLanguageObject("payment_details")
                              : "Payment Details"}
                          </h4>
                          <ul className="order-detail">
                            <li>
                              {getValueFromLanguageObject("pay_via")
                                ? getValueFromLanguageObject("pay_via")
                                : "Pay Via"}
                              :{" "}
                              {orderSummary.paymentDetail &&
                              JSON.parse(orderSummary.paymentDetail).pay_via !==
                                "COD"
                                ? JSON.parse(orderSummary.paymentDetail).pay_via
                                : "Cash On Delivery(COD)"}
                            </li>
                            <li>
                              {getValueFromLanguageObject("payment_id")
                                ? getValueFromLanguageObject("payment_id")
                                : "Payment ID"}
                              :{" "}
                              {orderSummary.paymentDetail &&
                              JSON.parse(orderSummary.paymentDetail)
                                .transaction_no !== ""
                                ? JSON.parse(orderSummary.paymentDetail)
                                    .transaction_no
                                : "N/A"}
                            </li>
                            {orderSummary.paymentstatus &&
                              this.renderPaymentStatus(
                                orderSummary.paymentstatus
                              )}
                          </ul>
                        </div>
                      )}
                    {!isInquiry && (
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <h4 className="f22">
                          {getValueFromLanguageObject("shipping_address")
                            ? getValueFromLanguageObject("shipping_address")
                            : "shipping address"}
                        </h4>
                        {this.renderShippingAddress()}
                      </div>
                    )}
                    {!isInquiry && (
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <h4 className="f22">
                          {getValueFromLanguageObject("billing_address")
                            ? getValueFromLanguageObject("billing_address")
                            : "billing address"}
                        </h4>
                        {this.renderBillingAddress()}
                      </div>
                    )}

                    {/* <div className="col-sm-12 payment-mode">
                                        <h4>payment method</h4>
                                        <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net
                                            banking acceptance subject to device availability.</p>
                                    </div> */}
                    <div className="col-sm-12 mb-3">
                      <div
                        className="delivery-sec"
                        onClick={() => {
                          this.onClickOfRepeatOrder();
                        }}
                      >
                        {isInquiry ? (
                          <h5>
                            {getValueFromLanguageObject(
                              "str_place_inquiry_again"
                            )
                              ? getValueFromLanguageObject(
                                  "str_place_inquiry_again"
                                )
                              : "Place Inquiry Again?"}
                          </h5>
                        ) : (
                          <h5>
                            {getValueFromLanguageObject(
                              "str_buy_products_again"
                            )
                              ? getValueFromLanguageObject(
                                  "str_buy_products_again"
                                )
                              : "Buy products Again?"}
                          </h5>
                        )}
                        {isInquiry ? (
                          <h2>
                            {getValueFromLanguageObject("str_re_inquiry")
                              ? getValueFromLanguageObject("str_re_inquiry")
                              : "Repeat Inquiry"}
                          </h2>
                        ) : (
                          <h2>
                            {getValueFromLanguageObject("str_re_order")
                              ? getValueFromLanguageObject("str_re_order")
                              : "Repeat Order"}
                          </h2>
                        )}
                      </div>
                    </div>

                    {sharingOptionSettings &&
                      sharingOptionSettings.status === 1 &&
                      shareUrl !== "" && (
                        <div className="col-sm-12 mb-3">
                          <div className="border-product text-center">
                            <h6 className="product-title text-center">
                              {getValueFromLanguageObject(
                                "str_share_this_order"
                              )
                                ? getValueFromLanguageObject(
                                    "str_share_this_order"
                                  )
                                : "Share This Order"}
                              :
                            </h6>
                            <div className="product-icon">
                              <ul className="product-social">
                                <TelegramShareButton url={shareUrl}>
                                  <TelegramIcon size={42} round />
                                </TelegramShareButton>
                                <WhatsappShareButton url={shareUrl}>
                                  <WhatsappIcon size={42} round />
                                </WhatsappShareButton>
                                <LinkedinShareButton url={shareUrl}>
                                  <LinkedinIcon size={42} round />
                                </LinkedinShareButton>

                                <EmailShareButton
                                  url={shareUrl}
                                  subject={"Order Link"}
                                  body={""}
                                >
                                  <EmailIcon size={42} round />
                                </EmailShareButton>

                                <div className="col-sm-12 mt-3">
                                  <CopyToClipboard
                                    text={shareUrl}
                                    onCopy={() => {
                                      NotificationManager.success(
                                        getValueFromLanguageObject(
                                          "str_order_link_copied_to_clipboard"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_order_link_copied_to_clipboard"
                                            )
                                          : "Order Link Copied to Clipboard!"
                                      );
                                    }}
                                  >
                                    <button className="btn">
                                      {getValueFromLanguageObject(
                                        "str_copy_to_clipboard"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_copy_to_clipboard"
                                          )
                                        : "Copy To Clipboard"}
                                    </button>
                                  </CopyToClipboard>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(withRouter(OrderSummary));
