/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import { updatedCountries } from "../components/currency-dropdown/data";
import KTSVG from "./KTSVG";
import { connect } from "react-redux";
import { setBaseCurrency } from "../../actions";
import CustomSelect from "./currency-dropdown/custom-select";
import { toAbsoluteUrl } from "../xeUtils/xeUtils";
import {
  getOrderViaWhatsappInfoUrl,
  getMasterDetailsIdUrl,
  getBaseCurrencyList,
  getPlaceHolderFlag,
} from "../../services";
import {
  clearLocalStorageReload,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../utils/utils";
import { getCall } from "../../api/post";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import { loadUserDetailsFromLocalStorage } from "../../store";
import { withRouter } from "react-router-dom";

class CurrencyBuySellDropdown extends Component {
  state = {
    transactionType: "Buy",
    // selectedTo: {
    //   value: "USD",
    //   flag: toAbsoluteUrl("/media/flags/INR.svg"),
    //   label: "USD",
    //   secondary: "US Dollar",
    // },
    selectedTo: {},
    // selectedFrom: {
    //   value: "INR",
    //   flag: <img src={toAbsoluteUrl("/media/flags/HKD.svg")} alt="flag" />,
    //   label: "INR",
    //   secondary: "Indian Rupees",
    // },
    selectedFrom: {},
    fromValue: "",
    toValue: "",
    // data: updatedCountries,
    selectedOption: "",
    cellnumber: "",
    masterDetailsObj: "",
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    baseCurrencyList: [],
    dropDownOptions: "USD",
    userstatus: "",
    orderviaWhatsappCellnumber: "",
  };

  componentDidMount = () => {
    this.initMasterDetailsId().then(() => {
      this.initOrderViaWhatsappInfo();
      this.initBaseCurrencyList();
      this.initUserDetails();
    });
  };

  componentWillUpdate = (prevProps) => {
    const { selectedBaseCurrency: prevpropsCurrency } = prevProps;
    const { selectedBaseCurrency: propsCurrency } = this.props;
    const { baseCurrencyList, selectedTo } = this.state;
    const { value: selectedToValue } = selectedTo || {};
    const { value: baseCurrencyListValue } = baseCurrencyList[0] || {};
    // Change From Dropdown when base currency is updated
    if (prevpropsCurrency !== propsCurrency) {
      const chnageC = baseCurrencyList.filter(
        (currencies) => currencies.value === prevpropsCurrency
      );
      this.setState({ selectedFrom: chnageC[0] }, () => {
        this.updateCalculationForCurrencyChange();
      });
    }

    //If To currency dropdown and from currency dropdown value are same
    if (prevpropsCurrency === selectedToValue) {
      if (selectedToValue !== baseCurrencyListValue) {
        this.setState({ selectedTo: baseCurrencyList[0] });
      } else {
        this.setState({ selectedTo: baseCurrencyList[1] });
      }
    }
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);
    if (userDetailsResponse.error) {
      clearLocalStorageReload({ response: userDetailsResponse });
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({
        userstatus: userDetailsResponse.userstatus,
      });
    }
  };

  updateCalculationForCurrencyChange = () => {
    const { currencyList, selectedBaseCurrency } = this.props;
    const { selectedTo, transactionType, toValue } = this.state;

    const [currencyBase] = currencyList.filter((currency) => {
      const { currencyLabel } = currency || {};
      return currencyLabel === selectedBaseCurrency;
    });
    const { currencyValue = [] } = currencyBase || {};
    const [currencyFromData] = currencyValue.filter((currency) => {
      const { currencyLabel } = currency || {};
      const { value } = selectedTo || {};
      return value === currencyLabel;
    });
    const { buy, sale } = currencyFromData || {};
    let price = transactionType === "Buy" ? buy : sale;
    const amount = parseFloat(toValue);
    price = parseFloat(price);
    const total = (amount * price).toFixed(2);
    this.setState({ fromValue: total });
  };

  updateCalculation = (transactionType) => {
    const { currencyList } = this.props;
    const { selectedFrom, selectedTo, toValue } = this.state;
    const [currencyBase] = currencyList.filter((currency) => {
      const { value: toCurrency } = selectedFrom || {};
      const { currencyLabel } = currency || {};
      return currencyLabel === toCurrency;
    });
    const { currencyValue = [] } = currencyBase || {};
    const [currencyFromData] = currencyValue.filter((currency) => {
      const { currencyLabel } = currency || {};
      const { value } = selectedTo || {};
      return value === currencyLabel;
    });
    const { buy, sale } = currencyFromData || {};
    let price = transactionType === "Buy" ? buy : sale;
    const amount = parseFloat(toValue);
    price = parseFloat(price);
    const total = (amount * price).toFixed(2);
    this.setState({ fromValue: total });
  };

  renderUnitCurrencyWithoutHtml = () => {
    const { currencyList, selectedBaseCurrency } = this.props;
    const { selectedFrom, selectedTo, transactionType } = this.state;
    // 1 USD = 74.55 INR
    const [currencyBase] = currencyList.filter((currency) => {
      const { currencyLabel } = currency || {};
      return currencyLabel === selectedBaseCurrency;
    });
    const { currencyValue = [] } = currencyBase || {};
    const [currencyFromData] = currencyValue.filter((currency) => {
      const { currencyLabel } = currency || {};
      const { value } = selectedTo || {};
      return value === currencyLabel;
    });
    const { buy, sale } = currencyFromData || {};
    const price = transactionType === "Buy" ? buy : sale;
    return `1 ${selectedTo.value} = ${price} ${selectedFrom.value}`;
  };

  renderUnitCurrency = () => {
    const { currencyList, selectedBaseCurrency } = this.props;
    const { selectedFrom, selectedTo, transactionType } = this.state;
    // 1 USD = 74.55 INR
    const [currencyBase] = currencyList.filter((currency) => {
      const { currencyLabel } = currency || {};
      return currencyLabel === selectedBaseCurrency;
    });
    const { currencyValue = [] } = currencyBase || {};
    const [currencyFromData] = currencyValue.filter((currency) => {
      const { currencyLabel } = currency || {};
      const { value } = selectedTo || {};
      return value === currencyLabel;
    });
    const { buy, sale } = currencyFromData || {};
    const price = transactionType === "Buy" ? buy : sale;
    const { value: toCurrency } = selectedTo || {};
    const { value: fromCurrency } = selectedFrom || {};

    return (
      <>
        {toCurrency && fromCurrency && (
          <span className="d-block text-end text-muted py-3 mb-5 text-align-last">
            {`1 ${toCurrency} = ${price} ${fromCurrency}`}
          </span>
        )}
      </>
    );
  };

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initOrderViaWhatsappInfo = async () => {
    const { masterDetailsObj } = this.state;
    const tenantInfoUrl = getOrderViaWhatsappInfoUrl(masterDetailsObj);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (
        tenantInfo &&
        tenantInfo.length > 0 &&
        tenantInfo[0].text &&
        tenantInfo[0].text.length > 0
      ) {
        const tenantSettings = JSON.parse(tenantInfo[0].text);
        if ("cellnumber" in tenantSettings) {
          this.setState({
            orderviaWhatsappCellnumber: tenantSettings.cellnumber,
          });
        } else {
          this.setState({
            orderviaWhatsappCellnumber: "9898024474",
          });
        }
      }
    }
  };

  initBaseCurrencyList = async () => {
    const baseCurrencyListUrl = getBaseCurrencyList();
    const baseCurrencyListResponse = await getCall(baseCurrencyListUrl, []);
    if (baseCurrencyListResponse.error) {
      clearLocalStorageReload({ response: baseCurrencyListResponse });
    }
    let currencies = [];
    baseCurrencyListResponse.map((currency) => {
      const { currencyLabel, currencyName, currencyStatus } = currency || {};
      let currencyObj = {
        value: currencyLabel,
        flag: toAbsoluteUrl("/media/flags/HKD.svg"),
        label: currencyLabel,
        secondary: currencyName,
        currencyStatus,
      };
      currencies.push(currencyObj);

      return currency;
    });
    const [selectedFrom] = currencies.filter((currency) => {
      const { value } = currency || {};
      return value === "INR";
    });

    const [selectedTo] = currencies.filter((currency) => {
      const { value } = currency || {};
      return value === "USD";
    });
    this.setState({ baseCurrencyList: currencies });
    this.setState({ selectedTo, selectedFrom }, () => {
      const [isValueSame] = currencies.filter((currency) => {
        const { value } = currency || {};
        return value === "USD";
      });
      const dropDownOptions = currencies.map((item) => {
        item.label = (
          <>
            {item.currencyStatus && (
              <div className="d-flex flex-row flex-grow-1 align-items-center currency-item">
                <div className="currency-name mr-3">
                  <div className="flag-icon border-0 box-showdow-none">
                    <img
                      src={`/media/flags/${item.label}.svg`}
                      alt="flag"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = getPlaceHolderFlag();
                      }}
                    />
                  </div>
                </div>
                <div className="currency-country d-flex flex-column">
                  <span className="primary-label">{item.label}</span>
                  <span className="secondary-label">{item.secondary}</span>
                </div>
              </div>
            )}
          </>
        );
        return item;
      });
      this.setState({ dropDownOptions });
    });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    let userDetails = loadUserDetailsFromLocalStorage();
    const { cellnumber, username, userstatus } = userDetails || {};
    const { data, requestID, orderviaWhatsappCellnumber } = this.state;
    const {
      transactionType,
      selectedFrom,
      fromValue,
      selectedTo,
      toValue,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      baseCurrencyList,
      dropDownOptions,
    } = this.state;
    const { currencyList } = this.props;
    const name = "\u002B";
    return (
      <>
        <div className="card mb-3">
          {/* begin::Header */}
          <div className="card-header border-0 pt-5 align-items-center">
            <h3 className="card-title align-items-end">
              <span className="card-label font-weight-bold fs-3 mb-1">
                I want to
              </span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className="card-body pt-0">
            {/*begin:BuySell Form*/}
            <form className="form">
              <div className="current">
                {/**/}
                <div className="w-100 mb-5">
                  <div className="fv-row mb-10">
                    <select
                      className="form-control form-control-solid"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      defaultValue={transactionType}
                      onChange={(event) => {
                        this.setState({ transactionType: event.target.value });
                        this.updateCalculation(event.target.value);
                      }}
                    >
                      <option value="Buy">Buy</option>
                      <option value="Sell">Sell</option>
                    </select>
                  </div>

                  <>
                    <div className="w-100 one">
                      <label className="mt-4">To</label>
                      <div className="input-group input-group-xl mb-24">
                        {/**/}
                        <div className="dropdown currency-dropdown form-control custom-select d-flex align-items-center flex-grow-1 ">
                          <CustomSelect
                            options={dropDownOptions}
                            placeholder="Select Currency"
                            selected={selectedTo}
                            onSelect={(data) => {
                              if (
                                !(
                                  this.props.selectedBaseCurrency === data.value
                                )
                              ) {
                                this.setState(
                                  {
                                    selectedTo: data,
                                  },
                                  () => {
                                    this.updateCalculationForCurrencyChange();
                                  }
                                );
                              }
                            }}
                            searchable
                            searchPlaceholder="Search currency"
                            className={
                              "react-currency-btn w-100 h-100 py-0 border-0"
                            }
                            selectButtonClassName="currency-flag-btn border-0"
                            selectedSize={16}
                            showSecondarySelectedLabel={false}
                            fullWidth={true}
                          />
                        </div>
                        {/**/}
                        <input
                          type="number"
                          className="form-control text-end"
                          placeholder="Enter amount"
                          value={toValue}
                          onChange={(event) => {
                            const [currencyBase] = currencyList.filter(
                              (currency) => {
                                const { value: toCurrency } =
                                  selectedFrom || {};
                                const { currencyLabel } = currency || {};
                                return currencyLabel === toCurrency;
                              }
                            );
                            const { currencyValue = [] } = currencyBase || {};
                            const [currencyFromData] = currencyValue.filter(
                              (currency) => {
                                const { currencyLabel } = currency || {};
                                const { value } = selectedTo || {};
                                return value === currencyLabel;
                              }
                            );
                            const { buy, sale } = currencyFromData || {};
                            let price = transactionType === "Buy" ? buy : sale;
                            this.setState({
                              toValue: event.target.value,
                            });
                            const amount = parseFloat(event.target.value);
                            price = parseFloat(price);
                            const total = (amount * price).toFixed(2);
                            this.setState({ fromValue: total.toString() });
                          }}
                        />
                        {/**/}
                      </div>
                    </div>
                    {/**/}
                    <div className="w-100 d-flex align-items-center justify-content-center py-4">
                      <div className="icon-badge">
                        <KTSVG
                          path="/media/flags/arr004.svg"
                          className="svg-icon-4 rounded text-white"
                        />
                      </div>
                    </div>
                    {/**/}
                    <div className="w-100 two">
                      <label>From</label>
                      <div className="input-group input-group-xl mb-24">
                        {/**/}

                        <div className="dropdown currency-dropdown form-control custom-select d-flex align-items-center flex-grow-1 ">
                          <CustomSelect
                            placeholder="Select Currency"
                            selected={selectedFrom}
                            searchable
                            searchPlaceholder="Search currency"
                            className={
                              "react-currency-btn w-100 h-100 py-0 border-0"
                            }
                            selectButtonClassName="currency-flag-btn border-0"
                            selectedSize={16}
                            showSecondarySelectedLabel={false}
                            fullWidth={true}
                            disabled={true}
                          />
                        </div>
                        {/**/}
                        <input
                          type="number"
                          className="form-control text-end"
                          placeholder="Enter amount"
                          value={fromValue}
                          //take value from selectedFrom and compare it with currency matrix label
                          //and return the value with a same label
                          onChange={(event) => {
                            const [currencyBase] = currencyList.filter(
                              (currency) => {
                                const { value: toCurrency } = selectedTo || {};
                                const { currencyLabel } = currency || {};
                                return currencyLabel === toCurrency;
                              }
                            );
                            const { currencyValue = [] } = currencyBase || {};
                            const [currencyFromData] = currencyValue.filter(
                              (currency) => {
                                const { currencyLabel } = currency || {};
                                const { value } = selectedFrom || {};
                                return value === currencyLabel;
                              }
                            );
                            const { buy, sale } = currencyFromData || {};
                            let price = transactionType === "Buy" ? buy : sale;
                            const amount = parseFloat(event.target.value);
                            price = parseFloat(price);
                            const total = (amount * price).toFixed(2);
                            this.setState({ fromValue: event.target.value });
                            this.setState({ toValue: total.toString() });
                          }}
                        />
                        {/**/}
                      </div>
                    </div>{" "}
                  </>

                  {this.renderUnitCurrency()}

                  <button
                    className="btn btn-primary align-self-center w-100"
                    type="button"
                    onClick={() => {
                      if (userstatus === "Deactive") {
                        navigateTo(this.props, `/login`);
                      }
                      const oneValue = this.renderUnitCurrencyWithoutHtml();
                      let text = `*Request ID - ${Math.floor(
                        10000000 + Math.random() * 900000
                      )}* - I want to ${transactionType} ${fromValue} ${
                        selectedFrom.value
                      } for ${toValue} ${
                        selectedTo.value
                      } (${oneValue}) \nFrom ${username} ${"%2B91"} ${cellnumber}`;
                      var whatsAppLink = `https://api.whatsapp.com/send?phone=${"%2B91"} ${orderviaWhatsappCellnumber}&text=${text}`;
                      window.open(whatsAppLink);
                    }}
                    disabled={!(toValue.length > 0)}
                  >
                    Submit Request
                  </button>
                  {/**/}
                </div>
              </div>
            </form>

            {/*end: BuySell Form*/}
          </div>
          {/* end::Body */}
          {showErrorMessageModal && (
            <ErrorMessageAnimationModal
              show={showErrorMessageModal}
              message={errorMessage}
              onClose={() => {
                this.setState({ showErrorMessageModal: false });
              }}
            />
          )}
          {showSuccessMessageModal && (
            <SuccessMessageAnimationModal
              show={showSuccessMessageModal}
              message={successMessage}
              onClose={() => {
                this.setState({ showSuccessMessageModal: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedBaseCurrency: state.settings.baseCurrency,
});
export default connect(mapStateToProps, {
  baseCurrency: setBaseCurrency,
})(withRouter(CurrencyBuySellDropdown));
