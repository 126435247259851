import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { getCall } from "../../../api/post";
import { connect } from "react-redux";
import { getImageUrl, getPlaceHolderImage } from "../../../services";
import { isInquiryOrCart } from "../../../actions";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  loadUserDetailsFromLocalStorage,
  getOpenStoreIdApp,
} from "../../../store";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
} from "../../../utils/utils";
import { debuglogger } from "../../../utils/logger";
import Lightbox from "react-image-lightbox";

class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stock:
        props.product.availablequantity > 0
          ? getValueFromLanguageObject("str_instock")
            ? getValueFromLanguageObject("str_instock")
            : "InStock"
          : getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      image: "",
      currentProduct: {},
      category: {},
      pincode: "",
      showErrorMessageModal: false,
      errorMessage: "",
      isFullScreenImage: false,
      fullScreenImagePath: "",
    };
  }

  componentDidMount() {
    const { masterDetailsObj } = this.props;
    this.props.isInquiryOrCart(masterDetailsObj);
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > this.props.product.minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      });
    }
  };

  changeQty = (e) => {
    const { isInquiry, product } = this.props;
    if (!isInquiry && product.availablequantity === 0) {
      return;
    }

    if (e.target.value === "" || e.target.value === "-") {
      this.setState({ quantity: "" });
      return;
    }
    if (e.target.value < 1) {
      return;
    }
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  addToCartBase = () => {
    const { pincode } = this.state;
    const { product, onAddToCartClicked, isInquiry } = this.props;
    onAddToCartClicked(product, this.state.quantity, isInquiry, pincode);
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    } else {
      userDetails = userDetails === undefined ? {} : userDetails;
    }
    const { id } = userDetails;
    const openStoreId = getOpenStoreIdApp();
    let userId = openStoreId ? openStoreId : id;
    const counterUrl = "commoncounters?filter[where][userId]=" + userId;
    debuglogger("counterUrl", counterUrl);
    const commonCounter = await getCall(counterUrl, []).then();
    if (commonCounter.error) {
      clearLocalStorageReload({ response: commonCounter });
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  pincodeHandleSubmit = (event) => {
    this.setState({ pincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      isInquiry,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      handleClickOfQuickView,
    } = this.props;

    const { isFullScreenImage, fullScreenImagePath } = this.state;

    const strOutOfStock = getValueFromLanguageObject("str_out_of_stock")
      ? getValueFromLanguageObject("str_out_of_stock")
      : "Out of Stock";

    const strThereAre = getValueFromLanguageObject("str_there_are")
      ? getValueFromLanguageObject("str_there_are")
      : "There are";

    const strUnitsOfItemAlreadyInTheCart = getValueFromLanguageObject(
      "str_units_of_item_already_in_the_cart"
    )
      ? getValueFromLanguageObject("str_units_of_item_already_in_the_cart")
      : "units of item already in the cart";

    return (
      <>
        <div className="product-box">
          <div className="img-wrapper">
            <div className="front">
              <Link to={`/singleproduct/${product.id}`}>
                <img
                  src={
                    product.productmedia.length > 0
                      ? getImageUrl(
                          product.productmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage()
                  }
                  className="img-fluid"
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getPlaceHolderImage();
                  }}
                />
              </Link>
            </div>
            <div className="cart-info cart-wrap">
              <button
                data-toggle="modal"
                data-target="#quick-view"
                title={
                  getValueFromLanguageObject("str_quick_view")
                    ? getValueFromLanguageObject("str_quick_view")
                    : "Quick View"
                }
                onClick={() => handleClickOfQuickView(product)}
                className="cursor-pointer"
              >
                <i className="fa fa-eye" aria-hidden="true" />
              </button>
              <button
                title={
                  getValueFromLanguageObject("str_view_image")
                    ? getValueFromLanguageObject("str_view_image")
                    : "View Image"
                }
                onClick={() => {
                  const path =
                    product.productmedia.length > 0
                      ? getImageUrl(
                          product.productmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage();
                  this.setState({
                    isFullScreenImage: true,
                    fullScreenImagePath: path,
                  });
                }}
              >
                <i className="fa fa-search" aria-hidden="true" />
              </button>
              <button
                title={
                  isInquiry
                    ? getValueFromLanguageObject("str_add_to_inquiry")
                      ? getValueFromLanguageObject("str_add_to_inquiry")
                      : "Add To Inquiry"
                    : getValueFromLanguageObject("str_add_to_cart")
                    ? getValueFromLanguageObject("str_add_to_cart")
                    : "Add to cart"
                }
                onClick={async () => {
                  if (!isInquiry && product.availablequantity === 0) {
                    return;
                  }
                  onAddToCartClicked(product, this.state.quantity, isInquiry);
                }}
              >
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button>
            </div>
            <div className="details-product col-md-4">
              <Link to={`/singleproduct/${product.id}`}>
                <h6>
                  <EllipsisText text={product.name} length={35} />
                </h6>
              </Link>
              {isInquiry ? (
                <div />
              ) : (
                <h4>
                  {symbol}
                  {parseFloat(product.price).toFixed(2)}
                </h4>
              )}
            </div>

            {product && product.totalCartCounter > 0 && (
              <div className="quantity-cart-item">
                {`${strThereAre} ${
                  product.totalCartCounter
                } ${strUnitsOfItemAlreadyInTheCart}`}
              </div>
            )}

            {!isInquiry && product.availablequantity === 0 && (
              <div className="product-outofstock"> {strOutOfStock} </div>
            )}
          </div>
        </div>

        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(withRouter(ProductItem));
