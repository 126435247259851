import React, { Component } from "react";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  getCurrentLanguage,
} from "../../../store";
import { getCall } from "../../../api/post";
import Moment from "react-moment";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import NotificationContentLoaderAnim from "../../../animation/NotificationContentLoaderAnim";
import NoNotificationFoundAnim from "../../../animation/NoNotificationFoundAnim";
import {
  clearLocalStorageReload,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";

const loadMoreStyle = {
  padding: "10px",
  textAlign: "center",
  border: "1px solid #A9A9A9",
  margin: "20px",
  fontSize: "15px",
  color: getColorCodeBasedOnKey("text_notification_drawer_load_more_homepage"),
  cursor: "pointer",
};

class NotificationList extends Component {
  state = {
    notificationList: [],
    skip: 0,
    limit: 20,
    isLoadMoreButtonVisible: false,
    isLoaderVisible: true,
    showErrorMessageModal: false,
    errorMessage: "",
  };

  componentDidMount() {
    this.initNotificationList();
  }

  initNotificationList = async () => {
    const { skip, notificationList, limit } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }

    const notificationUrl = `notificationreceivers?filter[where][userId]=${
      userDetails.id
    }&filter[include]=notification&filter[include]=order&filter[order]=created DESC&filter[skip]=${skip}&filter[limit]=${limit}`;
    const notifications = await getCall(notificationUrl, []);
    if (notifications.error) {
      clearLocalStorageReload({ response: notifications });
      this.loadErrorMessageAnimation(notifications.error.message);
      if (notifications.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        return;
      }
    } else {
      if (notificationList.length > 0) {
        const tempList = [...notificationList, ...notifications];
        this.setState({ notificationList: tempList });
      } else {
        this.setState({ notificationList: notifications });
        setTimeout(() => {
          this.setState({ isLoaderVisible: false });
        }, 1000);
      }
      if (limit > notifications.length + 1) {
        this.setState({ isLoadMoreButtonVisible: false });
      } else {
        this.setState({ isLoadMoreButtonVisible: true });
      }
    }
  };

  fetchMoreItems = () => {
    const { notificationList } = this.state;
    this.handlePagination(notificationList.length);
  };

  handlePagination = (skip) => {
    this.setState({ skip }, () => {
      this.initNotificationList();
    });
  };

  renderBorderColor = (index) => {
    if (index % 2 === 0) {
      return "#cc2121";
    } else {
      return "#cc2121";
    }
  };

  renderBGColor = (index) => {
    if (index % 2 === 0) {
      return "#F8F8F8";
    } else {
      return "#F8F8F8";
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      notificationList,
      isLoadMoreButtonVisible,
      isLoaderVisible,
      showErrorMessageModal,
      errorMessage,
    } = this.state;

    return (
      <>
        <div className="container " style={{ width: "370px" }}>
          {notificationList.length > 0 &&
            notificationList.map((notification, index) => (
              <div
                key={index}
                style={{
                  padding: "10px",
                  borderLeft: `2px solid ${this.renderBorderColor(index)}`,
                  marginTop: "5px",
                  backgroundColor: this.renderBGColor(index),
                  cursor: "pointer",
                }}
                onClick={() => {
                  const { orderId } = notification || {};
                  const { closeDrawer } = this.props;
                  this.props.history.push(`/ordersummary/${orderId}`);
                  closeDrawer();
                }}
              >
                {notification &&
                notification.notification &&
                notification.notification.arabicTextmessage &&
                getCurrentLanguage() === "ar"
                  ? notification.notification.arabicTextmessage
                  : notification.notification.textmessage}
                <br />

                <div style={{ color: "#cc2121" }}>
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  <Moment fromNow className="ml-2 mr-2">
                    {notification.created}
                  </Moment>
                </div>
              </div>
            ))}
          {notificationList.length > 0 && isLoadMoreButtonVisible && (
            <div
              onClick={() => {
                this.fetchMoreItems();
              }}
              style={loadMoreStyle}
            >
              {getValueFromLanguageObject("str_load_more")
                ? getValueFromLanguageObject("str_load_more")
                : "Load More"}
            </div>
          )}
          {notificationList.length === 0 && isLoaderVisible && (
            <NotificationContentLoaderAnim />
          )}
          {notificationList.length === 0 && !isLoaderVisible && (
            <NoNotificationFoundAnim />
          )}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}
export default withRouter(NotificationList);
